/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import React from 'react';
import { useSelector } from 'react-redux';

import imgUrl from '../assets/ultrasound.png';
import { RootState } from '../store';

const ExamReelButton = (props: React.HTMLProps<HTMLButtonElement>) => {
  const numCaptures = useSelector((s: RootState) => s.capture.captures.length);

  return (
    <button
      {...props}
      type="button"
      onClick={e => {
        if (!numCaptures) return;
        if (props.onClick) props.onClick(e);
      }}
      css={[require("./ExamReelButton-CssProp1_button.module.scss"), [["akeopjh", numCaptures ? `rgba(0, 0, 0, 0.3) url('${imgUrl}')` : 'black']]]}
    >
      {numCaptures}
    </button>
  );
};

export default ExamReelButton;
