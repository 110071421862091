/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import React, { ReactElement } from 'react';

interface Props extends React.HTMLProps<HTMLButtonElement> {
  children?: ReactElement;
  size?: number;
}

function IconButton({ children, size, ...props }: Props) {
  return (
    <button
      {...props}
      type="button"
      css={[require("./IconButton-CssProp1_button.module.scss"), [["akeopjh", size || '4.4rem'], ["a1wojgm6", size || '4.4rem']]]}
    >
      {children}
    </button>
  );
}

export default IconButton;
