const isOpera =
  (!!window.opr && !!window.opr.addons) ||
  !!window.opera ||
  navigator.userAgent.indexOf(' OPR/') >= 0;

export default function isChrome() {
  return (
    !isOpera &&
    !!window.chrome &&
    (!!window.chrome.webstore || !!window.chrome.runtime)
  );
}
