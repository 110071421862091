import { RouteMatch, RouteProps, RouteRenderArgs } from 'found';
import HttpError from 'found/lib/HttpError';
import BaseRoute from 'found/lib/Route';
import React from 'react';
import { GraphQLTaggedNode } from 'react-relay';

function defaultGetFetchPolicy({ location }: any) {
  return location.action === 'POP' ? 'store-or-network' : 'store-and-network';
}

type RouteRenderFn = (
  renderArgs: RelayRouteRenderArgs,
) => undefined | null | React.ReactElement;

interface RelayRouteProps extends RouteProps {
  query?: GraphQLTaggedNode;
  getQuery?: (
    match: RouteMatch,
  ) => GraphQLTaggedNode | Promise<GraphQLTaggedNode>;
}

interface RelayRouteRenderArgs extends RouteRenderArgs {
  error: null | Error;
  resolving: boolean;
}

interface CreateRenderOptions {
  render?: RouteRenderFn;
  renderFetched?: RouteRenderFn;
}

/**
 * Same as in Olympus-frontend, takes one or more render functions and composes them together
 *
 * render: runs on match and while data is fetched.
 *         return an element to render it or undefined/null to show the last rendered page
 *
 * renderFetched: only called after data has been resolved
 */
function createRender({ render, renderFetched }: CreateRenderOptions) {
  return (renderArgs: RelayRouteRenderArgs) => {
    const { Component, props, error } = renderArgs;

    if (error) {
      // TODO: What if we're not resolving?
      throw new HttpError(500);
    }

    if (render) {
      return render(renderArgs);
    }

    if (props && renderFetched) {
      return renderFetched(renderArgs);
    }

    if (!Component || !props) {
      return null;
    }

    return <Component {...props} />;
  };
}

class Route extends BaseRoute {
  constructor(props: RelayRouteProps) {
    if (!(props.query || props.getQuery)) {
      super(props);
      return;
    }

    super({
      getFetchPolicy: defaultGetFetchPolicy,
      ...props,
      render: createRender(props),
    });
  }
}

export default Route as React.ComponentType<RelayRouteProps>;
