import { track } from './Analytics';

type Seconds = number;

interface StillCapture {
  contents: string;
  type: 'still';
}

interface CineCapture {
  contents: string;
  duration: Seconds;
  type: 'cine';
}

export type Capture = StillCapture | CineCapture;

/*
 *   “Through me you pass into the city of woe:
 * Through me you pass into eternal pain:
 * Through me among the people lost for aye.
 * Justice the founder of my fabric moved:
 * To rear me was the task of power divine,
 * Supremest wisdom, and primeval love.
 * Before me things create were none, save things
 * Eternal, and eternal I shall endure.
 * All hope abandon, ye who enter here.”
 *  ― Dante Alighieri
 */
export function captureProbeStill(): Capture | null {
  const canvas = document.createElement('canvas');

  const source = document.getElementById(
    'probe-video',
  ) as HTMLVideoElement | null;
  if (!source) {
    console.log('Could not capture a still while on exam reel');
    track('PROBE_CAPTURE_FAILED_ON_EXAM_REEL');
    return null;
  }

  // TODO: if window.ImageCapture is defined, use ImageCapture API.
  // For some reason it throws an InvalidStateError even though
  // videoStream.readyState is 'live'. The spec says that should
  // not be possible.
  // https://www.w3.org/TR/image-capture/
  //
  // ALSO the polyfill doesn't work presumably b/c of splitting the track between two elements.
  // it SHOULD work but the new video element never plays
  canvas.width = source.videoWidth;
  canvas.height = source.videoHeight;

  const ctx = canvas.getContext('2d')!;
  ctx.drawImage(source, 0, 0);

  return {
    contents: canvas.toDataURL(),
    type: 'still',
  };
}
