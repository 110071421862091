/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import classNames from 'classnames';
import getScrollbarSize from 'dom-helpers/scrollbarSize';
import React from 'react';

const styles = require('./GettingStartedImageScroller-styles.module.scss');
interface Props {
  className?: string;
  children?: React.ReactNode;
}

function GettingStartedImageScroller({ children, className }: Props) {
  // we check so we only style scrollbars that are obtrusive, e.g. not auto hide
  const size = getScrollbarSize();

  return (
    <div
      className={classNames(className, size > 0 && styles.scrollbar)}
      css={[require("./GettingStartedImageScroller-CssProp1_div.module.scss"), []]}
    >
      {children}
    </div>
  );
}

export default GettingStartedImageScroller;
