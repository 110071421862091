import { useRouter } from 'found';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import Dropdown from '@bfly/ui/Dropdown';
import Navbar from '@bfly/ui/Navbar';

import { useAuthContext } from './AuthProvider';
import Avatar from './Avatar';
import { NavbarDropdown_viewer as Viewer } from './__generated__/NavbarDropdown_viewer.graphql';

interface Props {
  viewer: Viewer;
}

function NavbarDropdown({ viewer }: Props) {
  const { router } = useRouter();
  const authContext = useAuthContext();

  const logout = () => {
    authContext.clearTokenResponse();
    router.push('/');
  };
  return (
    <Dropdown>
      <Dropdown.Toggle id="user-menu" as={Navbar.Button}>
        <Avatar profile={viewer.profile!} size="navbar" />
      </Dropdown.Toggle>
      <Dropdown.Menu role="menu" alignRight>
        <Dropdown.Item onSelect={logout}>
          <FormattedMessage
            id="navbarDropdown.logout"
            defaultMessage="Log Out"
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default createFragmentContainer(NavbarDropdown, {
  viewer: graphql`
    fragment NavbarDropdown_viewer on Viewer {
      id
      profile {
        id
        name
        ...Avatar_profile
      }
    }
  `,
});
