/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import classnames from 'classnames';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  instructionClicked,
  tentativeInstructionCanceled,
  tentativeInstructionShown,
} from '../../actions/instruction';
import { VisualCommand, VisualCommandType } from '../../Instruction';
import { RootState } from '../../store';
import Arc from './Arc';
import ArrowHeadMarker from './ArrowHeadMarker';
import HelpText from './HelpText';
import InstructionPlane, {
  CENTER_X,
  CENTER_Y,
  CIRCLE_R,
} from './InstructionPlane';
import SvgButton from './SvgButton';
import * as Svg from './SvgUtils';

const RotateClockwise: VisualCommand = {
  type: VisualCommandType.ROTATE_CLOCKWISE,
};

const RotateCounterClockwise: VisualCommand = {
  type: VisualCommandType.ROTATE_COUNTERCLOCKWISE,
};

const styles = require('./Rotate-styles.module.scss');

const ARROW_R = 250;
interface ArrowProps {
  id: string;
  flip?: boolean;
  startAngle: number;
  endAngle: number;
  command: VisualCommand;
  // onPointerEnter: React.PointerEventHandler;
  // onPointerLeave: React.PointerEventHandler;
}
const Arrow = ({
  id,
  startAngle,
  endAngle,
  command,
  flip,
}: // onPointerEnter,
// onPointerLeave,
ArrowProps) => {
  const offset = (CIRCLE_R - ARROW_R) / 2 + ARROW_R;

  const dispatch = useDispatch();
  const stale = useSelector((s: RootState) => s.pose.stale);
  const activeCommand = useSelector(
    (s: RootState) => s.commands.activeCommand,
  );

  const active = activeCommand === command;
  const allowClicks = !stale && !active;
  const dir = flip ? -1 : 1;
  const sweep = flip ? 1 : 0;

  return (
    <g className={classnames(styles.container, active && styles.active)}>
      <SvgButton
        onClick={() => {
          if (allowClicks) dispatch(instructionClicked(command));
        }}
        onMouseEnter={() => {
          if (!allowClicks) return;

          dispatch(tentativeInstructionShown(command));
        }}
        onMouseLeave={() => {
          dispatch(tentativeInstructionCanceled());
        }}
      >
        <defs>
          <ArrowHeadMarker
            id={`${id}__arrow-end`}
            className={styles.arrowEnd}
          />
        </defs>
        <Arc
          cx={CENTER_X}
          cy={CENTER_Y}
          r={offset}
          sweep={sweep}
          startAngle={startAngle}
          endAngle={endAngle}
          fill="transparent"
        />
        <Arc
          cx={CENTER_X}
          cy={CENTER_Y}
          r={ARROW_R}
          sweep={sweep}
          strokeWidth={14}
          startAngle={startAngle + 35 * dir}
          // +/-5 to give room for the marker head extending past line
          endAngle={endAngle - 30 * dir}
          className={styles.arrow}
          markerEnd={`url(#${id}__arrow-end)`}
        />
      </SvgButton>
    </g>
  );
};

interface Props {
  indicatorDegrees: number | null;
}

function FancyRotate({ indicatorDegrees }: Props) {
  const [isHovering, setIsHovering] = useState(false);

  const frozenAngleRef = useRef<number | null>(null);

  const handlePointerEnter = () => {
    frozenAngleRef.current = indicatorDegrees;
    setIsHovering(true);
  };

  const handlePointerLeave = () => {
    frozenAngleRef.current = null;
    setIsHovering(false);
  };

  const rotate = Svg.toRotateTransform(
    frozenAngleRef.current || indicatorDegrees,
  );

  return (
    <div css={[require("./Rotate-CssProp1_div.module.scss")]}>
      <HelpText previewing={isHovering} />
      <InstructionPlane indicatorDegrees={indicatorDegrees}>
        <g
          transform={`rotate(${rotate}, ${CENTER_X}, ${CENTER_Y})`}
          onPointerEnter={handlePointerEnter}
          onPointerLeave={handlePointerLeave}
        >
          <Arrow
            id="rotate-ccw"
            startAngle={180}
            endAngle={0}
            command={RotateCounterClockwise}
          />

          <Arrow
            flip
            id="rotate-cw"
            startAngle={180}
            endAngle={0}
            command={RotateClockwise}
          />
        </g>
      </InstructionPlane>
    </div>
  );
}

export default FancyRotate;
