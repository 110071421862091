/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');
import React, { useEffect, useRef } from 'react';
import { Matrix4, Vector3 } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Layout from '@4c/layout';

import MainRenderer from '../scene/MainRenderer';
import { ArState } from '../scene/MainScene';
import RenderLoop from '../scene/RenderLoop';
import { createCamera } from '../scene/sceneObjects';
import Panel from './Panel';

class Scene {
  state: ArState;

  private readonly loop: RenderLoop;

  private readonly renderer: MainRenderer;

  constructor(protected readonly options: any) {
    const { container } = options;
    const camera = createCamera(800 / 600);
    this.state = {
      activeCommand: null,
      tentativeCommand: null,
      commandIsTentative: false,
      imagingFrozen: false,

      poseStale: false,
      probeRotation: new Matrix4(),
      probeTranslation: new Vector3(),
      indicatorAngle: 0,

      camera,
      dimensions: { width: 800, height: 600 },

      probeOcclusion: false,
      debug: {
        showProbe: true,
        lightHelper: true,
        gridHelper: true,
        cameraControls: new OrbitControls(camera, container),
      },
    };

    this.renderer = new MainRenderer(container);

    this.loop = new RenderLoop(dt => {
      this.renderer.render(this.state, dt);
    });

    this.loop.start();
  }

  destroy() {
    this.loop.stop();
    this.renderer.destroy();
  }
}

function Playground() {
  const arRef = useRef();

  useEffect(() => {
    const scene = new Scene({
      container: arRef.current!,
    });

    return () => {
      scene.destroy();
    };
  }, []);

  return (
    <Layout justify="center" align="center" css={[require("./_Three-CssProp1_Layout.module.scss")]}>
      <Layout direction="column">
        <Panel css={[require("./_Three-CssProp2_Panel.module.scss")]} ref={arRef} />
      </Layout>
    </Layout>
  );
}

export default Playground;
