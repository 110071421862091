import { useRouter } from 'found';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import Heading from '@bfly/ui/Heading';
import MainContent from '@bfly/ui/MainContent';
import Page from '@bfly/ui/Page';
import Text from '@bfly/ui/Text';

import isInApp from '../utils/isInApp';
import CrossOrgInviteForm from './CrossOrgInviteForm';
import OrganizationMembershipList from './OrganizationMembershipList';
import { ContactsPage_viewer as Viewer } from './__generated__/ContactsPage_viewer.graphql';

interface Props {
  viewer: Viewer;
}

function ContactsPage({ viewer }: Props) {
  const { router } = useRouter();

  useEffect(() => {
    if (!isInApp()) router.replace('/');
  }, [router]);

  return (
    <Page>
      <Page.Container>
        <MainContent>
          <Heading align="center">
            <FormattedMessage
              id="contacts.title"
              defaultMessage="Your TeleGuidance contacts"
            />
          </Heading>
          <Text align="center" color="subtitle" as="div" className="mb-6">
            <FormattedMessage
              id="contacts.body"
              defaultMessage="Invite anyone with a Butterfly account. Once your contact accepts the email invitation, you’ll be able to call them."
            />
          </Text>
          <CrossOrgInviteForm viewer={viewer} className="mb-4" />
          <OrganizationMembershipList viewer={viewer} hideAddContacts />
        </MainContent>
      </Page.Container>
    </Page>
  );
}

export default createFragmentContainer(ContactsPage, {
  viewer: graphql`
    fragment ContactsPage_viewer on Viewer {
      ...CrossOrgInviteForm_viewer
      ...OrganizationMembershipList_viewer
    }
  `,
});
