import React from 'react';

import { describeArc } from './Arc';

interface Props extends React.SVGProps<SVGPathElement> {
  cx: number;
  cy: number;
  r: number;
  startAngle: number;
  endAngle: number;
  sweep?: 0 | 1;
}

/**
 * Render a Path as an Slice described by a segment of the circumference of circle
 */
function Slice({
  cx,
  cy,
  r,
  startAngle,
  endAngle,
  sweep = 0,
  ...props
}: Props) {
  // const [sx, sy] = polarToCartesian(cx, cy, r, endAngle);
  const arc = describeArc(cx, cy, r, startAngle, endAngle, sweep);

  return <path {...props} d={`${arc} L ${cx} ${cy} Z`} />;
}

export default Slice;
