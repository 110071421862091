/* eslint-disable */
/* prettier-ignore-start */
// [GENERATED FILE; DO NOT EDIT BY HAND]

import React from "react"

var element = React.createElement("svg", {
  viewBox: "0 0 40 40",
  fill: "currentColor",
  "aria-hidden": "true"
}, React.createElement("path", {
  fill: "none",
  d: "M.01.01H40V40H.01z"
}), React.createElement("circle", {
  cx: "20",
  cy: "20",
  r: "19.99",
  fill: "#ef0000",
  fillRule: "evenodd",
  clipRule: "evenodd"
}), React.createElement("path", {
  d: "M20.344 14.907c-.117-.001-.228.002-.344.002-.116 0-.226-.004-.344-.002-6.227.12-10.032 2.027-10.155 6.029-.028.606.427 1.141.884 1.757.249.329.454.454.857.381.069.001 2.739-.702 3.551-.916.198-.052.391-.16.49-.339.314-.566.063-1.568.018-2.143-.175-.651.06-1.05.584-1.161 1.258-.312 2.683-.475 4.115-.487 1.433.012 2.858.175 4.115.487.524.111.759.511.584 1.161-.045.575-.296 1.577.018 2.143.099.179.292.287.49.339.812.214 3.482.917 3.551.916.403.073.608-.052.857-.381.457-.616.913-1.151.884-1.757-.124-4.002-3.929-5.909-10.155-6.029z",
  fill: "#fff"
}));

var Svg = React.forwardRef(function (props, ref) {
  var next = { ref: ref };
  for (var key in props) {
    if (props.hasOwnProperty(key)) {
      next[key] = props[key];
    }
  }
  return React.cloneElement(element, next);
});
Svg.displayName = "End";
Svg.element = element;
export default Svg;
