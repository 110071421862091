
import * as React from 'react';
import { MdStar, MdStarBorder } from 'react-icons/md';

interface Props {
  onChange: (value: number) => void;
  value: number;
}

const styles = require('./StarRatingInput-styles.module.scss');

export default class StarRatingInput extends React.Component<Props, {}> {
  renderStar = (value: number) => {
    const Icon = value <= this.props.value ? MdStar : MdStarBorder;
    return (
      <Icon
        key={value}
        onClick={() => this.props.onChange(value)}
        className={styles.star}
      />
    );
  };

  render() {
    return <span>{[1, 2, 3, 4, 5].map(this.renderStar)}</span>;
  }
}
