import useGlobalListener from '@restart/hooks/useGlobalListener';

const DISABLED_TAGS = ['input', 'select', 'textarea'];
const SPACE_DISABLED_TAGS = ['button'];

export default function useGlobalKeyDownListener(
  handler: (event: DocumentEventMap['keydown']) => any,
  capture?: AddEventListenerOptions | boolean,
) {
  const handleEvent = (e: DocumentEventMap['keydown']) => {
    const active = document.activeElement as HTMLElement | null;

    if (active) {
      const activeTag = active.tagName.toLowerCase();

      if (
        DISABLED_TAGS.includes(activeTag) ||
        (e.key === ' ' && SPACE_DISABLED_TAGS.includes(activeTag)) ||
        active.contentEditable === 'true'
      ) {
        return;
      }
    }

    handler(e);
  };

  return useGlobalListener('keydown', handleEvent, capture);
}
