/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import React from 'react';
import { FormattedMessage } from 'react-intl';
import CloseSheet from '@bfly/icons/CloseSheet';
import Button from '@bfly/ui/Button';

interface Props extends React.ComponentProps<typeof Button> {
  children?: React.ReactElement;
}

function CloseButton({ children, ...props }: Props) {
  return (
    <Button theme="link" {...props}>
      <CloseSheet
        css={[require("./CloseButton-CssProp1_CloseSheet.module.scss"), []]}
      />
      {children || (
        <FormattedMessage id="closeBtn.close" defaultMessage="Close" />
      )}
    </Button>
  );
}

export default CloseButton;
