import React from 'react';

interface Props {
  seconds: number;
}

function Duration({ seconds }: Props) {
  const minutes = Math.floor(seconds / 60);

  return (
    <>
      {String(minutes)}:{(seconds % 60).toFixed(0).padStart(2, '0')}
    </>
  );
}

export default Duration;
