/* eslint-disable max-classes-per-file */

// https://stackoverflow.com/a/1583281/130164
// circular buffer
// Circular buffer storage. Externally-apparent 'length' increases indefinitely
// while any items with indexes below length-n will be forgotten (undefined
// will be returned if you try to get them, trying to set is an exception).
// n represents the initial length of the array, not a maximum

export class CircularBuffer {
  memory: number[];

  length: number;

  IndexError = {};

  constructor(n: number) {
    this.memory = new Array(n);
    this.length = 0;
  }

  toString() {
    return `[object CircularBuffer(${this.memory.length}) length ${this.length}]`;
  }

  push(v: number) {
    this.memory[this.length % this.memory.length] = v;
    this.length++;
  }

  howManyFilled() {
    // how many objects are currently living in the array?
    // when we get going, this will just be the buffer size used to define the array
    // but this function is useful before we've reached the buffer size
    // until then, part of the array will be unfilled
    return this.length < this.memory.length ? this.length : this.memory.length;
  }

  resizeBuffer(newSize: number) {
    const oldSize = this.memory.length;

    if (oldSize === newSize) return;

    /*
    we either expand the array or trim it
    for trimming, we favoring entries at the end over entries at the beginning
    so normal trimming functionality doesn't apply
    (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/length)

    cases to handle:
      * new buffer size = old buffer size: do nothing
      * new size < old size and howManyFilled == old_size: slice array from old_size-new_size to old_size (exclusive)
      * howManyFilled < old_size and new size < old size:
          slice array from max(howManyFilled-new_size,0) to howManyFilled (exclusive), then lengthen array
      * old size < new size  --> easy, just expand the array size
    */

    const filledLength = this.howManyFilled();

    if (oldSize < newSize) {
      // expand array size in place
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/length
      this.memory.length = newSize;
    } else if (filledLength === oldSize) {
      // elseif implies (new_size < old_size)
      this.memory = this.memory.slice(oldSize - newSize); // slice to end
    } else {
      // howManyFilled < bufferSize and new size < old size
      this.memory = this.memory.slice(
        Math.max(filledLength - newSize, 0),
        filledLength,
      ); // slice to end
      // then length array to allow for new_size entries
      this.memory.length = newSize;
    }
  }
}

export function exponentialSmoothing(buffer: CircularBuffer): number {
  // smooth the array starting at start_index (inclusive)
  // the end of the array is most recent (i.e. push to the back)

  // https://en.wikipedia.org/wiki/Exponential_smoothing
  // https://en.wikipedia.org/wiki/Moving_average#Exponential_moving_average
  // https://stackoverflow.com/questions/40057020/calculating-exponential-moving-average-ema-using-javascript
  // https://github.com/jonschlinkert/exponential-moving-average/blob/master/index.js

  // var operatingmemory = values.slice(start_index)
  // TODO: this isn't supported in regular JS; values.memory.slice loses circularbuffer

  // after we fill at least buffer size, then this is just operatingmemory.length
  const n = buffer.howManyFilled();

  const k = 2 / (n + 1);
  const j = 1 - k;

  const emas = [buffer.memory[0]]; // recursively; start with first entry
  for (let i = 1; i < n; i++) {
    if (buffer.memory[i] === undefined) {
      // we've reached the point where the rest of the array is undefined
      // (i.e. buffer hasn't filled up yet)
      return emas[i - 1];
    }

    emas.push(k * buffer.memory[i] + j * emas[i - 1]);
  }

  return emas[n - 1]; // get the last value
}

export type Dictionary<T> = { [key: string]: T };

export class SmoothingDictionary<T extends Dictionary<number>> {
  private bufferSize: number;

  private cache: Map<string, CircularBuffer> = new Map();

  constructor(valueRange = 5) {
    this.bufferSize = valueRange;
  }

  setValueRangeRange(newValueRange: number) {
    if (this.bufferSize === newValueRange) return;

    this.bufferSize = newValueRange;
    this.cache.forEach(v => v.resizeBuffer(newValueRange));
  }

  smooth(values: T, newValueRange?: number): T {
    // passing bufferSize via `smooth` is more amenable to a streaming consumer
    if (newValueRange != null) this.bufferSize = newValueRange;

    // automatically smooth elements of a dictionary that keeps coming in
    // e.g. pose estimation dict has same keys but changing values
    // this automatically sets up smoothing for each key
    // pass in the current instance of the dict and the cache for this dict (initialize to {} before calling this)

    // important: remember https://stackoverflow.com/a/13104500/130164 re how cache is passed
    // "copy of a reference"
    // const output = new Map<string, number>();
    // const output: {[key: string]: number} = {};
    const output: Dictionary<number> = { ...values };

    for (const [key, value] of Object.entries(values)) {
      const cached =
        this.cache.get(key) || new CircularBuffer(this.bufferSize);

      if (!this.cache.has(key)) this.cache.set(key, cached);

      // this should be fine since if it's the same it's a noop
      cached.resizeBuffer(this.bufferSize);
      cached.push(value);
      output[key] = exponentialSmoothing(cached);
    }

    // return smoothed dict
    return output as T;
  }
}
