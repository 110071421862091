/* eslint-disable */
/* prettier-ignore-start */
// [GENERATED FILE; DO NOT EDIT BY HAND]

import React from "react"

var element = React.createElement("svg", {
  viewBox: "0 0 60 60",
  fill: "currentColor",
  "aria-hidden": "true"
}, React.createElement("g", {
  fill: "none",
  fillRule: "evenodd"
}, React.createElement("circle", {
  cx: "30",
  cy: "30",
  r: "30",
  fill: "var(--icon-bg-color)"
}), React.createElement("g", {
  fill: "var(--icon-color)"
}, React.createElement("g", {
  transform: "translate(23.182 10.91)"
}, React.createElement("rect", {
  width: "2.727",
  height: "38.182",
  x: "5.455",
  rx: "1.364"
}), React.createElement("path", {
  d: "M11.401 5.838a1.306 1.306 0 011.851.002c.512.512.512 1.34.003 1.85l-5.566 5.563c-.51.51-1.335.513-1.85-.002a1.308 1.308 0 01-.003-1.85l5.565-5.563zm0 26.506c.51.51 1.336.513 1.851-.003.512-.511.512-1.34.003-1.85L7.689 24.93a1.306 1.306 0 00-1.85.002 1.308 1.308 0 00-.003 1.85l5.565 5.563z"
}), React.createElement("path", {
  d: "M2.235 5.838a1.306 1.306 0 00-1.85.002 1.308 1.308 0 00-.003 1.85l5.565 5.563c.51.51 1.335.513 1.85-.002a1.308 1.308 0 00.003-1.85M2.235 32.344c-.51.51-1.335.513-1.85-.003a1.308 1.308 0 01-.003-1.85l5.565-5.562a1.306 1.306 0 011.85.002c.512.512.513 1.34.003 1.85l-5.565 5.563z"
})), React.createElement("g", {
  transform: "rotate(90 12.955 36.136)"
}, React.createElement("rect", {
  width: "2.727",
  height: "38.182",
  x: "5.455",
  rx: "1.364"
}), React.createElement("path", {
  d: "M11.401 5.838a1.306 1.306 0 011.851.002c.512.512.512 1.34.003 1.85l-5.566 5.563c-.51.51-1.335.513-1.85-.002a1.308 1.308 0 01-.003-1.85l5.565-5.563zm0 26.506c.51.51 1.336.513 1.851-.003.512-.511.512-1.34.003-1.85L7.689 24.93a1.306 1.306 0 00-1.85.002 1.308 1.308 0 00-.003 1.85l5.565 5.563z"
}), React.createElement("path", {
  d: "M2.235 5.838a1.306 1.306 0 00-1.85.002 1.308 1.308 0 00-.003 1.85l5.565 5.563c.51.51 1.335.513 1.85-.002a1.308 1.308 0 00.003-1.85L2.235 5.838zm0 26.506c-.51.51-1.335.513-1.85-.003a1.308 1.308 0 01-.003-1.85l5.565-5.562a1.306 1.306 0 011.85.002c.512.512.513 1.34.003 1.85"
})))));

var Svg = React.forwardRef(function (props, ref) {
  var next = { ref: ref };
  for (var key in props) {
    if (props.hasOwnProperty(key)) {
      next[key] = props[key];
    }
  }
  return React.cloneElement(element, next);
});
Svg.displayName = "Freeze";
Svg.element = element;
export default Svg;
