/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LaunchDarkly_viewer = {
    readonly memberships: ReadonlyArray<{
        readonly organization: {
            readonly viewerLaunchDarklyConfig: {
                readonly user: unknown | null;
                readonly hash: string | null;
                readonly state: unknown | null;
            } | null;
        } | null;
    } | null> | null;
    readonly " $refType": "LaunchDarkly_viewer";
};
export type LaunchDarkly_viewer$data = LaunchDarkly_viewer;
export type LaunchDarkly_viewer$key = {
    readonly " $data"?: LaunchDarkly_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"LaunchDarkly_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "LaunchDarkly_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "memberships",
      "storageKey": null,
      "args": null,
      "concreteType": "ViewerMembership",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "organization",
          "storageKey": null,
          "args": null,
          "concreteType": "Organization",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "viewerLaunchDarklyConfig",
              "storageKey": null,
              "args": null,
              "concreteType": "LaunchDarklyConfig",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "user",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "hash",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "state",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
(node as any).hash = 'aa00f5d5aa92db79478a7150518ff865';
export default node;
