/* tslint:disable */
/* eslint-disable */
/* @relayHash 9fda86dce9c915912852593a7751cc9e */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExpertTelemedSessionUpdatedSubscriptionInput = {
    expertUserId: unknown;
    clientSubscriptionId?: string | null;
};
export type CallHistoryList_SubscriptionVariables = {
    input: ExpertTelemedSessionUpdatedSubscriptionInput;
};
export type CallHistoryList_SubscriptionResponse = {
    readonly expertTelemedSessionUpdated: {
        readonly telemedSessionEdge: {
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"CallHistoryItem_session">;
            } | null;
        } | null;
    } | null;
};
export type CallHistoryList_Subscription = {
    readonly response: CallHistoryList_SubscriptionResponse;
    readonly variables: CallHistoryList_SubscriptionVariables;
};



/*
subscription CallHistoryList_Subscription(
  $input: ExpertTelemedSessionUpdatedSubscriptionInput!
) {
  expertTelemedSessionUpdated(input: $input) {
    telemedSessionEdge {
      node {
        id
        ...CallHistoryItem_session
      }
    }
  }
}

fragment Avatar_profile on UserProfile {
  name
  avatar {
    thumbnails {
      width
      url
    }
  }
}

fragment CallHistoryItem_session on TelemedSession {
  createdAt
  organization {
    name
    id
  }
  novice {
    name
    id
    ...Avatar_profile
  }
  status
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ExpertTelemedSessionUpdatedSubscriptionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CallHistoryList_Subscription",
    "type": "Subscription",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "expertTelemedSessionUpdated",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ExpertTelemedSessionUpdatedSubscriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "telemedSessionEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "TelemedSessionEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "TelemedSession",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "CallHistoryItem_session",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CallHistoryList_Subscription",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "expertTelemedSessionUpdated",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ExpertTelemedSessionUpdatedSubscriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "telemedSessionEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "TelemedSessionEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "TelemedSession",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "createdAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "organization",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Organization",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "novice",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserProfile",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "avatar",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Avatar",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "thumbnails",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Thumbnail",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "width",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "url",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "status",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "subscription",
    "name": "CallHistoryList_Subscription",
    "id": null,
    "text": "subscription CallHistoryList_Subscription(\n  $input: ExpertTelemedSessionUpdatedSubscriptionInput!\n) {\n  expertTelemedSessionUpdated(input: $input) {\n    telemedSessionEdge {\n      node {\n        id\n        ...CallHistoryItem_session\n      }\n    }\n  }\n}\n\nfragment Avatar_profile on UserProfile {\n  name\n  avatar {\n    thumbnails {\n      width\n      url\n    }\n  }\n}\n\nfragment CallHistoryItem_session on TelemedSession {\n  createdAt\n  organization {\n    name\n    id\n  }\n  novice {\n    name\n    id\n    ...Avatar_profile\n  }\n  status\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'd25bc3b3220e9afaaa85c97e1959e62b';
export default node;
