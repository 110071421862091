/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import Layout from '@4c/layout';
import Text from '@bfly/ui/Text';

import Avatar from './Avatar';
import EndCallButton from './EndCallButton';
import LocalVideoButton from './LocalVideoButton';
import MicButton from './MicButton';
import MuteButton from './MuteButton';
import { CallControls_session as Session } from './__generated__/CallControls_session.graphql';

interface Props {
  className?: string;
  session: Session;
}

function CallControls({ session, className }: Props) {
  return (
    <Layout
      pad={3}
      align="center"
      className={className}
      css={[require("./CallControls-CssProp1_Layout.module.scss"), []]}
    >
      <Avatar profile={session.novice!} size="medium" />
      <Layout direction="column">
        <Text variant="lg" color="headline">
          {session.novice!.name}
        </Text>
        <Text color="subtitle">{session.organization!.name}</Text>
      </Layout>

      <Layout.Spacer />
      <MicButton />
      <MuteButton />
      <LocalVideoButton />

      <EndCallButton session={session} />
    </Layout>
  );
}

export default createFragmentContainer(CallControls, {
  session: graphql`
    fragment CallControls_session on TelemedSession {
      novice {
        ...Avatar_profile
        name
      }
      organization {
        name
      }
      ...EndCallButton_session
    }
  `,
});
