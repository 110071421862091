import { getType as t } from 'typesafe-actions';
import { captureSaved, startedCine, stoppedCine } from '../actions/imaging';
import { Capture } from '../utils/capture';
import { RootAction } from './index';

export type CaptureState = {
  recording: boolean;
  captures: Capture[];
};

const initialState = {
  recording: false,
  captures: [],
};

export default (state: CaptureState = initialState, action: RootAction) => {
  switch (action.type) {
    case t(captureSaved):
      return { ...state, captures: [...state.captures, action.payload] };
    case t(startedCine):
      return { ...state, recording: true };
    case t(stoppedCine):
      return { ...state, recording: false };
    default:
      return state;
  }
};
