import { getType as t } from 'typesafe-actions';
import {
  instructionActivated,
  instructionTimeout,
  tentativeInstructionCanceled,
  tentativeInstructionShown,
} from '../actions/instruction';
import { VisualCommand } from '../Instruction';
import { RootAction } from './index';

export type CommandState = {
  activeId?: string;
  activeCommand: VisualCommand | null;
  tentativeCommand: VisualCommand | null;
};

const initialState = {
  activeCommand: null,
  tentativeCommand: null,
};

export default (
  state: CommandState = initialState,
  action: RootAction,
): CommandState => {
  switch (action.type) {
    case t(tentativeInstructionShown):
      return {
        ...state,
        tentativeCommand: action.payload,
      };
    case t(instructionActivated):
      return {
        ...state,
        tentativeCommand: null,
        activeCommand: action.payload.command,
        activeId: action.payload.commandId,
      };
    case t(instructionTimeout):
      if (action.payload === state.activeId) {
        // only allow auto timeout if current command ID matches timeout's command ID
        return initialState;
      }
      return state;
    case t(tentativeInstructionCanceled):
      return { ...state, tentativeCommand: null };
    default:
      return state;
  }
};
