/* eslint-disable */
/* prettier-ignore-start */
// [GENERATED FILE; DO NOT EDIT BY HAND]

import React from "react"

var element = React.createElement("svg", {
  viewBox: "0 0 40 40",
  fill: "currentColor",
  "aria-hidden": "true"
}, React.createElement("path", {
  fill: "none",
  d: "M.01.01H40V40H.01z"
}), React.createElement("circle", {
  cx: "20",
  cy: "20",
  r: "19.99",
  fill: "var(--icon-bg-color)",
  fillRule: "evenodd",
  clipRule: "evenodd"
}), React.createElement("path", {
  fill: "var(--icon-color)",
  d: "M28.166 27.821a.744.744 0 01-.53-.22L13.493 13.459a.75.75 0 111.061-1.061L28.696 26.54a.75.75 0 01-.53 1.281zM25.09 12.11c0-.988-1.196-1.482-1.893-.781L19.59 14.96l5.501 5.501V12.11zm-11.779 4.153c-.815 0-1.477.661-1.477 1.477v4.521c0 .815.661 1.477 1.477 1.477h4.369c.393 0 .77.157 1.047.436l4.47 4.498c.697.701 1.893.208 1.893-.781v-.36L13.822 16.263h-.511z"
}));

var Svg = React.forwardRef(function (props, ref) {
  var next = { ref: ref };
  for (var key in props) {
    if (props.hasOwnProperty(key)) {
      next[key] = props[key];
    }
  }
  return React.cloneElement(element, next);
});
Svg.displayName = "Volume";
Svg.element = element;
export default Svg;
