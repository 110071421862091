
import classNames from 'classnames';
import React from 'react';

const styles = require('./Range-styles.module.scss');

const Range = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement>
>(({ className, ...props }, ref) => {
  return (
    <input
      {...props}
      ref={ref}
      type="range"
      className={classNames(className, styles.range)}
    />
  );
});

Range.displayName = 'Range';

export default Range;
