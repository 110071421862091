
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '@4c/layout';
import Button from '@bfly/ui/Button';

import { setShowToast } from '../actions/session';
import { RootState } from '../store';

const styles = require('./SessionToast-styles.module.scss');

interface Props {
  children: React.ReactNode;
}

function SessionToast({ children }: Props) {
  const showToast = useSelector((s: RootState) => s.media.showToast);
  const dispatch = useDispatch();

  function onDismiss() {
    dispatch(setShowToast(false));
  }

  if (!showToast) {
    return null;
  }

  return (
    <Layout
      role="presentation"
      align="center"
      justify="space-between"
      onClick={onDismiss}
      className={styles.toast}
    >
      <Layout align="center">{children}</Layout>
      <Button className={styles.dismissButton} theme="link">
        <FormattedMessage id="toast.dismiss" defaultMessage="Dismiss" />
      </Button>
    </Layout>
  );
}

export default SessionToast;
