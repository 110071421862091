import 'focus-visible';

const polyfills = [];

if (typeof ImageCapture === 'undefined') {
  // @ts-ignore
  polyfills.push(import('image-capture'));
}

if (typeof window.ResizeObserver === 'undefined')
  polyfills.push(
    import('resize-observer-polyfill').then(d => {
      window.ResizeObserver = d.default;
    }),
  );

if (typeof Intl !== 'undefined' && !Intl.PluralRules) {
  // @ts-ignore
  polyfills.push(import('intl-pluralrules'));
}

export default Promise.all(polyfills);
