import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { localAudioToggled } from '../actions/media';
import Mic from '../icons/Mic';
import { RootState } from '../store';
import IconToggleButton from './IconToggleButton';

function MicButton() {
  const dispatch = useDispatch();
  const enabled = useSelector((s: RootState) => s.media.localAudioEnabled);

  return (
    <IconToggleButton
      onClick={() => dispatch(localAudioToggled(!enabled))}
      enabled={enabled}
      icon={<Mic />}
    />
  );
}

export default MicButton;
