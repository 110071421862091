/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Layout from '@4c/layout';
import Ultrasound from '@bfly/icons/Ultrasound';
import Button from '@bfly/ui/Button';
import Carousel from '@bfly/ui/Carousel';
import PreviewItem from '@bfly/ui/PreviewItem';
import PreviewRoll from '@bfly/ui/PreviewRoll';
import Text from '@bfly/ui/Text';
import useGlobalKeyDownListener from '@bfly/ui/useGlobalKeyDownListener';

import { RootState } from '../store';
import ExamReelThumbnail from './ExamReelThumbnail';
import Panel from './Panel';

import utilsStyles from '../styles/utils.module.scss';

const styles = require('./ExamReel-styles.module.scss');

// Extracted into a hook so we can use the upstream useElementNavigation if/when it makes sense
function useElementsNavigation<T>(elements: T[], defaultActiveIndex = 0) {
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);
  const lastIdx = elements.length - 1;
  const prev = useCallback(() => {
    setActiveIndex(i => Math.max(i - 1, 0));
  }, []);

  const next = useCallback(() => {
    setActiveIndex(i => Math.min(i + 1, lastIdx));
  }, [lastIdx]);

  useGlobalKeyDownListener(({ key }) => {
    if (key === 'ArrowLeft' || key === 'Left') {
      prev();
    } else if (key === 'ArrowRight' || key === 'Right') {
      next();
    }
  });

  return [
    elements[activeIndex],
    {
      isFirst: activeIndex === 0,
      isLast: activeIndex === elements.length - 1,
      activeIndex,
      setActiveIndex,
      prev,
      next,
    },
  ] as const;
}

function ExamReel({ onClose }: { onClose(): void }) {
  const intl = useIntl();
  const fixColor = useSelector(
    (s: RootState) => !s.session.heliosCapabilities.correctProbeFeedColor,
  );
  const captures = useSelector((s: RootState) => s.capture.captures);
  const [
    activeCapture,
    { prev, next, isFirst, isLast, activeIndex, setActiveIndex },
  ] = useElementsNavigation(captures);
  return (
    <>
      <Layout
        justify="center"
        align="center"
        css={[require("./ExamReel-CssProp1_Layout.module.scss"), []]}
      >
        <Carousel
          isFirst={isFirst}
          isLast={isLast}
          onPrev={prev}
          onNext={next}
          className="h-100"
        >
          {activeCapture.type === 'cine' ? (
            <video
              loop
              autoPlay
              controls
              src={activeCapture.contents}
              className={classNames(
                styles.item,
                fixColor && utilsStyles.fixColor,
              )}
            />
          ) : (
            <img
              src={activeCapture.contents}
              alt={intl.formatMessage({
                id: 'examReel.activeCapture',
                defaultMessage: 'A teleguided ultrasound frame capture',
              })}
              className={classNames(
                styles.item,
                fixColor && utilsStyles.fixColor,
              )}
            />
          )}
        </Carousel>
      </Layout>

      <Panel className="m-5 py-3" direction="column" pad={3}>
        {captures.length > 1 && (
          <>
            <PreviewRoll activeIndex={activeIndex} itemSize={80}>
              {captures.map((capture, idx) => (
                <PreviewItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  active={activeIndex === idx}
                  onClick={() => setActiveIndex(idx)}
                >
                  <ExamReelThumbnail item={capture} />
                </PreviewItem>
              ))}
            </PreviewRoll>
            <Panel.Hr />
          </>
        )}
        <Layout justify="space-between" className="px-4">
          <Button theme="link" onClick={onClose}>
            <Ultrasound css={[require("./ExamReel-CssProp2_Ultrasound.module.scss")]} className="mr-1" />
            <FormattedMessage id="examReel.scan" defaultMessage="Scan" />
          </Button>

          <Text>
            <FormattedMessage
              id="examReel.numCaptures"
              defaultMessage="{numberOfCaptures} captures"
              values={{ numberOfCaptures: captures.length }}
            />
          </Text>
        </Layout>
      </Panel>
    </>
  );
}

export default ExamReel;
