/* tslint:disable */
/* eslint-disable */
/* @relayHash 74964c4c721adbb59b700af8c1675a05 */

import { ConcreteRequest } from "relay-runtime";
export type SendTelemedPresenceHeartbeatInput = {
    active?: boolean | null;
    clientMutationId?: string | null;
};
export type DashboardPage_SendTelemedPresenceHeartbeatMutationVariables = {
    input: SendTelemedPresenceHeartbeatInput;
};
export type DashboardPage_SendTelemedPresenceHeartbeatMutationResponse = {
    readonly sendTelemedPresenceHeartbeat: {
        readonly __typename: string;
    } | null;
};
export type DashboardPage_SendTelemedPresenceHeartbeatMutation = {
    readonly response: DashboardPage_SendTelemedPresenceHeartbeatMutationResponse;
    readonly variables: DashboardPage_SendTelemedPresenceHeartbeatMutationVariables;
};



/*
mutation DashboardPage_SendTelemedPresenceHeartbeatMutation(
  $input: SendTelemedPresenceHeartbeatInput!
) {
  sendTelemedPresenceHeartbeat(input: $input) {
    __typename
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SendTelemedPresenceHeartbeatInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "sendTelemedPresenceHeartbeat",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendTelemedPresenceHeartbeatPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "__typename",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DashboardPage_SendTelemedPresenceHeartbeatMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DashboardPage_SendTelemedPresenceHeartbeatMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DashboardPage_SendTelemedPresenceHeartbeatMutation",
    "id": null,
    "text": "mutation DashboardPage_SendTelemedPresenceHeartbeatMutation(\n  $input: SendTelemedPresenceHeartbeatInput!\n) {\n  sendTelemedPresenceHeartbeat(input: $input) {\n    __typename\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'cbc4cd537636f7d6dc9c878ec7e0aa7b';
export default node;
