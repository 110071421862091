import React from 'react';
import { useIntl } from 'react-intl';
import Button from '@bfly/ui/Button';

import takeCall from '../assets/take-call-icon.svg';

interface Props {
  onClick: () => void;
}

export default function TakeCallButton({ onClick }: Props) {
  const intl = useIntl();

  const message = intl.formatMessage({
    id: 'incomingRequest.takeCall',
    defaultMessage: 'Take call',
  });

  return (
    <Button theme="success" size="large" onClick={onClick}>
      <img src={takeCall} alt={message} className="mr-2" />
      {message}
    </Button>
  );
}
