import { Camera, Matrix4, Vector3 } from 'three';
import { Point, Radians } from '@bfly/utils/math';

import {
  Dimensions,
  PoseEstimation,
  PoseRotation,
  PoseTranslation,
} from '../types';

const n360 = Math.PI * 2;

export interface Pose {
  rotation: Matrix4;
  translation: Vector3;
}

export const rotationIsEqual = (
  nextRotation: PoseRotation,
  lastRotation: PoseRotation,
) =>
  nextRotation.m11 === lastRotation.m11 &&
  nextRotation.m12 === lastRotation.m12 &&
  nextRotation.m13 === lastRotation.m13 &&
  nextRotation.m21 === lastRotation.m21 &&
  nextRotation.m22 === lastRotation.m22 &&
  nextRotation.m23 === lastRotation.m23 &&
  nextRotation.m31 === lastRotation.m31 &&
  nextRotation.m32 === lastRotation.m32 &&
  nextRotation.m33 === lastRotation.m33;

export const translationIsEqual = (
  nextTranslation: PoseTranslation,
  lastTranslation: PoseTranslation,
) =>
  nextTranslation.x === lastTranslation.x &&
  nextTranslation.y === lastTranslation.y &&
  nextTranslation.z === lastTranslation.z;

export function rotationMatrixFromPose(rotation: PoseRotation) {
  // prettier-ignore
  // The matrix from IOS is column ordered, whereas THREE's `set()`
  // method is row ordered so we need to manually correct.
  return new Matrix4().set(
    rotation.m11,       rotation.m21,       rotation.m31,      0.0,
    rotation.m12,       rotation.m22,       rotation.m32,      0.0,
    rotation.m13,       rotation.m23,       rotation.m33,      0.0,
    0.0,                0.0,                0.0,               1.0,
  )
}

export function getMathFriendlyPose(pose: PoseEstimation): Pose {
  return {
    rotation: rotationMatrixFromPose(pose.rotation),
    translation: new Vector3(
      pose.translation.x,
      pose.translation.y,
      pose.translation.z,
    ),
  };
}

const distance = (x1: number, y1: number, x2: number, y2: number) =>
  Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

function project(
  vector: Vector3,
  camera: Camera,
  { width, height }: Dimensions,
): Point {
  const w2 = width / 2;
  const h2 = height / 2;

  const { x, y } = vector.clone().project(camera);

  return [x * w2 + w2, y * h2 + h2];
}

/**
 * returns an angle (in degrees) representing the rotation of the
 * probe indicator dot relative to the camera.
 *
 * To calculate the angle we project two points into screen coordinates:
 * - p1: The probe position (origin)
 * - p2: a point offset along the x-axis representing the indicator dot.
 *
 * We then use the P1 as the center of a circle with P2 along the circumference
 *
 * calculating the radius we get a third point P3 at (y=0, P1.x + radius)
 *
 * Now we have a triangle and can determine the angle via the cosine formula
 */
export function getScreenRelativeProbePosition(
  origin: Vector3,
  poseRotation: Matrix4,
  camera: Camera,
  dimensions: Dimensions,
): Radians {
  // const facingAway = isProbeFacingAway(poseRotation, state.camera);

  // the center of our circle
  const [cx, cy] = project(origin, camera, dimensions);

  const dotMatrix = poseRotation
    .clone()
    .multiply(new Matrix4().makeTranslation(10, 0, 0));

  // Point representing the indicator dot an arbitrary distance from the center's x
  const [x1, y1] = project(
    new Vector3().setFromMatrixPosition(dotMatrix),
    camera,
    dimensions,
  );

  const radius = distance(x1, y1, cx, cy);

  const [x2, y2] = [cx + radius, cy];

  // Cosine formula
  // c^2 = a^2 + b^2 − 2ab * cos(α)
  //
  // Solving for the angle
  // α = acos((a^2 + b^2 - c^2) / 2ab)
  //
  // Since a and b = r here:
  // α = acos((2r^2 - c^2) / 2r^2)
  //
  const c = distance(x1, -y1, x2, -y2);
  const r2 = 2 * radius ** 2;
  let angle = Math.acos((r2 - c ** 2) / r2);

  // we adjust for the math giving 0 -> 180 on both sides of the x axis
  if (y1 < cy) angle *= -1; // Math.PI - angle + Math.PI;
  angle = (n360 + angle) % n360;
  return angle;
}
