import { combineLatest, interval } from 'rxjs';
import {
  filter,
  first,
  skipWhile,
  take,
  takeUntil,
  takeWhile,
  withLatestFrom,
} from 'rxjs/operators';
import { tag } from 'rxjs-spy/operators/tag';

import {
  noviceLeftTwilioRoom,
  receivedConnectionStats,
  remoteDataTrackConnected,
} from '../actions/session';
import { RootState } from '../store';
import StoreSubject from '../store/StoreSubject';
import * as Analytics from '../utils/Analytics';
import { callEnded } from '../utils/selectors';
import RoomConnection from './RoomConnection';

// Dispatches actions based on events in the room connection.
function setupActionsFromConnectionEvents(
  store$: StoreSubject<RootState>,
  connection: RoomConnection,
) {
  const callEnded$ = callEnded(store$);
  connection.stats$
    .pipe(takeUntil(callEnded$))
    .subscribe(stats => store$.dispatch(receivedConnectionStats(stats)));

  connection.remoteData$
    .pipe(
      filter(track => track != null),
      takeUntil(callEnded$),
    )
    .subscribe(() => store$.dispatch(remoteDataTrackConnected()));

  connection.remoteParticipant$
    .pipe(
      // Room observables start out null, so skip the first one it emits
      skipWhile(p => p == null),
      filter(p => p == null),
      takeUntil(callEnded$),
    )
    .subscribe(() => store$.dispatch(noviceLeftTwilioRoom()));
}

function setupTrackAnalytics(connection: RoomConnection) {
  connection.probe$
    .pipe(first(p => p != null))
    .subscribe(() => Analytics.track('novice/probeFirstConnected'));
  connection.camera$
    .pipe(first(c => c != null))
    .subscribe(() => Analytics.track('novice/cameraFirstConnected'));
  connection.remoteData$
    .pipe(first(t => t != null))
    .subscribe(() => Analytics.track('novice/remoteDataFirstConnected'));
  connection.remoteAudio$
    .pipe(first(a => a != null))
    .subscribe(() => Analytics.track('novice/remoteAudioFirstConnected'));

  // Ping analytics every 10s if not all remote tracks have attached.
  // This the case where the tracks never connect and the expert gives
  // up and leaves.
  interval(10000)
    .pipe(
      withLatestFrom(
        connection.remoteParticipant$,
        connection.probe$,
        connection.camera$,
        connection.remoteData$,
        connection.remoteAudio$,
      ),
      takeWhile(
        ([, participant, probe, camera, remoteData, remoteAudio]) =>
          !participant || !probe || !camera || !remoteData || !remoteAudio,
      ),
      // Stop pinging after a while.
      take(10),
    )
    .subscribe(([, participant, probe, camera, remoteData, remoteAudio]) => {
      Analytics.track('novice/slowToJoinRoom', {
        remoteParticipant: !!participant,
        remoteData: !!remoteData,
        probe: !!probe,
        camera: !!camera,
        remoteAudio: !!remoteAudio,
      });
    });
}

// Triggers changes in the room connection based on changes to the Redux store.
function setupConnectionChangesFromActions(
  store$: StoreSubject<RootState>,
  connection: RoomConnection,
) {
  store$
    .select(s => s.media.localAudioEnabled)
    .subscribe(
      enabled => {
        console.log(`Local audio toggled to ${enabled}`);
        if (enabled) {
          connection.addLocalAudio();
        } else {
          connection.removeLocalAudio();
        }
      },
      undefined,
      () => console.log('local audio ended'),
    );

  store$
    .select(s => s.media.localVideoEnabled)
    .subscribe(
      enabled => {
        console.log(`Local video toggled to ${enabled}`);
        if (enabled) {
          connection.addLocalVideo();
        } else {
          connection.removeLocalVideo();
        }
      },
      undefined,
      () => console.log('local video ended'),
    );

  combineLatest(
    store$.select(s => s.media.remoteAudioEnabled),
    connection.remoteAudio$,
  )
    .pipe(tag('remote audio:enabled'))
    .subscribe(
      ([enabled, track]) => {
        if (track) {
          // eslint-disable-next-line no-param-reassign
          track.mediaStreamTrack.enabled = enabled;
        }
      },
      undefined,
      () => console.log('remote audio ended'),
    );
}

// Wires up RoomConnection events with actions in both directions:
// * Actions like muteAudio trigger changes to the track settings
// * Changes in participant and track status trigger actions
export function connectRoomEventsToActions(
  store$: StoreSubject<RootState>,
  connection: RoomConnection,
) {
  setupConnectionChangesFromActions(store$, connection);
  setupActionsFromConnectionEvents(store$, connection);
  setupTrackAnalytics(connection);
}
