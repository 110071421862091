/* eslint-disable */
/* prettier-ignore-start */
// [GENERATED FILE; DO NOT EDIT BY HAND]

import React from "react"

var element = React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "currentColor",
  "aria-hidden": "true"
}, React.createElement("path", {
  fill: "none",
  d: "M-827.81-411.86h1677v833h-1677z"
}), React.createElement("path", {
  fill: "none",
  d: "M-827.81-411.86h1677v833h-1677z"
}), React.createElement("path", {
  d: "M18.89 7.26a.53.53 0 00-.14-.26.5.5 0 00-.75 0l-1.08 1.09-.06-.09a1.79 1.79 0 00-.23-.25 9.43 9.43 0 00-16.1 6.3.87.87 0 00.84.91.88.88 0 00.88-.84A7.68 7.68 0 0115.4 9l.14.16.12.13-1.13 1.13a.44.44 0 00-.14.28.51.51 0 00.42.57l4.06.58H19a.5.5 0 00.43-.57z"
}), React.createElement("path", {
  fill: "none",
  d: "M-827.81-453.47h1677v833h-1677z"
}), React.createElement("path", {
  fill: "none",
  d: "M-827.81-453.47h1677v833h-1677z"
}));

var Svg = React.forwardRef(function (props, ref) {
  var next = { ref: ref };
  for (var key in props) {
    if (props.hasOwnProperty(key)) {
      next[key] = props[key];
    }
  }
  return React.cloneElement(element, next);
});
Svg.displayName = "Tilt";
Svg.element = element;
export default Svg;
