import { FarceAction } from 'farce';
import { FoundState } from 'found';
import found from 'found/lib/foundReducer';
import { Reducer as ReduxReducer, combineReducers } from 'redux';

import { ActionType } from 'typesafe-actions';
import * as ImagingAction from '../actions/imaging';
import * as InstructionAction from '../actions/instruction';
import * as MediaAction from '../actions/media';
import * as SessionAction from '../actions/session';
import capture, { CaptureState } from './capture';
import commands, { CommandState } from './commands';
import imaging, { ImagingState } from './imaging';
import instruction, { InstructionState } from './instruction';
import media, { MediaState } from './media';
import pose, { PoseState } from './pose';
import session, { CallState } from './session';

export interface RootState {
  found: FoundState;
  instruction: InstructionState;
  commands: CommandState;
  session: CallState;
  pose: PoseState;
  capture: CaptureState;
  media: MediaState;
  imaging: ImagingState;
}

export type RootAction = ActionType<
  | typeof SessionAction
  | typeof InstructionAction
  | typeof MediaAction
  | typeof ImagingAction
  | FarceAction
>;

export type RootReducer = ReduxReducer<RootState, RootAction>;

export default combineReducers<RootState, RootAction>({
  commands,
  pose,
  media,
  capture,
  session,
  found,
  instruction,
  imaging,
});
