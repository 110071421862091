import {
  BatteryState,
  CameraMode,
  HeliosEvent,
  ImagingMode,
  ProbeState,
  TemperatureState,
} from '@bfly/telemed-interchange';

import { getType as t } from 'typesafe-actions';
import {
  batteryStateChanged,
  depthChangeRequested,
  depthChanged,
  freezeChanged,
  gainChangeRequested,
  gainChanged,
  modeChangeRequestedToHelios,
  modeChanged,
  presetChangeRequested,
  presetChanged,
  probeStateChanged,
  temperatureStateChanged,
} from '../actions/imaging';
import { currentSettingsUpdated } from '../actions/session';
import { RootAction } from './index';
import { combineFieldReducers } from './utils';

export type ImagingState = {
  settings: {
    availableModes: ImagingMode[];
    availablePresets: string[];
  };
  gain: HeliosEvent.IBoundedValue;
  depth: HeliosEvent.IBoundedValue;

  probeState: ProbeState;
  temperatureState: TemperatureState;
  batteryState: BatteryState;

  mode: ImagingMode;
  preset: string | null;
  frozen: boolean;

  // Whether imaging has even been running since the session began. It's
  // confusing if the session starts while frozen and the probe feed is black.
  hasBeenUnfrozen: boolean;

  fieldOfView: number | null;
  camera: CameraMode;
};

const initialState: ImagingState = {
  settings: {
    availableModes: [],
    availablePresets: [],
  },
  mode: ImagingMode.UNKNOWN_MODE,
  preset: null,
  gain: {},
  depth: {},
  probeState: ProbeState.UNKNOWN_PROBE,
  temperatureState: TemperatureState.UNKNOWN_TEMP,
  batteryState: BatteryState.UNKNOWN_BATTERY,
  camera: CameraMode.UNKNOWN_CAMERA_MODE,
  frozen: true,
  hasBeenUnfrozen: false,
  fieldOfView: null,
};

const fieldReducer = combineFieldReducers<ImagingState, RootAction>({
  hasBeenUnfrozen: (state = initialState.hasBeenUnfrozen, action) => {
    switch (action.type) {
      case t(freezeChanged):
        return state || !action.payload;
      default:
        return state;
    }
  },
  gain: (state = initialState.gain, action) => {
    switch (action.type) {
      case t(gainChangeRequested):
      case t(gainChanged):
        return { ...state, value: action.payload.value };
      default:
        return state;
    }
  },
  depth: (state = initialState.depth, action) => {
    switch (action.type) {
      case t(depthChangeRequested):
      case t(depthChanged):
        return { ...state, value: action.payload.value };
      default:
        return state;
    }
  },
  mode: (state = initialState.mode, action) => {
    switch (action.type) {
      case t(modeChangeRequestedToHelios):
      case t(modeChanged):
        return action.payload;
      default:
        return state;
    }
  },
  preset: (state = initialState.preset, action) => {
    switch (action.type) {
      case t(presetChangeRequested):
      case t(presetChanged):
        return action.payload;
      default:
        return state;
    }
  },
  frozen: (state = false, action) => {
    switch (action.type) {
      case t(freezeChanged):
        return action.payload;
      default:
        return state;
    }
  },
  probeState: (state = initialState.probeState, action) => {
    switch (action.type) {
      case t(probeStateChanged):
        return action.payload;
      default:
        return state;
    }
  },
  temperatureState: (state = initialState.temperatureState, action) => {
    switch (action.type) {
      case t(temperatureStateChanged):
        return action.payload;
      default:
        return state;
    }
  },
  batteryState: (state = initialState.batteryState, action) => {
    switch (action.type) {
      case t(batteryStateChanged):
        return action.payload;
      default:
        return state;
    }
  },
});

export default (state = initialState, action: RootAction): ImagingState => {
  const nextState = fieldReducer(state, action);

  switch (action.type) {
    case t(currentSettingsUpdated): {
      const {
        mode,
        preset,
        frozen,
        fieldOfView,
        camera,
        gain,
        depth,
        probeState,
        temperatureState,
        batteryState,
        availableModes,
        availablePresets,
      } = action.payload!;

      return {
        ...nextState,
        hasBeenUnfrozen: nextState.hasBeenUnfrozen || !frozen,
        mode,
        preset,
        frozen,
        fieldOfView,
        camera,
        probeState,
        temperatureState,
        batteryState,
        gain: gain || state.gain,
        depth: depth || state.depth,
        settings: {
          availableModes,
          availablePresets,
        },
      };
    }
    default:
      return nextState;
  }
};
