/* eslint-disable no-param-reassign */
import {
  AnimationAction,
  AnimationMixer,
  AxesHelper,
  Box3,
  Color,
  DirectionalLightHelper,
  GridHelper,
  Material,
  Matrix4,
  Mesh,
  Object3D,
  Vector2,
  Vector3,
  Math as _Math,
} from 'three';

import { TranslateCommand, VisualCommandType } from '../Instruction';
import * as Animations from './animations';
import loadModel from './loader';
import { ArState } from './MainScene';
import Renderer from './Renderer';
import {
  SceneObjectName,
  createBaseMaterial,
  createInstructionMaterial,
  makeLights,
  setInstructionState,
  tentativeCommandOpacity,
} from './sceneObjects';

export type ScreenCoordinate = [number, number];

export interface RendererOptions {
  container: HTMLDivElement;
  probeOcclusion?: boolean;
  debug?:
    | true
    | {
        showProbe?: boolean;
        gridHelper?: boolean;
        axesHelper?: boolean;
        lightHelper?: boolean;
        cameraControls?: boolean;
      };
}

const xAxis = new Vector3(1, 0, 0);
const yAxis = new Vector3(0, 1, 0);

// Scaling parameters to make arrows appear big on screen even when far away
const nearCm = 35;
const nearScale = 1;

const farCm = 150;
const farScale = 3;

const scaleSlope = (farScale - nearScale) / (farCm - nearCm);
const scaleIntercept = nearScale - scaleSlope * nearCm;

function getScaleToOffsetDepth(depth: number) {
  let scale = scaleSlope * depth + scaleIntercept;
  scale = Math.min(scale, farScale);
  return Math.max(scale, nearScale);
}

const getBoundingBox = (object: Object3D) => new Box3().setFromObject(object);

type HelperMap = {
  [key: string]: AxesHelper | GridHelper | DirectionalLightHelper;
};

export default class ArRenderer extends Renderer<ArState> {
  private probeBoundingBox: Box3 | null = null;

  private readonly modelNode = new Object3D();

  private readonly probeContainer = new Object3D();

  private readonly compassContainer = new Object3D();

  private indicatorRing = new Mesh();

  private readonly helpers: HelperMap = {};

  private readonly commandToObjectMap = new Map<VisualCommandType, Mesh>();

  private readonly mixer = new AnimationMixer(this.scene);

  private moveAction?: AnimationAction;

  private tiltTentativeAction?: AnimationAction;

  private tiltActiveAction?: AnimationAction;

  private rotateTentativeAction?: AnimationAction;

  private rotateActiveAction?: AnimationAction;

  constructor(readonly container: HTMLElement) {
    super(container);

    this.scene.add(this.modelNode);
    this.scene.add(...makeLights());

    this.buildTiltArrows();
    this.buildRotateArrows();
    this.buildMoveArrows();
    this.buildProbeAndCompass();
  }

  buildProbeAndCompass() {
    loadModel().then(objectMap => {
      const { probeContainer, compassContainer } = this;
      const probe = objectMap[SceneObjectName.Probe].clone() as Mesh;
      probe.renderOrder = 1;

      const boundingBox = getBoundingBox(probe);
      probe.material = createBaseMaterial();

      this.probeBoundingBox = boundingBox;

      this.indicatorRing = objectMap.CompassRing.clone() as Mesh;
      this.indicatorRing.renderOrder = 2;
      this.indicatorRing.material = createBaseMaterial();
      this.indicatorRing.material.opacity = 0.5;

      const indicatorBall = objectMap.CompassBall.clone() as Mesh;

      indicatorBall.renderOrder = 2;
      indicatorBall.material = createInstructionMaterial(true);

      compassContainer.visible = true;
      compassContainer.add(this.indicatorRing, indicatorBall);

      probeContainer.add(probe, compassContainer);
      this.modelNode.add(this.probeContainer);
    });
  }

  buildMoveArrows() {
    loadModel().then(objectMap => {
      const moveContainer = objectMap.LateralContainer.clone();
      const arrow = moveContainer.children[0] as Mesh;

      arrow.renderOrder = 2;
      arrow.visible = true;

      arrow.material = createInstructionMaterial();

      this.moveAction = Animations.move(arrow, 1, this.mixer);

      this.commandToObjectMap.set(VisualCommandType.TRANSLATE, arrow);
      moveContainer.add(arrow);
      this.modelNode.add(moveContainer);
    });
  }

  buildTiltArrows() {
    loadModel().then(objectMap => {
      const tiltContainer = objectMap.TiltContainer.clone();
      const tiltArrow = tiltContainer.children[0] as Mesh;
      tiltArrow.name = 'TiltArrow';
      tiltArrow.visible = true;
      tiltArrow.renderOrder = 3;
      tiltArrow.material = createInstructionMaterial();

      this.tiltTentativeAction = Animations.tilt(tiltArrow, this.mixer, true);
      this.tiltActiveAction = Animations.tilt(tiltArrow, this.mixer, false);

      this.commandToObjectMap.set(VisualCommandType.TILT, tiltArrow);

      this.modelNode.add(tiltContainer);
    });
  }

  buildRotateArrows() {
    loadModel().then(objectMap => {
      const rotateContainer = objectMap.TwistContainer.clone();
      const rotateArrow = rotateContainer.children[0] as Mesh;
      rotateArrow.name = 'RotateArrow';

      rotateArrow.visible = true;
      rotateArrow.renderOrder = 2;
      rotateArrow.material = createInstructionMaterial();

      this.rotateTentativeAction = Animations.twist(
        rotateArrow,
        this.mixer,
        true,
      );
      this.rotateActiveAction = Animations.twist(
        rotateArrow,
        this.mixer,
        false,
      );

      this.commandToObjectMap.set(
        VisualCommandType.ROTATE_CLOCKWISE,
        rotateArrow,
      );
      this.commandToObjectMap.set(
        VisualCommandType.ROTATE_COUNTERCLOCKWISE,
        rotateArrow,
      );
      rotateContainer.add(rotateArrow);
      this.modelNode.add(rotateContainer);
    });
  }

  updateProbe({ debug, probeOcclusion }: ArState) {
    const probe = this.probeContainer.children[0] as Mesh;
    if (!probe) return;

    // First reset the position, then update it if there's a command.
    this.probeContainer.setRotationFromMatrix(new Matrix4());

    const visible = debug.showProbe || probeOcclusion;
    probe.visible = visible;

    if (!debug.showProbe) {
      (probe.material as Material).colorWrite = !probeOcclusion;
    }
  }

  updateHelpers({ debug }: ArState) {
    if (debug.axesHelper && !this.helpers.axesHelper) {
      this.helpers.axesHelper = new AxesHelper(10);
      this.helpers.axesHelper.position.x = -10;
      this.helpers.axesHelper.position.y = 10;
      this.scene.add(this.helpers.axesHelper);
    }
    if (debug.gridHelper && !this.helpers.gridHelper) {
      this.scene.add(
        (this.helpers.gridHelper = new GridHelper(
          100,
          10,
          new Color(0xff0000),
          new Color(0xffffff),
        )),
      );
    }
    if (this.helpers.axesHelper)
      this.helpers.axesHelper.visible = debug.axesHelper;
    if (this.helpers.gridHelper)
      this.helpers.gridHelper.visible = debug.gridHelper;
  }

  updateMoveArrow(command: TranslateCommand, isTentative: boolean) {
    const arrow = this.commandToObjectMap.get(VisualCommandType.TRANSLATE)!;

    if (isTentative) {
      this.runAnimation(null);
      (arrow.material as Material).opacity = tentativeCommandOpacity;
    } else {
      this.runAnimation(this.moveAction!);
    }

    arrow.parent!.setRotationFromAxisAngle(yAxis, -command.angle);
  }

  runAnimation(action: AnimationAction | null) {
    const actions = [
      this.rotateActiveAction,
      this.rotateTentativeAction,
      this.moveAction,
      this.tiltActiveAction,
      this.tiltTentativeAction,
    ];

    actions.forEach(a => {
      if (a !== action) {
        a!.stop();
      }
    });

    if (action && !action.isRunning()) {
      action.play();
    }
  }

  render(state: ArState, timeDeltaSeconds: DOMHighResTimeStamp) {
    this.updateHelpers(state);

    if (!this.probeBoundingBox) return;

    this.updateProbe(state);
    this.modelNode.setRotationFromMatrix(state.probeRotation);

    for (const obj of this.commandToObjectMap.values()) {
      obj.visible = false;
    }

    this.modelNode.visible = !state.poseStale && !state.imagingFrozen;

    if (state.activeCommand || state.tentativeCommand) {
      const command = state.activeCommand
        ? state.activeCommand!
        : state.tentativeCommand!;
      const obj = this.commandToObjectMap.get(command.type)!;

      // All arrows have just a single node that gets rotated based on the
      // direction of the active command.
      switch (command.type) {
        case VisualCommandType.TRANSLATE:
          this.updateMoveArrow(command, state.commandIsTentative);
          break;
        case VisualCommandType.TILT:
          obj.parent!.setRotationFromAxisAngle(yAxis, -command.angle);
          this.runAnimation(
            state.commandIsTentative
              ? this.tiltTentativeAction!
              : this.tiltActiveAction!,
          );
          break;
        case VisualCommandType.ROTATE_CLOCKWISE:
          obj.parent!.setRotationFromAxisAngle(xAxis, 0);
          this.runAnimation(
            state.commandIsTentative
              ? this.rotateTentativeAction!
              : this.rotateActiveAction!,
          );
          break;
        case VisualCommandType.ROTATE_COUNTERCLOCKWISE:
          obj.parent!.setRotationFromAxisAngle(xAxis, Math.PI);
          this.runAnimation(
            state.commandIsTentative
              ? this.rotateTentativeAction!
              : this.rotateActiveAction!,
          );
          break;
        default:
          // HOLD_POSITION doesn't exist in new arrow style
          break;
      }

      const scale = getScaleToOffsetDepth(state.probeTranslation.z);
      obj.parent!.scale.set(scale, scale, scale);

      // TODO: Add a material for the checkmark, so it can have a gray preview.
      // Right now it always shows as green.
      if (obj.material) {
        setInstructionState(obj, !state.commandIsTentative);
      }
      obj.visible = true;
    }

    const { width, height } = state.dimensions;

    const size = this.renderer.getSize(new Vector2());
    if (size.width !== width || size.height !== height) {
      this.renderer.setSize(width, height);
    }

    this.mixer.update(timeDeltaSeconds);

    this.renderer.render(this.scene, state.camera);
  }
}
