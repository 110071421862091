/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '@4c/layout';
import { DEG2RAD, RAD2DEG, Radians } from '@bfly/utils/math';

import {
  instructionClicked,
  tentativeInstructionCanceled,
  tentativeInstructionShown,
} from '../../actions/instruction';
import {
  TranslateCommand,
  VisualCommandType,
  normalizeRadians,
} from '../../Instruction';
import { RootState } from '../../store';
import ArrowHeadMarker from './ArrowHeadMarker';
import HelpText from './HelpText';
import InstructionPlane, {
  CENTER_X,
  CENTER_Y,
  CIRCLE_R,
} from './InstructionPlane';
import * as Svg from './SvgUtils';
import useThrottledPointerMove from './useThrottledPointerMove';

/**
 * Returns rotation in radians counterclockwise from the right
 */
function rotationFromPoint(point: [number, number], center: [number, number]) {
  const dx = point[0] - center[0];
  const dy = point[1] - center[1];

  const r = Math.atan2(-dy, dx);

  return normalizeRadians(r);
}

const styles = require('./Move-styles.module.scss');

const DEFAULT_ARROW_DEGREES_FROM_DOT = 0;
const INNER_R = 80;
const lineOffset = CIRCLE_R - 100;

interface Props {
  indicatorDegrees: number | null;
}

function createTranslateCommand(angle: Radians, offsetAngle: Radians) {
  const translationAngle = angle - offsetAngle;

  const command: TranslateCommand = {
    type: VisualCommandType.TRANSLATE,
    angle: translationAngle,
  };
  return command;
}

function Move(props: Props) {
  const indicatorDegrees = props.indicatorDegrees || 0;
  const dispatch = useDispatch();

  const activeCommand = useSelector(
    (s: RootState) => s.commands.activeCommand,
  );
  const tentativeCommand = useSelector(
    (s: RootState) => s.commands.tentativeCommand,
  );
  const isActiveMove =
    !!activeCommand && activeCommand.type === VisualCommandType.TRANSLATE;

  const [hoverDegrees, setHoverDegrees] = useState<number | null>(null);

  const [isHovering, setIsHovering] = useState(false);

  let arrowRenderAngle = 0;
  if (isHovering) {
    // hoverDegrees should only be null in the time after pointerEnter before the first pointerMove
    arrowRenderAngle = hoverDegrees ?? indicatorDegrees;
  } else if (activeCommand?.type === VisualCommandType.TRANSLATE) {
    arrowRenderAngle = activeCommand.angle * RAD2DEG + indicatorDegrees;
  } else {
    arrowRenderAngle = DEFAULT_ARROW_DEGREES_FROM_DOT + indicatorDegrees;
  }
  // Invert the angle because SVG rotate() goes clockwise
  arrowRenderAngle *= -1;

  useEffect(() => {
    if (!activeCommand) {
      setHoverDegrees(null);
    }
  }, [activeCommand]);

  const [handlePointerMove, cancel] = useThrottledPointerMove(
    (e: React.PointerEvent<SVGElement>) => e.isPrimary,
    e => {
      const localPoint = Svg.getLocalPointFromEvent(e);
      const rotation = rotationFromPoint(localPoint, [CENTER_X, CENTER_Y]);

      const degrees = rotation * RAD2DEG;
      setHoverDegrees(degrees);

      const command = createTranslateCommand(
        rotation,
        indicatorDegrees * DEG2RAD,
      );
      dispatch(tentativeInstructionShown(command));
    },
  );

  function handlePointerUp() {
    cancel();

    // For commands that are translated to probe relative ones, we need to
    // use the command as it was calculated originally since the probe may have
    // drifted and re-mapping to the probe position would produce a different
    // result than what is being previewed.
    if (tentativeCommand) {
      dispatch(instructionClicked(tentativeCommand));
    }
  }

  function handlePointerEnter() {
    setIsHovering(true);
  }

  function handlePointerLeave() {
    if (tentativeCommand && !activeCommand) {
      dispatch(tentativeInstructionCanceled());
    }

    setHoverDegrees(null);
    setIsHovering(false);
    cancel();
  }

  return (
    <Layout css={[require("./Move-CssProp1_Layout.module.scss")]} direction="column" align="center">
      <HelpText previewing={isHovering} />
      <InstructionPlane indicatorDegrees={indicatorDegrees}>
        <circle
          cx={CENTER_X}
          cy={CENTER_Y}
          r={INNER_R}
          css={[require("./Move-CssProp2_circle.module.scss"), []]}
        />
        <circle
          cx={CENTER_X}
          cy={CENTER_Y}
          r={CIRCLE_R}
          css={[require("./Move-CssProp3_circle.module.scss"), []]}
          onPointerEnter={handlePointerEnter}
          onPointerLeave={handlePointerLeave}
          onPointerUp={handlePointerUp}
          onPointerMove={handlePointerMove}
        />

        <g
          className={classNames(
            styles.container,
            isActiveMove && styles.active,
            isHovering && styles.hovering,
          )}
          transform={`rotate(${arrowRenderAngle}, ${CENTER_X}, ${CENTER_Y})`}
        >
          <defs>
            <ArrowHeadMarker
              id="move__arrow-end"
              className={styles.arrowEnd}
            />
          </defs>
          <line
            strokeWidth={14}
            x1={CENTER_X - lineOffset}
            y1={CENTER_Y}
            x2={CENTER_X + lineOffset}
            y2={CENTER_Y}
            className={styles.arrow}
            strokeLinecap="round"
            markerEnd="url(#move__arrow-end)"
            id="rotate-ccw"
          />
        </g>
      </InstructionPlane>
    </Layout>
  );
}

export default Move;
