import { Redirect } from 'found';
import makeRouteConfig from 'found/lib/makeRouteConfig';
import React from 'react';
import { graphql } from 'react-relay';

import ContactsPage from './components/ContactsPage';
import DashboardPage from './components/DashboardPage';
import GettingStartedExpertPage from './components/GettingStartedExpertPage';
import GettingStartedNovicePage from './components/GettingStartedNovicePage';
import GettingStartedPage from './components/GettingStartedPage';
import Route from './components/Route';
import SessionPage from './components/SessionPage';
import _Playground from './components/_Playground';
import _Three from './components/_Three';
import isChrome from './utils/isChrome';
import {
  LaunchDarklyContext,
  LaunchDarklyManager,
} from './utils/LaunchDarkly';

export default makeRouteConfig(
  <Route path="/">
    <Route
      allowPublic
      path="getting-started"
      Component={GettingStartedPage}
      query={graphql`
        query routeConfig_GettingStartedPageQuery {
          viewer {
            ...GettingStartedPage_viewer
          }
        }
      `}
    >
      <Redirect to="/getting-started/get-assistance" />
      <Route path="give-assistance" Component={GettingStartedExpertPage} />
      <Route path="get-assistance" Component={GettingStartedNovicePage} />
    </Route>
    <Route
      query={graphql`
        query routeConfig_LDQuery {
          viewer {
            ...LaunchDarkly_viewer @relay(mask: false)
          }
        }
      `}
      renderFetched={({ match, props }: any) => {
        const ld: LaunchDarklyManager = match.context.launchDarkly;
        ld.init(props.viewer);

        // This will get cloned with the correct children by Found.
        return <LaunchDarklyContext.Provider value={ld} />;
      }}
    >
      {!isChrome() && <Redirect from="/" to="/offline" />}
      <Route
        path="/(offline)?"
        Component={DashboardPage}
        query={graphql`
          query routeConfig_DashboardQuery {
            viewer {
              ...DashboardPage_viewer
            }
          }
        `}
      />
      <Route
        path="contacts"
        allowLoginFromHash
        Component={ContactsPage}
        query={graphql`
          query routeConfig_ContactsPageQuery {
            viewer {
              ...ContactsPage_viewer
            }
          }
        `}
      />
      <Route
        path="/session/:sessionId"
        Component={SessionPage}
        query={graphql`
          query routeConfig_SessionPageQuery($sessionId: ID!) {
            telemedSession: node(id: $sessionId) {
              ...SessionPage_telemedSession
            }
          }
        `}
      />
    </Route>
    <Route path="__playground" Component={_Playground} />
    <Route path="__three" Component={_Three} />
  </Route>,
);
