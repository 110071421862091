/* eslint-disable */
/* prettier-ignore-start */
// [GENERATED FILE; DO NOT EDIT BY HAND]

import React from "react"

var element = React.createElement("svg", {
  viewBox: "0 0 40 40",
  fill: "currentColor",
  "aria-hidden": "true"
}, React.createElement("path", {
  fill: "none",
  d: "M0 0h40v40H0z",
  className: "st0"
}), React.createElement("path", {
  fill: "none",
  d: "M0 .01h40v40H0z",
  className: "st0"
}), React.createElement("path", {
  d: "M20 .01c11.04 0 19.99 8.84 19.99 19.75v.49C39.99 31.16 31.04 40 20 40S.01 31.15.01 20.24v-.49C.01 8.85 8.96.01 20 .01z",
  fill: "var(--icon-bg-color)",
  fillRule: "evenodd",
  clipRule: "evenodd"
}), React.createElement("path", {
  fill: "var(--icon-color)",
  d: "M27.071 27.821a.744.744 0 01-.53-.22L12.399 13.459a.75.75 0 111.061-1.061L27.601 26.54a.75.75 0 01-.53 1.281zm1.666-10.792l-2.393 2.241a1 1 0 000 1.46l2.393 2.241A.75.75 0 0030 22.423v-4.846a.75.75 0 00-1.263-.548zM10 16.583v6.933a2 2 0 002 2h9.981l-10.77-10.77A2 2 0 0010 16.583zm14.167 0a2 2 0 00-2-2h-4.048l6.048 6.048v-4.048z",
  className: "st2"
}));

var Svg = React.forwardRef(function (props, ref) {
  var next = { ref: ref };
  for (var key in props) {
    if (props.hasOwnProperty(key)) {
      next[key] = props[key];
    }
  }
  return React.cloneElement(element, next);
});
Svg.displayName = "Video";
Svg.element = element;
export default Svg;
