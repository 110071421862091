/* eslint-disable */
/* prettier-ignore-start */
// [GENERATED FILE; DO NOT EDIT BY HAND]

import React from "react"

var element = React.createElement("svg", {
  viewBox: "0 0 40 40",
  fill: "currentColor",
  "aria-hidden": "true"
}, React.createElement("path", {
  fill: "none",
  d: "M0 0h40v40H0z"
}), React.createElement("path", {
  d: "M20 0c11.046 0 20 8.845 20 19.756v.488C40 31.155 31.046 40 20 40S0 31.155 0 20.244v-.488C0 8.845 8.954 0 20 0z",
  fill: "var(--icon-bg-color)",
  fillRule: "evenodd",
  clipRule: "evenodd"
}), React.createElement("path", {
  fill: "var(--icon-color)",
  d: "M27.071 27.821a.744.744 0 01-.53-.22L12.398 13.459a.75.75 0 111.061-1.061L27.602 26.54a.75.75 0 01-.531 1.281zm-10.24-7.258a3.088 3.088 0 003.088 3.088h.162c.011 0 .021-.003.031-.003l-3.281-3.281v.196zm6.338-8.038a3.088 3.088 0 00-3.088-3.088h-.162a3.088 3.088 0 00-3.088 3.088v.771l6.338 6.337v-7.108z",
  className: "st2"
}), React.createElement("path", {
  fill: "var(--icon-color)",
  d: "M20.106 25.25h-.213a4.484 4.484 0 01-4.479-4.479v-.704a.625.625 0 00-1.25 0v.704a5.735 5.735 0 005.211 5.703v1.839h-2.438a.625.625 0 000 1.25h6.125a.625.625 0 000-1.25h-2.438v-1.839a5.625 5.625 0 001.819-.495l-.967-.966a4.402 4.402 0 01-1.37.237zm5.73-4.479v-.704a.625.625 0 00-1.25 0v.704c0 .087-.021.168-.026.254l1.096 1.095c.106-.435.18-.882.18-1.349z",
  className: "st2"
}));

var Svg = React.forwardRef(function (props, ref) {
  var next = { ref: ref };
  for (var key in props) {
    if (props.hasOwnProperty(key)) {
      next[key] = props[key];
    }
  }
  return React.cloneElement(element, next);
});
Svg.displayName = "Mic";
Svg.element = element;
export default Svg;
