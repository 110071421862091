/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type VideoStatusOverlay_session = {
    readonly novice: {
        readonly name: string | null;
    } | null;
    readonly " $refType": "VideoStatusOverlay_session";
};
export type VideoStatusOverlay_session$data = VideoStatusOverlay_session;
export type VideoStatusOverlay_session$key = {
    readonly " $data"?: VideoStatusOverlay_session$data;
    readonly " $fragmentRefs": FragmentRefs<"VideoStatusOverlay_session">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "VideoStatusOverlay_session",
  "type": "TelemedSession",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "novice",
      "storageKey": null,
      "args": null,
      "concreteType": "UserProfile",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = 'baddf7f31b31a9e403957cea9fa5d81e';
export default node;
