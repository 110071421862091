import * as LDClient from 'launchdarkly-js-client-sdk';
import React, { useContext } from 'react';
import { graphql } from 'relay-runtime';

import FeatureFlags from '../FeatureFlags.d';
import { LaunchDarkly_viewer as LaunchDarklyViewer } from './__generated__/LaunchDarkly_viewer.graphql';

interface LaunchDarklyConfig {
  user: any;
  hash: string;
  state: {};
}

const _ = graphql`
  fragment LaunchDarkly_viewer on Viewer {
    memberships {
      organization {
        viewerLaunchDarklyConfig {
          user
          hash
          state
        }
      }
    }
  }
`;

const fromRelayGlobalId = (str: string) => atob(str).split(':')[1] || str;

export class LaunchDarklyManager {
  private clients: Map<string, LDClient.LDClient> = new Map();

  init(viewer: DeepNonNull<LaunchDarklyViewer>) {
    const configs = viewer.memberships.map(
      m => m.organization.viewerLaunchDarklyConfig as LaunchDarklyConfig,
    );
    const keys = this.clients.keys();

    for (const key of keys) {
      if (configs.find(c => c.user.key === key)) continue;
      this.clients.delete(key);
    }

    configs.forEach(({ hash, user, state }) => {
      this.clients.set(
        user!.key!,
        LDClient.initialize(
          window.bflyConfig.LAUNCHDARKLY_CLIENT_SIDE_ID,
          user,
          { hash, bootstrap: state },
        ),
      );
    });
  }

  getClient(orgId: string): LDClient.LDClient | undefined {
    return this.clients.get(orgId);
  }

  // Returns the value of the first org that has the telemed feature enabled.
  getValueForFirstTelemedOrg<TKey extends keyof FeatureFlags>(
    key: TKey,
    defaultValue: FeatureFlags[TKey],
  ): FeatureFlags[TKey] | null {
    for (const client of this.clients.values()) {
      if (client.variation('telemed', false)) {
        return client.variation(key, defaultValue);
      }
    }
    return defaultValue;
  }

  variation<TKey extends keyof FeatureFlags>(
    orgId: string,
    key: TKey,
    defaultValue: FeatureFlags[TKey],
  ): FeatureFlags[TKey] {
    const uuid = fromRelayGlobalId(orgId);

    const client = this.getClient(uuid);
    if (client) {
      return client.variation(key, defaultValue)!;
    }

    // In cross-org, the expert doesn't have access to the flags of the novice's org, which
    // would otherwise be the one that determines the flags for the session.
    // So we grab the values for the first org that has telemed enabled. This guarantees
    // that if a feature is rolled out to all a user's organizations that have telemed,
    // the feature is enabled in cross-org calls as well. (If you arbitrarily picked the
    // first org without checking the telemed flag, you don't get this guarantee.)
    const firstTelemedOrgValue = this.getValueForFirstTelemedOrg(
      key,
      defaultValue,
    );
    return firstTelemedOrgValue != null ? firstTelemedOrgValue : defaultValue;
  }
}

export const LaunchDarklyContext = React.createContext<LaunchDarklyManager | null>(
  null,
);

export function useLaunchDarkly(): LaunchDarklyManager | null;
export function useLaunchDarkly(assertContext: true): LaunchDarklyManager;
export function useLaunchDarkly(assertContext?: true) {
  const ctx = useContext(LaunchDarklyContext);
  if (assertContext && !ctx) {
    throw new Error('Missing Launch Darkly Context');
  }
  return ctx;
}

export function useVariation<TKey extends keyof FeatureFlags>(
  orgId: string,
  key: TKey,
  defaultValue: FeatureFlags[TKey],
): FeatureFlags[TKey] {
  const ld = useLaunchDarkly();
  if (!ld) return defaultValue;

  return ld.variation(orgId, key, defaultValue);
}
