/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Observable } from 'rxjs';
import { VideoTrack } from 'twilio-video';
import Layout from '@4c/layout';
import { ShowMarker } from '@bfly/telemed-interchange';

import { freezeChangeRequested } from '../actions/imaging';
import { RootState } from '../store';
import Contain from './Contain';
import ExamReel from './ExamReel';
import ImagingAlert from './ImagingAlert';
import ImagingControls from './ImagingControls';
import InstructionPanel from './InstructionPanel';
import MarkerOverlay from './MarkerOverlay';
import RecordingControls from './RecordingControls';
import Video from './Video';

import styles from '../styles/utils.module.scss';

interface Props {
  probeTrack$: Observable<VideoTrack | null>;
}

const localStyles = require('./StudyPanel-localStyles.module.scss');

export default function StudyPanel({ probeTrack$ }: Props) {
  const dispatch = useDispatch();

  const [examReelOpen, setExamReelOpen] = React.useState(false);

  const dimensions = useSelector((s: RootState) => s.media.probeDimensions);

  const recording = useSelector((s: RootState) => s.capture.recording);

  const enableMagicMarker = useSelector(
    (s: RootState) => s.session.heliosCapabilities.magicMarkerProbe,
  );
  const fixColor = useSelector(
    (s: RootState) => !s.session.heliosCapabilities.correctProbeFeedColor,
  );

  return (
    <InstructionPanel>
      {examReelOpen ? (
        <ExamReel
          onClose={() => {
            // hide exam reel and unfreeze
            // this is because when we show exam reel, we unmount probe video completely
            // returning from exam reel, we remount the video from scratch
            // if in frozen state, there won't be any frames
            // so probe video appears empty
            // alternative is to request a frame
            // but it seems like a decent product decision to always go from exam reel back to live exam
            dispatch(freezeChangeRequested(false));
            setExamReelOpen(false);
          }}
        />
      ) : (
        <>
          <Layout
            justify="center"
            css={[require("./StudyPanel-CssProp1_Layout.module.scss"), []]}
          >
            <Contain dimensions={dimensions}>
              {containedDimensions => (
                <>
                  <Video
                    id="probe-video"
                    track$={probeTrack$}
                    style={containedDimensions}
                    className={classNames(
                      fixColor && styles.fixColor,
                      localStyles.probeVideo,
                    )}
                  />
                  {enableMagicMarker && dimensions && (
                    <MarkerOverlay
                      surface={ShowMarker.Surface.PROBE}
                      dimensions={containedDimensions}
                    />
                  )}
                </>
              )}
            </Contain>
            <ImagingAlert />
          </Layout>
          <Layout
            direction="column"
            justify="flex-end"
            className="p-6"
            css={[require("./StudyPanel-CssProp2_Layout.module.scss")]}
          >
            {recording ? (
              <RecordingControls />
            ) : (
              <ImagingControls
                onToggleExamReel={() => {
                  setExamReelOpen(s => !s);
                }}
              />
            )}
          </Layout>
        </>
      )}
    </InstructionPanel>
  );
}
