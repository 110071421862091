/* tslint:disable */
/* eslint-disable */
/* @relayHash e54239367b884d055e3411e0b7384c41 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routeConfig_SessionPageQueryVariables = {
    sessionId: string;
};
export type routeConfig_SessionPageQueryResponse = {
    readonly telemedSession: {
        readonly " $fragmentRefs": FragmentRefs<"SessionPage_telemedSession">;
    } | null;
};
export type routeConfig_SessionPageQuery = {
    readonly response: routeConfig_SessionPageQueryResponse;
    readonly variables: routeConfig_SessionPageQueryVariables;
};



/*
query routeConfig_SessionPageQuery(
  $sessionId: ID!
) {
  telemedSession: node(id: $sessionId) {
    __typename
    ...SessionPage_telemedSession
    id
  }
}

fragment Avatar_profile on UserProfile {
  name
  avatar {
    thumbnails {
      width
      url
    }
  }
}

fragment CallControls_session on TelemedSession {
  novice {
    ...Avatar_profile
    name
    id
  }
  organization {
    name
    id
  }
  ...EndCallButton_session
}

fragment CallEndedDialog_session on TelemedSession {
  id
  noviceAllowsRecording
  expertAllowsRecording
}

fragment CameraPanel_session on TelemedSession {
  organization {
    id
  }
  ...InstructorHud_session
  ...CallControls_session
}

fragment CautionBanner_session on TelemedSession {
  organization {
    id
  }
}

fragment DebugPanel_session on TelemedSession {
  organization {
    id
  }
}

fragment EndCallButton_session on TelemedSession {
  id
}

fragment InstructorHud_session on TelemedSession {
  ...VideoStatusOverlay_session
}

fragment SessionPage_telemedSession on TelemedSession {
  id
  status
  noviceAllowsRecording
  expertAllowsRecording
  twilioRoom {
    roomName
    expertInfo {
      accessToken
    }
  }
  novice {
    id
    name
    avatar {
      thumbnails {
        width
        url
      }
    }
  }
  expertProfile {
    name
    id
  }
  organization {
    name
    id
  }
  ...DebugPanel_session
  ...CameraPanel_session
  ...CallEndedDialog_session
  ...CautionBanner_session
}

fragment VideoStatusOverlay_session on TelemedSession {
  novice {
    name
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "sessionId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "sessionId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "routeConfig_SessionPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "telemedSession",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "SessionPage_telemedSession",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routeConfig_SessionPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "telemedSession",
        "name": "node",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "TelemedSession",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "status",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "noviceAllowsRecording",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "expertAllowsRecording",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "twilioRoom",
                "storageKey": null,
                "args": null,
                "concreteType": "TwilioRoom",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "roomName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "expertInfo",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserTwilioInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "accessToken",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "novice",
                "storageKey": null,
                "args": null,
                "concreteType": "UserProfile",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "avatar",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Avatar",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "thumbnails",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Thumbnail",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "width",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "url",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "expertProfile",
                "storageKey": null,
                "args": null,
                "concreteType": "UserProfile",
                "plural": false,
                "selections": (v4/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "organization",
                "storageKey": null,
                "args": null,
                "concreteType": "Organization",
                "plural": false,
                "selections": (v4/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "routeConfig_SessionPageQuery",
    "id": null,
    "text": "query routeConfig_SessionPageQuery(\n  $sessionId: ID!\n) {\n  telemedSession: node(id: $sessionId) {\n    __typename\n    ...SessionPage_telemedSession\n    id\n  }\n}\n\nfragment Avatar_profile on UserProfile {\n  name\n  avatar {\n    thumbnails {\n      width\n      url\n    }\n  }\n}\n\nfragment CallControls_session on TelemedSession {\n  novice {\n    ...Avatar_profile\n    name\n    id\n  }\n  organization {\n    name\n    id\n  }\n  ...EndCallButton_session\n}\n\nfragment CallEndedDialog_session on TelemedSession {\n  id\n  noviceAllowsRecording\n  expertAllowsRecording\n}\n\nfragment CameraPanel_session on TelemedSession {\n  organization {\n    id\n  }\n  ...InstructorHud_session\n  ...CallControls_session\n}\n\nfragment CautionBanner_session on TelemedSession {\n  organization {\n    id\n  }\n}\n\nfragment DebugPanel_session on TelemedSession {\n  organization {\n    id\n  }\n}\n\nfragment EndCallButton_session on TelemedSession {\n  id\n}\n\nfragment InstructorHud_session on TelemedSession {\n  ...VideoStatusOverlay_session\n}\n\nfragment SessionPage_telemedSession on TelemedSession {\n  id\n  status\n  noviceAllowsRecording\n  expertAllowsRecording\n  twilioRoom {\n    roomName\n    expertInfo {\n      accessToken\n    }\n  }\n  novice {\n    id\n    name\n    avatar {\n      thumbnails {\n        width\n        url\n      }\n    }\n  }\n  expertProfile {\n    name\n    id\n  }\n  organization {\n    name\n    id\n  }\n  ...DebugPanel_session\n  ...CameraPanel_session\n  ...CallEndedDialog_session\n  ...CautionBanner_session\n}\n\nfragment VideoStatusOverlay_session on TelemedSession {\n  novice {\n    name\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '4bbed0087a268d2885a1d5b9fedd31b3';
export default node;
