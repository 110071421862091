/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ContactsPage_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"CrossOrgInviteForm_viewer" | "OrganizationMembershipList_viewer">;
    readonly " $refType": "ContactsPage_viewer";
};
export type ContactsPage_viewer$data = ContactsPage_viewer;
export type ContactsPage_viewer$key = {
    readonly " $data"?: ContactsPage_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"ContactsPage_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "ContactsPage_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "CrossOrgInviteForm_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "OrganizationMembershipList_viewer",
      "args": null
    }
  ]
};
(node as any).hash = 'e70c8ae50a208d9831c4ffa6d1ca16da';
export default node;
