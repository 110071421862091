import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Text from '@bfly/ui/Text';
import useTimeout from '@restart/hooks/useTimeout';

import { depthChangeRequested } from '../actions/imaging';
import { RootState } from '../store';
import Range from './Range';

export const getDisplayValue = (
  val: number | null | undefined,
): number | undefined => {
  return val == null ? undefined : Math.round(val * 100);
};

function DepthRange({
  disabled,
}: React.ComponentPropsWithoutRef<typeof Range>) {
  const ref = useRef<HTMLInputElement>(null);
  const timeout = useTimeout();
  const dispatch = useDispatch();
  const { lower, upper, value } = useSelector(
    (s: RootState) => s.imaging.depth,
  );
  const displayValue = getDisplayValue(value);

  return (
    <>
      <Range
        ref={ref}
        disabled={disabled}
        min={getDisplayValue(lower)}
        max={getDisplayValue(upper)}
        value={displayValue || ''}
        onChange={e => {
          const nextValue = e.currentTarget.valueAsNumber / 100;
          dispatch(
            depthChangeRequested({ value: nextValue, tentative: true }),
          );

          timeout.set(() => {
            dispatch(
              depthChangeRequested({ value: nextValue, tentative: false }),
            );
          }, 500);
        }}
      />
      <Text color="headline" variant="body-bold">
        {displayValue}
        <abbr>cm</abbr>
      </Text>
    </>
  );
}

export default DepthRange;
