/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CrossOrgConnectionPanel_viewer = {
    readonly crossOrgConnections: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly " $fragmentRefs": FragmentRefs<"CrossOrgConnectionList_crossOrgConnections">;
            } | null;
        } | null> | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"CrossOrgConnectionList_viewer" | "CrossOrgModal_viewer">;
    readonly " $refType": "CrossOrgConnectionPanel_viewer";
};
export type CrossOrgConnectionPanel_viewer$data = CrossOrgConnectionPanel_viewer;
export type CrossOrgConnectionPanel_viewer$key = {
    readonly " $data"?: CrossOrgConnectionPanel_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"CrossOrgConnectionPanel_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "CrossOrgConnectionPanel_viewer",
  "type": "Viewer",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "crossOrgConnections"
        ]
      }
    ]
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "crossOrgConnections",
      "name": "__CrossOrgConnectionPanel_crossOrgConnections_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "TelemedUserToUserPairingConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "TelemedUserToUserPairingEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "TelemedUserToUserPairing",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "CrossOrgConnectionList_crossOrgConnections",
                  "args": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "CrossOrgConnectionList_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CrossOrgModal_viewer",
      "args": null
    }
  ]
};
(node as any).hash = '61706c54d3b2ec17840e4ba77bb5fde3';
export default node;
