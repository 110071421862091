/* eslint-disable no-param-reassign */
import {
  AmbientLight,
  Box3,
  Cache,
  Color,
  DirectionalLight,
  FrontSide,
  Light,
  Material,
  Mesh,
  MeshPhysicalMaterial,
  Object3D,
  PerspectiveCamera,
  Vector3,
  Math as _Math,
} from 'three';

// Names of objects in the Collada model. This is not always the same as the
// objects that eventually make it into the scene. In the control probe,
// for example, the tilt handle is duplicated to make the rock handle.
export enum SceneObjectName {
  Probe = 'Probe',
  TwistArrow = 'TwistArrow',
  TwistContainer = 'TwistContainer',
  TiltArrow = 'TiltArrow',
  TiltContainer = 'TiltContainer',
  LateralArrow = 'LateralArrow',
  LateralContainer = 'LateralContainer',
  CheckMark = 'CheckMark',
  CompassRing = 'CompassRing',
  CompassBall = 'CompassBall',
}

export const xAxis = new Vector3(1, 0, 0);
export const yAxis = new Vector3(0, 1, 0);
export const zAxis = new Vector3(0, 0, 1);

export const getBoundingBox = (object: Object3D) =>
  new Box3().setFromObject(object);

Cache.enabled = true;

export const baseColor = new Color('#F2F2F2');
export const tentativeIndicatorColor = baseColor;
export const activeIndicatorColor = new Color('#2779FF');

export const tentativeCommandOpacity = 0.6;

interface MaterialWithColor extends Material {
  color: Color;
}

const hasColor = (m: Material): m is MaterialWithColor => {
  return (m as any).color !== undefined;
};
export const setColor = (material: Material | Material[], color: Color) => {
  if (!Array.isArray(material)) {
    if (hasColor(material)) material.color.set(color);
    return;
  }
  material.forEach(m => setColor(m, color));
};

export function createCamera(aspectRatio: number) {
  const camera = new PerspectiveCamera(
    58, // fov
    aspectRatio,
    0.1, // near
    500, // far cm
  );
  camera.name = 'Camera';
  camera.position.set(0, 0, 40);

  return camera;
}

export function createBaseMaterial() {
  return new MeshPhysicalMaterial({
    color: baseColor,
    metalness: 0,
    roughness: 0.5,
    flatShading: false,
    side: FrontSide,
    transparent: true,
  });
}

export function createInstructionMaterial(isActive?: boolean) {
  return new MeshPhysicalMaterial({
    color: isActive ? activeIndicatorColor : tentativeIndicatorColor,
    metalness: 0,
    roughness: 0.5,
    flatShading: false,
    side: FrontSide,
    transparent: true,
  });
}

export const setInstructionState = (mesh: Mesh, isActive: boolean) => {
  setColor(
    mesh.material,
    isActive ? activeIndicatorColor : tentativeIndicatorColor,
  );
};

export type SceneObjectMap = Record<SceneObjectName, Mesh>;

export function cloneMaterial(mesh: Mesh) {
  if (mesh.material) {
    mesh.material = Array.isArray(mesh.material)
      ? mesh.material.map(material => material.clone())
      : mesh.material.clone();
  }
}

export function makeLights(): Light[] {
  const ambientLight = new AmbientLight(0xffffff);
  ambientLight.position.x = 0;
  ambientLight.position.y = 100;
  ambientLight.position.z = -50;
  ambientLight.intensity = 0.4;

  // Add a bright light off to the corner and coming from the front, which
  // gives some 3-dimensionality. Then a weak light on the other side to fill
  // in the shadows.
  const spotLight = new DirectionalLight(0xd3d3d3);
  spotLight.position.x = 100;
  spotLight.position.y = 100;
  spotLight.position.z = 50;
  spotLight.intensity = 0.8;
  spotLight.castShadow = true;

  const spotLight2 = new DirectionalLight(0xd3d3d3);
  spotLight2.position.x = -100;
  spotLight2.position.y = -100;
  spotLight2.position.z = 50;
  spotLight2.intensity = 0.2;

  return [ambientLight, spotLight, spotLight2];
}
