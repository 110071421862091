import {
  BrowserProtocol,
  Actions as FarceActions,
  createBasenameMiddleware,
  createHistoryEnhancer,
  queryMiddleware,
} from 'farce';
import { Matcher } from 'found';
import createMatchEnhancer from 'found/lib/createMatchEnhancer';
import {
  Dispatch as ReduxDispatch,
  Store,
  applyMiddleware,
  compose,
  createStore,
} from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import reducer, { RootAction, RootReducer, RootState } from '../reducers';
import routeConfig from '../routeConfig';
import { createHeliosMiddleware } from '../Twilio';
import { segmentLogger } from '../utils/Analytics';
import { ROOT_PATH } from '../utils/constants';
import reactiveStoreEnhancer from './reactiveStoreEnhancer';
import StoreSubject from './StoreSubject';

export type Dispatch = ReduxDispatch<RootAction>;

export { RootAction, RootState, RootReducer };

const composeEnhancers: typeof compose =
  // eslint-disable-next-line no-underscore-dangle
  (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const heliosMiddleware = createHeliosMiddleware();

const middlewares = [
  thunk as ThunkMiddleware<RootState, RootAction>,
  heliosMiddleware,
  segmentLogger,
];

const enhancer = composeEnhancers(
  createHistoryEnhancer({
    protocol: new BrowserProtocol(),
    middlewares: [
      createBasenameMiddleware({ basename: ROOT_PATH }),
      queryMiddleware,
    ],
    useBeforeUnload: true,
  }),
  createMatchEnhancer(new Matcher(routeConfig)),
  applyMiddleware(...middlewares),
  reactiveStoreEnhancer<RootState>(),
);

export type AppStore = Store<RootState, RootAction> & {
  state$: StoreSubject<RootState>;
};

const store: AppStore = createStore(reducer, {}, enhancer) as any;

heliosMiddleware.init(store);

store.dispatch(FarceActions.init());

// export store singleton instance
export default store;
