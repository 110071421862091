/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Avatar_profile = {
    readonly name: string | null;
    readonly avatar: {
        readonly thumbnails: ReadonlyArray<{
            readonly width: number | null;
            readonly url: string | null;
        } | null> | null;
    } | null;
    readonly " $refType": "Avatar_profile";
};
export type Avatar_profile$data = Avatar_profile;
export type Avatar_profile$key = {
    readonly " $data"?: Avatar_profile$data;
    readonly " $fragmentRefs": FragmentRefs<"Avatar_profile">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "Avatar_profile",
  "type": "UserProfile",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "avatar",
      "storageKey": null,
      "args": null,
      "concreteType": "Avatar",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "thumbnails",
          "storageKey": null,
          "args": null,
          "concreteType": "Thumbnail",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "width",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "url",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
(node as any).hash = '9024ebcb30c9f3df85b363d2174bb5fc';
export default node;
