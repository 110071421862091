/* eslint-disable */
/* prettier-ignore-start */
// [GENERATED FILE; DO NOT EDIT BY HAND]

import React from "react"

var element = React.createElement("svg", {
  viewBox: "0 0 40 40",
  fill: "currentColor",
  "aria-hidden": "true"
}, React.createElement("g", {
  fill: "none",
  fillRule: "evenodd"
}, React.createElement("circle", {
  cx: "20",
  cy: "20",
  r: "20",
  fill: "var(--icon-bg-color)"
}), React.createElement("path", {
  fill: "var(--icon-color)",
  d: "M8 15.565A4.568 4.568 0 0112.57 11h14.86A4.57 4.57 0 0132 15.565v8.87A4.568 4.568 0 0127.43 29H12.57A4.57 4.57 0 018 24.435v-8.87zm11.88 8.572a4.2 4.2 0 100-8.4 4.2 4.2 0 000 8.4zm8.28-7.453c1.06 0 1.92-.848 1.92-1.895 0-1.046-.86-1.894-1.92-1.894s-1.92.848-1.92 1.894c0 1.047.86 1.895 1.92 1.895z"
})));

var Svg = React.forwardRef(function (props, ref) {
  var next = { ref: ref };
  for (var key in props) {
    if (props.hasOwnProperty(key)) {
      next[key] = props[key];
    }
  }
  return React.cloneElement(element, next);
});
Svg.displayName = "CaptureStill";
Svg.element = element;
export default Svg;
