/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CallControls_session = {
    readonly novice: {
        readonly name: string | null;
        readonly " $fragmentRefs": FragmentRefs<"Avatar_profile">;
    } | null;
    readonly organization: {
        readonly name: string | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"EndCallButton_session">;
    readonly " $refType": "CallControls_session";
};
export type CallControls_session$data = CallControls_session;
export type CallControls_session$key = {
    readonly " $data"?: CallControls_session$data;
    readonly " $fragmentRefs": FragmentRefs<"CallControls_session">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CallControls_session",
  "type": "TelemedSession",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "novice",
      "storageKey": null,
      "args": null,
      "concreteType": "UserProfile",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "FragmentSpread",
          "name": "Avatar_profile",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "organization",
      "storageKey": null,
      "args": null,
      "concreteType": "Organization",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "EndCallButton_session",
      "args": null
    }
  ]
};
})();
(node as any).hash = '0ea0dde71f8260437c6cb1fd41241b74';
export default node;
