import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  BatteryState,
  HeliosEvent,
  IChangeDepth,
  IChangeGain,
  ImagingMode,
  ProbeState,
  TemperatureState,
} from '@bfly/telemed-interchange';

import { createAction } from 'typesafe-actions';
import { RootState } from '../store';
import { createLoggedAction, withPayload } from '../utils/Analytics';
import { Capture } from '../utils/capture';

export const probeStateChanged = createLoggedAction(
  'novice/probeStateChanged',
  withPayload<ProbeState>(),
);

export const temperatureStateChanged = createLoggedAction(
  'novice/temperatureStateChanged',
  withPayload<TemperatureState>(),
);

export const batteryStateChanged = createLoggedAction(
  'novice/batteryStateChanged',
  withPayload<BatteryState>(),
);

export const presetChangeRequested = createLoggedAction(
  'expert/presetChangeRequested',
  withPayload<string>(),
);
export const modeChangeRequestedToHelios = createLoggedAction(
  'expert/modeChangeRequested',
  withPayload<ImagingMode>(),
);

export const pendingChangeTimeout = createAction(
  'expert/pendingChangeTimeout',
)();

// TODO: Fix the changeCompleted argument in the the gain/depth actions, then
// add logging on just times when it completes.
export const gainChangeRequested = createAction('expert/gainChangeRequested')<
  Required<IChangeGain>
>();

// from helios, we need to distinguish so we don't resend to IOS
export const gainChanged = createAction('novice/gainChanged')<
  Required<HeliosEvent.IGainChanged>
>();

export const depthChangeRequested = createAction(
  'expert/depthChangeRequested',
)<Required<IChangeDepth>>();

export const depthChanged = createAction('novice/depthChanged')<
  Required<HeliosEvent.IDepthChanged>
>();

export const freezeChanged = createLoggedAction(
  'novice/freezeChanged',
  withPayload<boolean>(),
);

export const freezeChangeRequested = createLoggedAction(
  'expert/freezeChangeRequested',
  withPayload<boolean>(),
);

export const examReelOpened = createLoggedAction('expert/examReelOpened');
export const examReelClosed = createLoggedAction('expert/examReelClosed');

export const presetChanged = createLoggedAction(
  'novice/presetChanged',
  withPayload<string>(),
);
export const modeChanged = createLoggedAction(
  'novice/modeChanged',
  withPayload<ImagingMode>(),
);

export const cineStartRequested = createLoggedAction(
  'expert/cineStartRequested',
);
export const cineStopRequested = createLoggedAction(
  'expert/cineStopRequested',
);
export const cineCancelRequested = createLoggedAction(
  'expert/cineCancelRequested',
);
export const stillCaptureRequested = createLoggedAction(
  'expert/stillCaptureRequested',
);

export const startedCine = createLoggedAction('expert/cineStarted');
export const stoppedCine = createLoggedAction('expert/cineStopped');

export const captureSaved = createLoggedAction(
  'expert/captureSaved',
  (_: Capture) => ({}),
);

export function modeChangedRequested(
  key: ImagingMode,
): ThunkAction<void, RootState, undefined, AnyAction> {
  return dispatch => {
    dispatch(modeChangeRequestedToHelios(key));
    setTimeout(() => {
      dispatch(pendingChangeTimeout());
    }, 2000);
  };
}
