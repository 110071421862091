/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import { Link } from 'found';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '@4c/layout';
import Button from '@bfly/ui/Button';
import Heading from '@bfly/ui/Heading';
import Section from '@bfly/ui/Section';
import Text from '@bfly/ui/Text';

import vid0 from '../assets/getting-started/expert/0-Story.mp4';
import vid1 from '../assets/getting-started/expert/1-Login.mp4';
import vid2 from '../assets/getting-started/expert/3-Set-Up-Contacts.mp4';
import vid3 from '../assets/getting-started/expert/4-Available.mp4';
import vid4 from '../assets/getting-started/expert/5-Incoming-Call.mp4';
import vid5 from '../assets/getting-started/expert/6-Image-Adjustments.mp4';
import vid6 from '../assets/getting-started/expert/7-iQ-Positioned.mp4';
import vid8a from '../assets/getting-started/expert/8a-Draw.mp4';
import vid8b from '../assets/getting-started/expert/8b-Rotate.mp4';
import vid8c from '../assets/getting-started/expert/8c-Tilt.mp4';
import vid8d from '../assets/getting-started/expert/8d-Slide.mp4';
import poster0 from '../assets/getting-started/expert/generated-posters/0-Story.jpg';
import poster1 from '../assets/getting-started/expert/generated-posters/1-Login.jpg';
import poster2 from '../assets/getting-started/expert/generated-posters/3-Set-Up-Contacts.jpg';
import poster3 from '../assets/getting-started/expert/generated-posters/4-Available.jpg';
import poster4 from '../assets/getting-started/expert/generated-posters/5-Incoming-Call.jpg';
import poster5 from '../assets/getting-started/expert/generated-posters/6-Image-Adjustments.jpg';
import poster6 from '../assets/getting-started/expert/generated-posters/7-iQ-Positioned.jpg';
import poster8a from '../assets/getting-started/expert/generated-posters/8a-Draw.jpg';
import poster8b from '../assets/getting-started/expert/generated-posters/8b-Rotate.jpg';
import poster8c from '../assets/getting-started/expert/generated-posters/8c-Tilt.jpg';
import poster8d from '../assets/getting-started/expert/generated-posters/8d-Slide.jpg';
import ExpertVideo from './GettingStartedExpertVideo';
import GettingStartedImageScroller from './GettingStartedImageScroller';
import {
  CompatibilityNote,
  Content,
  Footer,
  HRule,
  Step,
  StepHeader,
  StepSection,
} from './GettingStartedLayout';
import GettingStartedStory from './GettingStartedStory';

// eslint-disable-next-line import/no-named-as-default

function GettingStartedExpertPage() {
  return (
    <>
      <main className="pt-16">
        <Section>
          <Content>
            <Heading variant="display-2">
              <FormattedMessage
                id="gettingStarted.expert.title"
                defaultMessage="Help acquire better images remotely."
              />
            </Heading>

            <FormattedMessage
              tagName="p"
              id="gettingStarted.expert.copy1"
              defaultMessage="Your contacts can place video calls from the iQ app. Receive calls from your computer."
            />
            <CompatibilityNote className="mt-6">
              <FormattedMessage
                id="gettingStarted.expert.useChrome"
                defaultMessage="Requires the latest version of Chrome on a computer"
              />
            </CompatibilityNote>
          </Content>
          <GettingStartedStory
            src={vid0}
            poster={poster0}
            name="expert.story"
          />
        </Section>
        <HRule className="mt-16 mb-4" />
        <StepSection>
          <StepHeader>
            <Heading variant="display-2">
              <FormattedMessage
                id="gettingStarted.expert.settingUp.title"
                defaultMessage="Setting up"
              />
            </Heading>
          </StepHeader>
          <Step>
            <Heading variant="xl">
              <FormattedMessage
                id="gettingStarted.expert.settingUp.step1"
                defaultMessage="1. Log into your TeleGuidance Dashboard."
              />
            </Heading>
            <p>
              <FormattedMessage
                id="gettingStarted.expert.settingUp.step1.body"
                defaultMessage="Your Dashboard lets you wait for incoming calls, see your history, and add contacts."
              />
            </p>
          </Step>
          <ExpertVideo
            src={vid1}
            poster={poster1}
            name="expert.dashboardLogin"
          />

          <Step>
            <Heading variant="xl">
              <FormattedMessage
                id="gettingStarted.expert.settingUp.step2"
                defaultMessage="2. Set up your contacts by inviting any Butterfly user."
              />
            </Heading>
            <p>
              <FormattedMessage
                id="gettingStarted.expert.settingUp.step2.body"
                defaultMessage="In the “Your contacts” section, click on “Add a contact” and enter an email address of any Butterfly user. They will receive an invite via email."
              />
            </p>
          </Step>
          <ExpertVideo
            src={vid2}
            poster={poster2}
            name="expert.setUpContacts"
          />

          <Step>
            <Heading variant="xl">
              <FormattedMessage
                id="gettingStarted.expert.settingUp.step3"
                defaultMessage="3. Mark yourself as available to receive calls."
              />
            </Heading>
            <p>
              <FormattedMessage
                id="gettingStarted.expert.settingUp.step3.body"
                defaultMessage="Click on your status and switch to “Available for calls”."
              />
            </p>
          </Step>
          <ExpertVideo
            src={vid3}
            poster={poster3}
            name="expert.makeAvailable"
          />
        </StepSection>

        <HRule className="mt-16 mb-4" />
        <StepSection>
          <StepHeader>
            <Heading variant="display-2">
              <FormattedMessage
                id="gettingStarted.expert.takingCalls.title"
                defaultMessage="Taking calls"
              />
            </Heading>
          </StepHeader>
          <Step>
            <Heading variant="xl">
              <FormattedMessage
                id="gettingStarted.expert.takingCalls.step1"
                defaultMessage="1. Listen for incoming calls on your Dashboard."
              />
            </Heading>
            <p>
              <FormattedMessage
                id="gettingStarted.expert.takingCalls.step1.body"
                defaultMessage="Turn on your speakers. Your contacts can call you when you’re marked as available."
              />
            </p>
          </Step>
          <ExpertVideo
            src={vid4}
            poster={poster4}
            name="expert.receiveCalls"
          />

          <Step>
            <Heading variant="xl">
              <FormattedMessage
                id="gettingStarted.expert.takingCalls.step2"
                defaultMessage="2. View and adjust the ultrasound image in real time."
              />
            </Heading>
            <p>
              <FormattedMessage
                id="gettingStarted.expert.takingCalls.step2.body"
                defaultMessage="The caller’s ultrasound image and camera are live streamed to you."
              />
            </p>
            <p>
              <FormattedMessage
                id="gettingStarted.expert.takingCalls.step2.body2"
                defaultMessage="You can adjust the preset, mode, gain, depth, and capture images or cines."
              />
            </p>
          </Step>
          <ExpertVideo
            src={vid5}
            poster={poster5}
            name="expert.adjustSettings"
          />

          <Step>
            <Heading variant="xl">
              <FormattedMessage
                id="gettingStarted.expert.takingCalls.step3"
                defaultMessage="3. See how the iQ is positioned."
              />
            </Heading>
            <p>
              <FormattedMessage
                id="gettingStarted.expert.takingCalls.step3.body"
                defaultMessage="Have the caller point their iPhone’s camera at the iQ to show you its position."
              />
            </p>
            <p>
              <FormattedMessage
                id="gettingStarted.expert.takingCalls.step3.body2"
                defaultMessage="A ring will appear around the iQ tail with the blue dot corresponding to the probe marker."
              />
            </p>
          </Step>
          <ExpertVideo
            src={vid6}
            poster={poster6}
            name="expert.seeProbePosition"
          />

          <Step span="full">
            <Heading variant="xl">
              <FormattedMessage
                id="gettingStarted.expert.takingCalls.step4"
                defaultMessage="4. Help position the iQ with graphical instructions."
              />
            </Heading>
            <p>
              <FormattedMessage
                id="gettingStarted.expert.takingCalls.step4.body"
                defaultMessage="Visually illustrate how to move the iQ during your conversation:"
              />
            </p>
            <ol
              css={[require("./GettingStartedExpertPage-CssProp1_ol.module.scss"), []]}
            >
              <FormattedMessage
                tagName="li"
                id="gettingStarted.expert.takingCalls.step4.body2.a"
                defaultMessage="Select an instruction tab (Rotate, Slide, or Tilt)."
              />
              <FormattedMessage
                tagName="li"
                id="gettingStarted.expert.takingCalls.step4.body2.b"
                defaultMessage="Hover over an arrow to see a preview over the video feed."
              />
              <FormattedMessage
                tagName="li"
                id="gettingStarted.expert.takingCalls.step4.body2.c"
                defaultMessage="Click on an arrow to send."
              />
            </ol>
          </Step>
        </StepSection>
        <GettingStartedImageScroller className="mt-6">
          <Layout direction="column" align="center">
            <ExpertVideo src={vid8a} poster={poster8a} name="expert.draw" />
            <Text variant="body-bold" color="headline" className="pt-4">
              <FormattedMessage
                id="gettingStarted.expert.makingCalls.vidA"
                defaultMessage="Drawing"
              />
            </Text>
          </Layout>
          <Layout direction="column" align="center">
            <ExpertVideo src={vid8b} poster={poster8b} name="expert.rotate" />
            <Text variant="body-bold" color="headline" className="pt-4">
              <FormattedMessage
                id="gettingStarted.expert.makingCalls.vidb"
                defaultMessage="Rotating"
              />
            </Text>
          </Layout>
          <Layout direction="column" align="center">
            <ExpertVideo src={vid8c} poster={poster8c} name="expert.tilt" />
            <Text variant="body-bold" color="headline" className="pt-4">
              <FormattedMessage
                id="gettingStarted.expert.makingCalls.vidv"
                defaultMessage="Tilting"
              />
            </Text>
          </Layout>
          <Layout direction="column" align="center">
            <ExpertVideo src={vid8d} poster={poster8d} name="expert.slide" />
            <Text variant="body-bold" color="headline" className="pt-4">
              <FormattedMessage
                id="gettingStarted.expert.makingCalls.vidd"
                defaultMessage="Sliding"
              />
            </Text>
          </Layout>
        </GettingStartedImageScroller>
      </main>
      <Footer className="mt-16">
        <Text variant="xl" color="headline">
          <FormattedMessage
            id="gettingStarted.expert.footer"
            defaultMessage="Get Started."
          />
        </Text>
        <Link as={Button} to="/" theme="primary" size="large" className="mt-8">
          <FormattedMessage
            id="gettingStarted.expert.goToDashboard"
            defaultMessage="Go to Dashboard"
          />
        </Link>
      </Footer>
    </>
  );
}

export default GettingStartedExpertPage;
