/* tslint:disable */
/* eslint-disable */
/* @relayHash 6864ef2ad2b73b3ead902d5b6acdb6cb */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routeConfig_GettingStartedPageQueryVariables = {};
export type routeConfig_GettingStartedPageQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"GettingStartedPage_viewer">;
    } | null;
};
export type routeConfig_GettingStartedPageQuery = {
    readonly response: routeConfig_GettingStartedPageQueryResponse;
    readonly variables: routeConfig_GettingStartedPageQueryVariables;
};



/*
query routeConfig_GettingStartedPageQuery {
  viewer {
    ...GettingStartedPage_viewer
    id
  }
}

fragment AppPage_viewer on Viewer {
  ...NavbarDropdown_viewer
}

fragment Avatar_profile on UserProfile {
  name
  avatar {
    thumbnails {
      width
      url
    }
  }
}

fragment GettingStartedPage_viewer on Viewer {
  ...AppPage_viewer
}

fragment NavbarDropdown_viewer on Viewer {
  id
  profile {
    id
    name
    ...Avatar_profile
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "routeConfig_GettingStartedPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "GettingStartedPage_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routeConfig_GettingStartedPageQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "profile",
            "storageKey": null,
            "args": null,
            "concreteType": "UserProfile",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "avatar",
                "storageKey": null,
                "args": null,
                "concreteType": "Avatar",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "thumbnails",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Thumbnail",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "width",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "url",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "routeConfig_GettingStartedPageQuery",
    "id": null,
    "text": "query routeConfig_GettingStartedPageQuery {\n  viewer {\n    ...GettingStartedPage_viewer\n    id\n  }\n}\n\nfragment AppPage_viewer on Viewer {\n  ...NavbarDropdown_viewer\n}\n\nfragment Avatar_profile on UserProfile {\n  name\n  avatar {\n    thumbnails {\n      width\n      url\n    }\n  }\n}\n\nfragment GettingStartedPage_viewer on Viewer {\n  ...AppPage_viewer\n}\n\nfragment NavbarDropdown_viewer on Viewer {\n  id\n  profile {\n    id\n    name\n    ...Avatar_profile\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'b91a6a6e29a038875a4596eacb83ef39';
export default node;
