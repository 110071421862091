import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { freezeChangeRequested } from '../actions/imaging';
import Freeze from '../icons/Freeze';
import { RootState } from '../store';
import colors from '../utils/colors';
import IconButton from './IconButton';

interface Props {
  disabled: boolean;
}
function FreezeButton({ disabled }: Props) {
  const dispatch = useDispatch();
  const frozen = useSelector((s: RootState) => s.imaging.frozen);

  return (
    <IconButton
      disabled={disabled}
      onClick={() => dispatch(freezeChangeRequested(!frozen))}
      style={{
        '--icon-bg-color': frozen ? colors.blue : colors.white,
        '--icon-color': frozen ? colors.white : colors.blue,
      }}
    >
      <Freeze />
    </IconButton>
  );
}

export default FreezeButton;
