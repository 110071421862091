import React from 'react';

interface Props extends React.SVGProps<SVGPathElement> {
  id: string;
}

function ArrowHeadMarker({ id, ...rest }: Props) {
  return (
    <marker
      id={id}
      markerWidth="5"
      markerHeight="5"
      viewBox="0 0 16 25"
      refX="8"
      refY="12.5"
      orient="auto"
    >
      <path
        fill="context-stroke" // sadly doesn't work
        d="M14.324 10.562a2.755 2.755 0 0 1 0 3.873c-2.342 2.38-6.398 6.491-9.594 9.74a2.768 2.768 0 0 1-3.014.619 2.77 2.77 0 0 1-1.715-2.559V2.761C.001 1.643.678.631 1.716.21A2.756 2.756 0 0 1 4.73.822c3.196 3.248 7.252 7.36 9.594 9.74z"
        {...rest}
      />
    </marker>
  );
}

export default ArrowHeadMarker;
