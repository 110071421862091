/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type GettingStartedPage_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"AppPage_viewer">;
    readonly " $refType": "GettingStartedPage_viewer";
};
export type GettingStartedPage_viewer$data = GettingStartedPage_viewer;
export type GettingStartedPage_viewer$key = {
    readonly " $data"?: GettingStartedPage_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"GettingStartedPage_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "GettingStartedPage_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "AppPage_viewer",
      "args": null
    }
  ]
};
(node as any).hash = '2726b248067e31a064c660c6847120c5';
export default node;
