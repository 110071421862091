import { getType as t } from 'typesafe-actions';
import {
  setLocalAudioEnabled,
  setLocalVideoEnabled,
  setRemoteAudioEnabled,
  setRingtoneEnabled,
} from '../actions/media';
import {
  cameraDimensionsUpdated,
  currentSettingsUpdated,
  probeDimensionsUpdated,
  setProbeUnavailable,
  setShowToast,
} from '../actions/session';
import { Dimensions } from '../types';
import {
  LOCAL_AUDIO_STORAGE_KEY,
  LOCAL_VIDEO_STORAGE_KEY,
  REMOTE_AUDIO_STORAGE_KEY,
  RINGTONE_STORAGE_KEY,
} from '../utils/constants';
import { RootAction } from './index';

export type MediaState = {
  localAudioEnabled: boolean;
  remoteAudioEnabled: boolean;
  localVideoEnabled: boolean;
  ringtoneEnabled: boolean;
  showToast: boolean;
  probeUnavailable: boolean;
  cameraDimensions: Dimensions;
  probeDimensions: Dimensions;
  cameraVideoPaused: boolean;
  expertControlDisabled: boolean;
};

function getStoredMediaState(key: string) {
  const storedValue = localStorage.getItem(key);
  return storedValue == null || storedValue === 'true';
}
const emptyDimension = { width: 0, height: 0 };
const initialState = {
  localAudioEnabled: getStoredMediaState(LOCAL_AUDIO_STORAGE_KEY),
  remoteAudioEnabled: getStoredMediaState(REMOTE_AUDIO_STORAGE_KEY),
  localVideoEnabled: getStoredMediaState(LOCAL_VIDEO_STORAGE_KEY),
  ringtoneEnabled: getStoredMediaState(RINGTONE_STORAGE_KEY),
  showToast: false,
  probeUnavailable: false,
  expertControlDisabled: false,

  // First assume a square video to help initial layout.
  cameraDimensions: { width: 100, height: 100 },
  probeDimensions: emptyDimension,
  cameraVideoPaused: false,
};

export default (state: MediaState = initialState, action: RootAction) => {
  switch (action.type) {
    case t(currentSettingsUpdated): {
      const {
        cameraVideoDimension,
        probeVideoDimension,
        cameraVideoPaused,
      } = action.payload!;
      let nextState = { ...state, cameraVideoPaused };
      if (cameraVideoDimension) {
        nextState = { ...nextState, cameraDimensions: cameraVideoDimension };
      }
      if (probeVideoDimension) {
        nextState = { ...nextState, probeDimensions: probeVideoDimension };
      }

      return {
        ...nextState,
        cameraVideoPaused,
        expertControlDisabled: cameraVideoPaused || state.probeUnavailable,
      };
    }
    case t(cameraDimensionsUpdated):
      return { ...state, cameraDimensions: action.payload };
    case t(probeDimensionsUpdated):
      return { ...state, probeDimensions: action.payload };
    case t(setLocalAudioEnabled):
      return { ...state, localAudioEnabled: action.payload };
    case t(setRemoteAudioEnabled):
      return { ...state, remoteAudioEnabled: action.payload };
    case t(setLocalVideoEnabled):
      return { ...state, localVideoEnabled: action.payload };
    case t(setRingtoneEnabled):
      return { ...state, ringtoneEnabled: action.payload };
    case t(setShowToast):
      return { ...state, showToast: action.payload };
    case t(setProbeUnavailable):
      return {
        ...state,
        probeUnavailable: action.payload,
        expertControlDisabled: action.payload || state.cameraVideoPaused,
      };
    default:
      return state;
  }
};
