/* tslint:disable */
/* eslint-disable */
/* @relayHash 045f917305a69e497d440897a3403578 */

import { ConcreteRequest } from "relay-runtime";
export type AuthProvider_SessionInfoQueryVariables = {};
export type AuthProvider_SessionInfoQueryResponse = {
    readonly viewer: {
        readonly authorizationExpiration: string | null;
        readonly localId: string | null;
    } | null;
};
export type AuthProvider_SessionInfoQuery = {
    readonly response: AuthProvider_SessionInfoQueryResponse;
    readonly variables: AuthProvider_SessionInfoQueryVariables;
};



/*
query AuthProvider_SessionInfoQuery {
  viewer {
    authorizationExpiration
    localId
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "authorizationExpiration",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "localId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AuthProvider_SessionInfoQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AuthProvider_SessionInfoQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AuthProvider_SessionInfoQuery",
    "id": null,
    "text": "query AuthProvider_SessionInfoQuery {\n  viewer {\n    authorizationExpiration\n    localId\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '13c909e3108e733f9c2d7759bdd26c09';
export default node;
