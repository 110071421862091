import React from 'react';
import { FormattedMessage } from 'react-intl';
import Tooltip from '@bfly/ui/Tooltip';

interface Props {
  showTooltip: boolean;
  children: React.ReactNode;
}

function StatusTooltip({ showTooltip, children }: Props) {
  return (
    <Tooltip
      id="unsupported-browser"
      placement="bottom-start"
      enabled={showTooltip}
      tooltip={
        <FormattedMessage
          id="statusTooltip"
          defaultMessage="Switch to Chrome to take calls"
        />
      }
    >
      {children}
    </Tooltip>
  );
}

export default StatusTooltip;
