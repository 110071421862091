/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TelemedSessionStatus = "%future added value" | "%future added value" | "%future added value" | "%future added value" | "ACTIVE" | "COMPLETED" | "EXPERT_REJECTED" | "NOVICE_CANCELED" | "WAITING_FOR_EXPERT" | "%future added value";
export type SessionPage_telemedSession = {
    readonly id: string;
    readonly status: TelemedSessionStatus | null;
    readonly noviceAllowsRecording: boolean | null;
    readonly expertAllowsRecording: boolean | null;
    readonly twilioRoom: {
        readonly roomName: string | null;
        readonly expertInfo: {
            readonly accessToken: string | null;
        } | null;
    } | null;
    readonly novice: {
        readonly id: string;
        readonly name: string | null;
        readonly avatar: {
            readonly thumbnails: ReadonlyArray<{
                readonly width: number | null;
                readonly url: string | null;
            } | null> | null;
        } | null;
    } | null;
    readonly expertProfile: {
        readonly name: string | null;
    } | null;
    readonly organization: {
        readonly name: string | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"DebugPanel_session" | "CameraPanel_session" | "CallEndedDialog_session" | "CautionBanner_session">;
    readonly " $refType": "SessionPage_telemedSession";
};
export type SessionPage_telemedSession$data = SessionPage_telemedSession;
export type SessionPage_telemedSession$key = {
    readonly " $data"?: SessionPage_telemedSession$data;
    readonly " $fragmentRefs": FragmentRefs<"SessionPage_telemedSession">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "SessionPage_telemedSession",
  "type": "TelemedSession",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "noviceAllowsRecording",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "expertAllowsRecording",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "twilioRoom",
      "storageKey": null,
      "args": null,
      "concreteType": "TwilioRoom",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "roomName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "expertInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "UserTwilioInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "accessToken",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "novice",
      "storageKey": null,
      "args": null,
      "concreteType": "UserProfile",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "avatar",
          "storageKey": null,
          "args": null,
          "concreteType": "Avatar",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "thumbnails",
              "storageKey": null,
              "args": null,
              "concreteType": "Thumbnail",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "width",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "url",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "expertProfile",
      "storageKey": null,
      "args": null,
      "concreteType": "UserProfile",
      "plural": false,
      "selections": (v2/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "organization",
      "storageKey": null,
      "args": null,
      "concreteType": "Organization",
      "plural": false,
      "selections": (v2/*: any*/)
    },
    {
      "kind": "FragmentSpread",
      "name": "DebugPanel_session",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CameraPanel_session",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CallEndedDialog_session",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CautionBanner_session",
      "args": null
    }
  ]
};
})();
(node as any).hash = '33e30f06b6e784d25b8bf8a33f3353dd';
export default node;
