/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Caret from '@bfly/ui/Caret';
import Dropdown from '@bfly/ui/Dropdown';
import Text from '@bfly/ui/Text';

const styles = require('./StatusDropdown-styles.module.scss');

interface Props {
  isOnline: boolean;
  disabled?: boolean;
  handleStatus: (online: boolean) => void;
}

function StatusDropdown({ isOnline, handleStatus, disabled }: Props) {
  return (
    <Dropdown className={classNames(disabled && styles.disabled, 'pl-2')}>
      <Dropdown.Toggle id="header" theme="link">
        <Text className="ml-1" variant="xl">
          {isOnline ? (
            <div css={[require("./StatusDropdown-CssProp1_div.module.scss")]}>
              <FormattedMessage
                id="statusDropdown.online"
                defaultMessage="Available for Calls"
              />
              <Caret />
            </div>
          ) : (
            <>
              <FormattedMessage
                id="statusDropdown.offline"
                defaultMessage="Offline"
              />
              <Caret />
            </>
          )}
        </Text>
      </Dropdown.Toggle>
      <Dropdown.Menu className="mt-2" role="menu">
        {isOnline ? (
          <Dropdown.Item onSelect={() => handleStatus(false)}>
            <Text as="div" className="pb-1" variant="body-bold">
              <FormattedMessage
                id="statusDropdown.offline.header"
                defaultMessage="Offline"
              />
            </Text>
            <Text variant="sm" color="subtitle">
              <FormattedMessage
                id="statusDropdown.offline.message"
                defaultMessage="Unavailable to assist at this time."
              />
            </Text>
          </Dropdown.Item>
        ) : (
          <Dropdown.Item onSelect={() => handleStatus(true)}>
            <Text as="div" className="pb-1" variant="body-bold">
              <FormattedMessage
                id="statusDropdown.online.header"
                defaultMessage="Available for Calls"
              />
            </Text>
            <Text variant="sm" color="subtitle">
              <FormattedMessage
                id="statusDropdown.online.message"
                defaultMessage="Allow calls from your contacts."
              />
            </Text>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default StatusDropdown;
