import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { remoteAudioToggled } from '../actions/media';
import Volume from '../icons/Volume';
import { RootState } from '../store';
import IconToggleButton from './IconToggleButton';

function MuteButton() {
  const dispatch = useDispatch();
  const enabled = useSelector((s: RootState) => s.media.remoteAudioEnabled);

  return (
    <IconToggleButton
      onClick={() => dispatch(remoteAudioToggled(!enabled))}
      enabled={enabled}
      icon={<Volume />}
    />
  );
}

export default MuteButton;
