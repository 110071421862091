/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CameraPanel_session = {
    readonly organization: {
        readonly id: string;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"InstructorHud_session" | "CallControls_session">;
    readonly " $refType": "CameraPanel_session";
};
export type CameraPanel_session$data = CameraPanel_session;
export type CameraPanel_session$key = {
    readonly " $data"?: CameraPanel_session$data;
    readonly " $fragmentRefs": FragmentRefs<"CameraPanel_session">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "CameraPanel_session",
  "type": "TelemedSession",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "organization",
      "storageKey": null,
      "args": null,
      "concreteType": "Organization",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "InstructorHud_session",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CallControls_session",
      "args": null
    }
  ]
};
(node as any).hash = 'ea6ef30c2342015372445bda1d7653d1';
export default node;
