import { Dispatch, Middleware, Store } from 'redux';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, share, takeUntil } from 'rxjs/operators';
import { tag } from 'rxjs-spy/operators/tag';
import { AudioTrack, VideoTrack } from 'twilio-video';

import { getType as t } from 'typesafe-actions';
import { serverStatusUpdated, sessionJoined } from '../actions/session';
import { SessionStatus } from '../reducers/session';
import { RootAction, RootState } from '../store';
import StoreSubject from '../store/StoreSubject';
import { callEnded } from '../utils/selectors';
import * as HeliosDataChannel from './HeliosDataChannel';
import RoomConnection from './RoomConnection';
import { connectRoomEventsToActions } from './RoomConnectionHandler';
import VideoRecorder from './VideoRecorder';

export type Session = {
  probe$: Observable<VideoTrack | null>;
  camera$: Observable<VideoTrack | null>;
  myVideo$: Observable<VideoTrack | null>;
  remoteAudio$: Observable<AudioTrack | null>;
};

interface HeliosMiddleware extends Middleware<Dispatch, RootState> {
  init(store: Store<RootState>): void;
}

export interface SessionConnectionInfo {
  roomName: string;
  token: string;
  record: boolean;
}

// Creates middleware that connects Redux with the Twilio room, in three ways:
// 1) The room is created and destroyed in this middleware by intercepting the
//    joinRoom and call ending actions.
// 2) Events on the Room itself (like "participant connected") can trigger actions,
//    and actions can trigger changes there.
// 3) Many incoming actions cause messages to be sent to Helios over the data track,
//    and many incoming messages from Helios cause this to dispatch actions.
export function createHeliosMiddleware(): HeliosMiddleware {
  let connection: RoomConnection | null = null;
  let store$: StoreSubject<RootState>;

  const actions$ = new Subject<RootAction>();

  function connect(sessionInfo: SessionConnectionInfo): Session {
    if (!store$) throw new Error('Twilio middleware not enabled');

    const { roomName, token, record } = sessionInfo;

    const myVideo$ = new BehaviorSubject<VideoTrack | null>(null);
    const probe$ = new BehaviorSubject<VideoTrack | null>(null);
    const camera$ = new BehaviorSubject<VideoTrack | null>(null);
    const remoteAudio$ = new BehaviorSubject<AudioTrack | null>(null);

    // Connecting takes a while, so return immediately and fill in the tracks
    // once the room connects
    RoomConnection.join(roomName, token!, record).then(conn => {
      connection = conn;

      const callEnded$ = callEnded(store$);
      connection.localVideo$
        .pipe(takeUntil(callEnded$), tag('localVideo'))
        .subscribe(localVideo => myVideo$.next(localVideo));
      connection.probe$
        .pipe(takeUntil(callEnded$), tag('probe'))
        .subscribe(probe => probe$.next(probe));
      connection.camera$
        .pipe(takeUntil(callEnded$), tag('camera'))
        .subscribe(camera => camera$.next(camera));
      connection.remoteAudio$
        .pipe(takeUntil(callEnded$), tag('remoteAudio'))
        .subscribe(remoteAudio => remoteAudio$.next(remoteAudio));

      connectRoomEventsToActions(store$, connection);
      const probeRecorder$ = connection.probe$.pipe(
        filter(p => p != null), // After probe feed is detached, still keep the old recorder around after to give a change for the user to stop
        map(p => new VideoRecorder(p!)),
        share(),
      );
      HeliosDataChannel.init(
        store$,
        actions$,
        connection.localData$,
        connection.remoteData$,
        probeRecorder$,
      );
    });

    return { myVideo$, probe$, camera$, remoteAudio$ };
  }

  const middleware = () => {
    return (next: any) => (action: RootAction) => {
      if (connection) actions$.next(action);
      switch (action.type) {
        case t(serverStatusUpdated):
          if (
            action.payload === SessionStatus.Completed &&
            connection &&
            connection.room.state !== 'disconnected'
          ) {
            connection.room.disconnect();
          }
          return next(action);
        case t(sessionJoined):
          next(action);
          return connect(action.payload);
        default:
          return next(action);
      }
    };
  };

  middleware.init = (store: Store<RootState, RootAction>) => {
    store$ = new StoreSubject(store);
  };

  return middleware;
}
