/* eslint-disable @typescript-eslint/camelcase */
import { combineReducers } from 'redux';

import { getType } from 'typesafe-actions';
import {
  examReelClosed,
  examReelOpened,
  modeChangeRequestedToHelios,
  presetChangeRequested,
} from '../actions/imaging';
import { currentSettingsUpdated } from '../actions/session';
import { RootAction } from './index';

export type InstructionState = Readonly<{
  examReelOpen: boolean;

  pendingPresetChange: boolean;
  pendingModeChange: boolean;
}>;

// TODO: These are imaging settings, not instruction. Merge this into that reducer.
export default combineReducers<InstructionState, RootAction>({
  pendingPresetChange: (state = false, action) => {
    switch (action.type) {
      case getType(presetChangeRequested):
        return true;
      case getType(currentSettingsUpdated):
        return false;
      default:
        return state;
    }
  },
  pendingModeChange: (state = false, action) => {
    switch (action.type) {
      case getType(modeChangeRequestedToHelios):
        return true;
      case getType(currentSettingsUpdated):
        return false;
      default:
        return state;
    }
  },

  examReelOpen: (state = false, action) => {
    switch (action.type) {
      case getType(examReelOpened):
        return true;
      case getType(examReelClosed):
        return false;
      default:
        return state;
    }
  },
});
