import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { cineStartRequested, stillCaptureRequested } from '../actions/imaging';
import CaptureStill from '../icons/CaptureStill';
import RecordVideo from '../icons/RecordVideo';
import { RootState } from '../store';
import colors from '../utils/colors';
import IconButton from './IconButton';

interface Props {
  disabled: boolean;
}

function CaptureButton({ disabled }: Props) {
  const dispatch = useDispatch();
  const frozen = useSelector((s: RootState) => s.imaging.frozen);

  return (
    <IconButton
      disabled={disabled}
      onClick={() =>
        dispatch(frozen ? stillCaptureRequested() : cineStartRequested())
      }
      style={{
        '--icon-bg-color': colors.white,
        '--icon-color': frozen ? colors.blue : colors.red,
      }}
    >
      {frozen ? <CaptureStill /> : <RecordVideo />}
    </IconButton>
  );
}

export default CaptureButton;
