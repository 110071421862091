import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export function isNotNullOrUndefined<T>(
  input: null | undefined | T,
): input is T {
  return input != null;
}

export default function filterEmpty<T>() {
  return (src: Observable<null | undefined | T>) =>
    src.pipe(filter(isNotNullOrUndefined));
}
