/* eslint-disable */
/* prettier-ignore-start */
// [GENERATED FILE; DO NOT EDIT BY HAND]

import React from "react"

var element = React.createElement("svg", {
  viewBox: "0 0 40 40",
  fill: "currentColor",
  "aria-hidden": "true"
}, React.createElement("path", {
  fill: "none",
  d: "M0 0h40v40H0z",
  className: "st0"
}), React.createElement("path", {
  fill: "none",
  d: "M26.923 20.486v-7.037a.232.232 0 00-.302-.221l-7.403 2.455 4.848 4.849c.151-.094.306-.183.473-.246a2.72 2.72 0 012.384.2z",
  className: "st0"
}), React.createElement("path", {
  fill: "none",
  d: "M26.923 20.486v-7.037a.232.232 0 00-.302-.221l-7.8 2.587 4.942 4.942c.235-.194.489-.364.776-.471a2.72 2.72 0 012.384.2z",
  className: "st0"
}), React.createElement("circle", {
  cx: "20",
  cy: "20",
  r: "19.99",
  opacity: ".1",
  fill: "#fff",
  fillRule: "evenodd",
  clipRule: "evenodd"
}), React.createElement("path", {
  fill: "#fff",
  d: "M27.071 27.821a.744.744 0 01-.53-.22L12.399 13.459a.75.75 0 111.061-1.061L27.601 26.54a.75.75 0 01-.53 1.281z",
  className: "st2"
}), React.createElement("path", {
  fill: "none",
  d: "M26.923 20.486v-7.037a.232.232 0 00-.302-.221l-7.403 2.455 4.848 4.849c.151-.094.306-.183.473-.246a2.72 2.72 0 012.384.2z",
  className: "st0"
}), React.createElement("path", {
  fill: "#fff",
  d: "M15.856 24.576a2.721 2.721 0 00-2.377-.203 2.75 2.75 0 00-1.7 1.87 2.784 2.784 0 002.744 3.506c1.537-.031 2.723-1.388 2.723-2.926V20.78l-1.39-1.39v5.186zm10.765-11.348a.232.232 0 01.302.221v7.037a2.72 2.72 0 00-2.384-.2 2.723 2.723 0 00-.473.246l3.792 3.792a3.025 3.025 0 00.454-1.59V11.177a.927.927 0 00-1.208-.883l-9.982 3.294 2.095 2.095 7.404-2.455z",
  className: "st2"
}));

var Svg = React.forwardRef(function (props, ref) {
  var next = { ref: ref };
  for (var key in props) {
    if (props.hasOwnProperty(key)) {
      next[key] = props[key];
    }
  }
  return React.cloneElement(element, next);
});
Svg.displayName = "RingtoneOff";
Svg.element = element;
export default Svg;
