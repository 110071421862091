import memoize from 'memoize-one';
import { Matrix4, PerspectiveCamera, Vector3 } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Radians } from '@bfly/utils/math';

import { VisualCommand } from '../Instruction';
import { RootState } from '../store';
import { PoseRotation, PoseTranslation } from '../types';
import {
  getScreenRelativeProbePosition,
  rotationIsEqual,
  rotationMatrixFromPose,
  translationIsEqual,
} from '../utils/poseMatrix';
import { SmoothingDictionary } from '../utils/SmoothingDictionary';
import { SceneOptions, defaultOptions } from './debugOptions';
import ArRenderer from './MainRenderer';
import OldArrowsRenderer from './OldArrowsRenderer';
import RenderLoop from './RenderLoop';
import { createCamera } from './sceneObjects';

const ORIGIN = new Vector3(0, 0, 0);

const rotationCache = new SmoothingDictionary<PoseRotation>(5);
const translationCache = new SmoothingDictionary<PoseTranslation>(5);

export type StateSubset = Pick<
  RootState,
  'commands' | 'pose' | 'imaging' | 'session'
>;

export interface ArState {
  activeCommand: VisualCommand | null;
  tentativeCommand: VisualCommand | null;
  commandIsTentative: boolean;

  poseStale: boolean;
  probeRotation: Matrix4;
  probeTranslation: Vector3;
  indicatorAngle: Radians;
  camera: PerspectiveCamera;

  imagingFrozen: boolean;

  dimensions: {
    width: number;
    height: number;
  };

  probeOcclusion: boolean;
  debug: any;
}

interface ReadonlyStore {
  getState(): StateSubset;
}

export interface AugmentedRealitySceneOptions {
  container: HTMLDivElement;
  store: ReadonlyStore;
  version: number;
  sceneOptions?: SceneOptions;
  onUpdate?: (state: ArState) => void;
}

const getProcessedRotation = memoize(
  (rawRotation: PoseRotation) => {
    const rotation = rotationCache.smooth(rawRotation);
    return rotationMatrixFromPose(rotation);
  },
  ([nextRotation]: PoseRotation[], [lastRotation]: PoseRotation[]) =>
    rotationIsEqual(nextRotation, lastRotation),
);

const getProcessedTranslation = memoize(
  (rawTranslation: PoseTranslation) => {
    // console.log('memo translate');
    return translationCache.smooth(rawTranslation);
  },
  (
    [nextTranslation]: PoseTranslation[],
    [lastTranslation]: PoseTranslation[],
  ) => translationIsEqual(nextTranslation, lastTranslation),
);

function getDimensionsState(
  currentState: ArState,
  options: AugmentedRealitySceneOptions,
) {
  const { clientHeight, clientWidth } = options.container;
  const aspect = Math.round((100 * clientWidth) / clientHeight) / 100;

  if (aspect !== currentState.camera.aspect) {
    // eslint-disable-next-line no-param-reassign
    currentState.camera.aspect = aspect;
    currentState.camera.updateProjectionMatrix();
  }

  return {
    dimensions: {
      height: clientHeight,
      width: clientWidth,
    },
  };
}

function getCommandState(_: ArState, { store }: AugmentedRealitySceneOptions) {
  const { commands } = store.getState();

  return {
    activeCommand: commands.activeCommand,
    tentativeCommand: commands.tentativeCommand,
    commandIsTentative: !commands.activeCommand,
  };
}

function getPoseState(
  currentState: ArState,
  { store }: AugmentedRealitySceneOptions,
) {
  const { estimation, stale, fieldOfView } = store.getState().pose;

  const nextState: Partial<ArState> = { poseStale: stale };

  if (estimation) {
    const probeRotation = getProcessedRotation(estimation.rotation!);
    const { x, y, z } = getProcessedTranslation(estimation.translation!);

    const { camera, dimensions } = currentState;

    // When the controls are enabled we need reset the rotation and scale
    if (currentState.debug.cameraControls) {
      camera.rotation.set(0, 0, 0);
      camera.scale.set(1, 1, 1);
    }

    camera.position.set(x, y, z);

    if (fieldOfView !== camera.fov) {
      camera.fov = fieldOfView;
      camera.updateProjectionMatrix();
    }

    nextState.probeRotation = probeRotation;
    nextState.probeTranslation = new Vector3(
      estimation.translation.x,
      estimation.translation.y,
      estimation.translation.z,
    );

    if (currentState.probeRotation !== probeRotation)
      nextState.indicatorAngle = getScreenRelativeProbePosition(
        ORIGIN,
        probeRotation,
        camera,
        dimensions,
      );
  }

  return nextState;
}

function getImagingState({
  store,
}: AugmentedRealitySceneOptions): Partial<ArState> {
  return { imagingFrozen: store.getState().imaging.frozen };
}

function getSceneOptionState(
  currentState: ArState,
  { container, sceneOptions }: AugmentedRealitySceneOptions,
) {
  const opts = defaultOptions(sceneOptions || {});

  const debug: any = { ...opts.debug };
  const nextState: Partial<ArState> = {
    debug,
    probeOcclusion: opts.probeOcclusion || false,
  };

  if (opts.debug.cameraControls) {
    if (!currentState.debug.cameraControls) {
      const controls = new OrbitControls(currentState.camera, container);
      debug.cameraControls = controls;
    }

    currentState.camera.updateMatrix();

    nextState.indicatorAngle = getScreenRelativeProbePosition(
      ORIGIN,
      currentState.probeRotation,
      currentState.camera,
      currentState.dimensions,
    );
  } else if (!opts.debug.cameraControls && currentState.debug.cameraControls) {
    currentState.debug.cameraControls.dispose();
    delete debug.cameraControls;
  }

  return nextState;
}

export default class AugmentedRealityScene {
  state: ArState;

  private readonly loop: RenderLoop;

  private readonly renderer: ArRenderer | OldArrowsRenderer;

  constructor(protected readonly options: AugmentedRealitySceneOptions) {
    const { container, version } = options;
    this.state = {
      activeCommand: null,
      tentativeCommand: null,
      commandIsTentative: false,
      imagingFrozen: true,

      poseStale: true,
      probeRotation: new Matrix4(),
      probeTranslation: new Vector3(),
      indicatorAngle: 0,

      camera: createCamera(container.clientWidth / container.clientHeight),
      dimensions: container.getBoundingClientRect(),

      probeOcclusion: false,
      debug: {},
    };

    this.renderer =
      version >= 1
        ? new ArRenderer(container)
        : new OldArrowsRenderer(container);

    this.loop = new RenderLoop(dt => {
      this.update();
      this.renderer.render(this.state, dt);
    });

    this.loop.start();
  }

  setSceneOptions(sceneOptions: SceneOptions) {
    this.options.sceneOptions = sceneOptions;
  }

  update() {
    Object.assign(
      this.state,
      getCommandState(this.state, this.options),
      getDimensionsState(this.state, this.options),
      getPoseState(this.state, this.options),
      getSceneOptionState(this.state, this.options),
      getImagingState(this.options),
    );

    if (this.options.onUpdate) this.options.onUpdate(this.state);
  }

  destroy() {
    this.loop.stop();
    this.renderer.destroy();
  }
}
