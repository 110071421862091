import React, { ComponentPropsWithoutRef, ReactElement } from 'react';
import { useTheme } from '@bfly/ui/ThemeContext';

import colors from '../utils/colors';
import IconButton from './IconButton';

interface Props extends ComponentPropsWithoutRef<typeof IconButton> {
  enabled?: boolean;
  icon: ReactElement;

  bgColor?: string;
  enabledBgColor?: string;
  size?: number;
}

function IconToggleButton({ icon, enabled, ...rest }: Props) {
  const theme = useTheme();
  const enabledBgColor =
    theme === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.3)';

  return (
    <IconButton
      {...rest}
      style={{
        '--icon-bg-color': enabled ? enabledBgColor : colors.white,
        '--icon-color': enabled ? colors.white : colors.red,
      }}
    >
      {icon}
    </IconButton>
  );
}

export default IconToggleButton;
