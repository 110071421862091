
import classNames from 'classnames';
import * as React from 'react';
import { Observable } from 'rxjs';
import { VideoTrack } from 'twilio-video';

import Media from './Media';

const styles = require('./Video-styles.module.scss');

interface Props {
  id?: string;
  height?: number;
  className?: string;
  fullBleed?: boolean;
  resize?: boolean;
  style?: React.CSSProperties;
  track$: Observable<VideoTrack | null>;
}

export default function Video({ className, id, style, ...props }: Props) {
  return (
    <Media {...props}>
      {inner => (
        <video
          muted
          id={id}
          {...inner}
          style={style}
          className={classNames(className, styles.video)}
        />
      )}
    </Media>
  );
}
