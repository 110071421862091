import uniqueId from 'lodash/uniqueId';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IShowMarker } from '@bfly/telemed-interchange';

import { createAction } from 'typesafe-actions';
import { VisualCommand } from '../Instruction';
import { RootState } from '../store';
import { PoseEstimation } from '../types';
import { createLoggedAction, withPayload } from '../utils/Analytics';

export interface CommandPayload {
  command: VisualCommand;
  commandId?: string;
}
function withLoggedCommandPayload() {
  return (command: CommandPayload) => command.command;
}

export const tentativeInstructionShown = createAction(
  'expert/tentativeInstructionShown',
)<VisualCommand>();

export const instructionActivated = createLoggedAction(
  'expert/instructionActivated',
  withLoggedCommandPayload(),
);

export const instructionTimeout = createLoggedAction(
  'expert/instructionTimeout',
  withPayload<string>(),
);

export const tentativeInstructionCanceled = createLoggedAction(
  'expert/tentativeInstructionCanceled',
);

export const poseUpdated = createAction('novice/poseUpdated')<{
  stale: boolean;
  estimation: PoseEstimation | null;
}>();

export function instructionClicked(
  command: VisualCommand,
): ThunkAction<void, RootState, undefined, AnyAction> {
  return dispatch => {
    const id = uniqueId();

    dispatch(
      instructionActivated({
        command,
        commandId: id,
      }),
    );

    setTimeout(() => {
      dispatch(instructionTimeout(id));
    }, 3000);
  };
}

export const markerPointDrawn = createAction('expert/markerPointDrawn')<
  IShowMarker
>();
