/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CrossOrgModal_viewer = {
    readonly id: string;
    readonly profile: {
        readonly id: string;
    } | null;
    readonly crossOrgConnections: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly " $fragmentRefs": FragmentRefs<"CrossOrgConnectionList_crossOrgConnections">;
            } | null;
        } | null> | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"CrossOrgConnectionList_viewer" | "CrossOrgInviteForm_viewer">;
    readonly " $refType": "CrossOrgModal_viewer";
};
export type CrossOrgModal_viewer$data = CrossOrgModal_viewer;
export type CrossOrgModal_viewer$key = {
    readonly " $data"?: CrossOrgModal_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"CrossOrgModal_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CrossOrgModal_viewer",
  "type": "Viewer",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "crossOrgConnections"
        ]
      }
    ]
  },
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "profile",
      "storageKey": null,
      "args": null,
      "concreteType": "UserProfile",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "crossOrgConnections",
      "name": "__CrossOrgConnectionPanel_crossOrgConnections_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "TelemedUserToUserPairingConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "TelemedUserToUserPairingEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "TelemedUserToUserPairing",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "CrossOrgConnectionList_crossOrgConnections",
                  "args": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "CrossOrgConnectionList_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CrossOrgInviteForm_viewer",
      "args": null
    }
  ]
};
})();
(node as any).hash = 'ee01c6cd732c16cdc339cd5ea273ffdd';
export default node;
