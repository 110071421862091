import { BehaviorSubject, Observable, interval } from 'rxjs';
import { filter, flatMap, map, share, withLatestFrom } from 'rxjs/operators';
import Video, {
  AudioTrack,
  LocalAudioTrack,
  LocalDataTrack,
  LocalTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteDataTrack,
  RemoteParticipant,
  RemoteTrack,
  Room,
  Track,
  VideoTrack,
} from 'twilio-video';

import { ConnectionStats, makeConnectionStats } from './ConnectionStats';

export type TrackName = 'Probe' | 'Camera' | 'Microphone';

const localVideoTrackName = 'ExpertLocalVideo';
const screenshareTrackName = 'ExpertScreenshare';
const localAudioTrackName = 'ExpertLocalAudio';

// Most stats are cumulative so a high sampling rate isn't needed.
const statsIntervalMs = 10000;

const isAudioTrack = (track: Track): track is AudioTrack =>
  track.kind === 'audio';

const isRemoteDataTrack = (
  track: Video.RemoteTrack,
): track is RemoteDataTrack => track.kind === 'data';

const isLocalDataTrack = (track: Video.LocalTrack): track is LocalDataTrack =>
  track.kind === 'data';

export default class RoomConnection {
  name: string;

  room: Room;

  localData$ = new BehaviorSubject<LocalDataTrack | null>(null);

  remoteData$ = new BehaviorSubject<RemoteDataTrack | null>(null);

  probe$ = new BehaviorSubject<VideoTrack | null>(null);

  camera$ = new BehaviorSubject<VideoTrack | null>(null);

  remoteAudio$ = new BehaviorSubject<RemoteAudioTrack | null>(null);

  localAudio$ = new BehaviorSubject<LocalAudioTrack | null>(null);

  localVideo$ = new BehaviorSubject<LocalVideoTrack | null>(null);

  remoteParticipant$ = new BehaviorSubject<RemoteParticipant | null>(null);

  stats$: Observable<ConnectionStats>;

  setupRemoteTrack = (track: RemoteTrack) => {
    if (isRemoteDataTrack(track)) {
      this.remoteData$.next(track);
    } else if (isAudioTrack(track)) {
      this.remoteAudio$.next(track);
    } else if (track.kind === 'video' && track.name === 'Probe') {
      this.probe$.next(track);
    } else if (track.kind === 'video' && track.name === 'Camera') {
      this.camera$.next(track);
    }
  };

  teardownRemoteTrack = (track: RemoteTrack) => {
    if (isRemoteDataTrack(track)) {
      this.remoteData$.next(null);
    } else if (isAudioTrack(track)) {
      this.remoteAudio$.next(null);
    } else if (track.kind === 'video' && track.name === 'Probe') {
      this.probe$.next(null);
    } else if (track.kind === 'video' && track.name === 'Camera') {
      this.camera$.next(null);
    }
  };

  setupParticipant = (participant: RemoteParticipant) => {
    this.remoteParticipant$.next(participant);

    participant.tracks.forEach(publication => {
      if (publication.track) {
        this.setupRemoteTrack(publication.track);
      }
    });

    participant.on('trackSubscribed', track => {
      this.setupRemoteTrack(track);
    });
    participant.on('trackUnsubscribed', track => {
      this.teardownRemoteTrack(track);
    });
  };

  constructor(room: Video.Room) {
    this.room = room;

    this.name = room.name;

    room.on('disconnected', () => console.log('\n\n\nDISCONNECT FIRED\n\n\n'));

    // Handlers for remote participant
    if (room.participants.size > 0) {
      this.setupParticipant(room.participants.values().next().value);
    }
    room.on('participantConnected', this.setupParticipant);
    room.on('participantDisconnected', () => {
      this.remoteParticipant$.next(null);
      this.remoteData$.next(null);
      this.remoteAudio$.next(null);
      this.camera$.next(null);
      this.probe$.next(null);
    });

    this.localData$.next(
      Array.from(room.localParticipant.tracks.values(), p => p.track).find(
        isLocalDataTrack,
      ) ?? null,
    );

    // In a group room (i.e. when recording) the local data track is not yet
    // attached when the session begins.
    room.localParticipant.addListener('trackPublished', t => {
      if (isLocalDataTrack(t.track)) {
        this.localData$.next(t.track);
      }
    });

    this.stats$ = interval(statsIntervalMs).pipe(
      flatMap(() => room.getStats().catch(() => [])),
      filter(raw => raw.length === 1),
      map(stats => stats[0]),

      withLatestFrom(this.camera$.pipe(filter(c => c != null))),
      map(([rawStats, camera]: any[]) =>
        makeConnectionStats(rawStats, camera.sid),
      ),
      share(),
    );
  }

  static async join(
    name: string,
    token: string,
    screenshare: boolean,
  ): Promise<RoomConnection> {
    const tracks: LocalTrack[] = [new Video.LocalDataTrack()];
    if (screenshare && navigator.mediaDevices.getDisplayMedia) {
      try {
        // TODO: We'd prefer to only grab the Chrome tab. That should be
        // possible with this, but it's not as of Chrome 72:
        // const constraints = {
        //   video: {
        //     cursor: 'always',
        //     displaySurface: { exact: 'browser' },
        //   }
        // };
        const constraints = {
          video: true,
        };
        const screenStream = await navigator.mediaDevices.getDisplayMedia(
          constraints,
        );
        tracks.push(
          new Video.LocalVideoTrack(screenStream.getVideoTracks()[0], {
            logLevel: 'info',
            name: screenshareTrackName,
          }),
        );
      } catch (err) {
        // User declined screenshare.
      }
    }

    // Join with no local audio or video by default.
    const room = await Video.connect(token, {
      name,
      logLevel: 'info',
      // preferredVideoCodecs: ['H264', 'VP8'], // NOTE: this breaks B-mode completely.
      audio: false,
      video: false,
      tracks,
    });

    return new RoomConnection(room);
  }

  async addLocalAudio() {
    if (this.room.localParticipant.audioTracks.size === 0) {
      try {
        const audioTrack = await Video.createLocalAudioTrack({
          name: localAudioTrackName,
        });
        this.room.localParticipant.publishTrack(audioTrack);
        this.localAudio$.next(audioTrack);
      } catch (err) {
        // TODO: Make this error nicely show in the UI.
        window.alert(
          'Could not access your microphone. Please ensure the permission is enabled.',
        );
      }
    }
  }

  async addLocalVideo() {
    const localVideoTrackExists =
      Array.from(this.room.localParticipant.videoTracks.values()).filter(
        track => track.trackName === localVideoTrackName,
      ).length > 0;
    if (!localVideoTrackExists) {
      try {
        const videoTrack = await Video.createLocalVideoTrack({
          name: localVideoTrackName,
        });
        this.room.localParticipant.publishTrack(videoTrack);
        this.localVideo$.next(videoTrack);
      } catch (err) {
        // TODO: Make this error nicely show in the UI.
        window.alert(
          'Could not access your camera. Please ensure the permission is enabled.',
        );
      }
    }
  }

  removeLocalAudio() {
    const audioTracks = this.room.localParticipant.audioTracks.values();
    for (const trackPublication of audioTracks) {
      this.room.localParticipant.unpublishTrack(trackPublication.track);
      this.localAudio$.next(null);
    }
  }

  removeLocalVideo() {
    const videoTracks = this.room.localParticipant.videoTracks.values();
    for (const trackPublication of videoTracks) {
      trackPublication.track.mediaStreamTrack.stop();
      this.room.localParticipant.unpublishTrack(trackPublication.track);
      this.localVideo$.next(null);
    }
  }
}
