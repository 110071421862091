/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import React from 'react';

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
}

export default function BlueLinkButton({ children, onClick }: Props) {
  return (
    <button
      type="button"
      onClick={onClick}
      css={[require("./BlueLinkButton-CssProp1_button.module.scss"), []]}
    >
      {children}
    </button>
  );
}
