import React from 'react';
import { Degrees, polarToCartesian } from '@bfly/utils/math';

interface Props extends React.SVGProps<SVGPathElement> {
  cx: number;
  cy: number;
  r: number;
  startAngle: number;
  endAngle: number;
  sweep?: 0 | 1;
}

export function describeArc(
  x: number,
  y: number,
  r: number,
  startAngle: Degrees,
  endAngle: Degrees,
  sweep: 0 | 1,
) {
  const [sx, sy] = polarToCartesian([x, y], r, endAngle);
  const [ex, ey] = polarToCartesian([x, y], r, startAngle);

  const total = endAngle - startAngle;
  const largeArc = total <= 180 ? '0' : '1';
  return `M ${sx} ${sy} A ${r} ${r} 0 ${largeArc} ${sweep} ${ex} ${ey}`;
}

/**
 * Render a Path as an Arc described by a segment of the circumference of circle
 */
function Arc({
  cx,
  cy,
  r,
  startAngle,
  endAngle,
  stroke,
  sweep = 0,
  ...props
}: Props) {
  return (
    <path
      fill="none"
      stroke={stroke}
      strokeLinecap="round"
      {...props}
      d={describeArc(cx, cy, r, startAngle, endAngle, sweep)}
    />
  );
}

export default Arc;
