/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');
import React, { useState } from 'react';
import Layout from '@4c/layout';
import FormLabel from '@bfly/ui/FormLabel';

import FancyArrowControls from './FancyArrowControls';
import Panel from './Panel';

function Playground() {
  const [angle, setAngle] = useState(0);

  return (
    <Layout justify="center" align="center" css={[require("./_Playground-CssProp1_Layout.module.scss")]}>
      <Layout direction="column">
        <Panel css={[require("./_Playground-CssProp2_Panel.module.scss")]}>
          <FancyArrowControls indicatorDegrees={angle} />
        </Panel>
        <Panel className="mt-10 p-6">
          <FormLabel>Hey</FormLabel>
          <input
            type="range"
            min={0}
            max={360}
            value={angle}
            onChange={e => setAngle(e.target.valueAsNumber)}
          />
        </Panel>
      </Layout>
    </Layout>
  );
}

export default Playground;
