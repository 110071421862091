/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CallEndedDialog_session = {
    readonly id: string;
    readonly noviceAllowsRecording: boolean | null;
    readonly expertAllowsRecording: boolean | null;
    readonly " $refType": "CallEndedDialog_session";
};
export type CallEndedDialog_session$data = CallEndedDialog_session;
export type CallEndedDialog_session$key = {
    readonly " $data"?: CallEndedDialog_session$data;
    readonly " $fragmentRefs": FragmentRefs<"CallEndedDialog_session">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "CallEndedDialog_session",
  "type": "TelemedSession",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "noviceAllowsRecording",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "expertAllowsRecording",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = '555cc39009387a706ac4a96b90055f72';
export default node;
