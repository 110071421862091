/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '@4c/layout';
import Button from '@bfly/ui/Button';
import Text from '@bfly/ui/Text';

import RingtoneIcon from '../icons/Ringtone';
import DashboardDialog from './DashboardDialog';

interface Props {
  onHide: () => void;
  show: boolean;
  online: boolean;
}

function RingtoneDialog({ show, online, onHide }: Props) {
  return (
    <DashboardDialog
      show={show}
      align="center"
      justify="center"
      direction="column"
      pad
    >
      <Layout
        direction="column"
        css={[require("./RingtoneDialog-CssProp1_Layout.module.scss"), []]}
      >
        <Layout align="center" className="mb-5" direction="column">
          <RingtoneIcon className="mb-4" height="74px" width="74px" />
          <Text align="center" className="mb-1" variant="xl" color="headline">
            <FormattedMessage
              id="setAvailabilityDialog.message"
              defaultMessage="Start taking calls and view your Dashboard."
            />
          </Text>
        </Layout>
        <Layout css={[require("./RingtoneDialog-CssProp2_Layout.module.scss")]} pad={4}>
          <Button
            css={[require("./RingtoneDialog-CssProp3_Button.module.scss")]}
            size="large"
            theme="primary"
            onClick={() => {
              onHide();
            }}
          >
            {!online ? (
              <FormattedMessage
                id="setAvailabilityDialog.offline"
                defaultMessage="Go online"
              />
            ) : (
              <FormattedMessage
                id="setAvailabilityDialog.online"
                defaultMessage="Continue"
              />
            )}
          </Button>
        </Layout>
      </Layout>
    </DashboardDialog>
  );
}

export default RingtoneDialog;
