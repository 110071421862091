import { StoreEnhancer, StoreEnhancerStoreCreator } from 'redux';

import StoreSubject from './StoreSubject';

export default function reactiveStoreEnhancer<TState>(): StoreEnhancer<{
  state$: StoreSubject<TState>;
}> {
  return (createStore: StoreEnhancerStoreCreator) => (...args) => {
    const store = createStore(...args) as any;

    store.state$ = new StoreSubject(store);

    return store;
  };
}
