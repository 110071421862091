/* eslint-disable */
/* prettier-ignore-start */
// [GENERATED FILE; DO NOT EDIT BY HAND]

import React from "react"

var element = React.createElement("svg", {
  viewBox: "0 0 40 40",
  fill: "currentColor",
  "aria-hidden": "true"
}, React.createElement("path", {
  d: "M15 15.09l-.1-.43c-.12-.45-.23-.81-.44-1.43l-.21-.62a10.63 10.63 0 01-.77-3.29 4.12 4.12 0 01.55-2l.12-.25a4.3 4.3 0 00.22-.48 5.86 5.86 0 00.4-1.89 7.11 7.11 0 00-.6-3 3.89 3.89 0 00-.61-1 1.84 1.84 0 00-1.37-.7h-7a1.88 1.88 0 00-1.36.63 4 4 0 00-.61 1 7.27 7.27 0 00-.6 3 5.74 5.74 0 00.12 1v.17a5.62 5.62 0 00.26.78l.05.12.13.3.06.12a4.6 4.6 0 01.67 2.25 7.69 7.69 0 01-.24 1.57c0 .14-.07.28-.11.42l-.22.7-.53 1.54c-.14.41-.23.71-.32 1.06A14.16 14.16 0 002 18.27v1.17a14.85 14.85 0 00.36 2.22c.18.76.42 1.53.74 2.47l.29.83c.3.82.85 2.29 1 2.63A45.12 45.12 0 017 38.38v.54c.2.81.89 1.08 1.7 1.08a2.39 2.39 0 00.77-.12h.12a1.16 1.16 0 00.7-.75.36.36 0 000-.1v-.56c0-.43.11-.85.17-1.27a44.18 44.18 0 012.21-8.84l.36-1c.28-.74.82-2.23 1-2.69a27.69 27.69 0 00.97-3.01 13.64 13.64 0 000-6.57zM5.06 2.45l.05-.13c.06-.12.12-.22.17-.3h6.84l.05.09a1.79 1.79 0 01.17.34 5.56 5.56 0 01.36 1.21h-8a5.56 5.56 0 01.36-1.21zM8.79 35.4l-.09.6-.1-.61a45.91 45.91 0 00-1.85-7.11h3.9a47.1 47.1 0 00-1.86 7.12zM13 21.2a23.09 23.09 0 01-.82 2.67l-.87 2.38H6.05c-.29-.79-.73-2-.87-2.38l-.24-.67c-.25-.76-.44-1.39-.58-2a12.87 12.87 0 01-.28-1.54V18.29a12.24 12.24 0 01.43-3.12l.11-.38c.08-.31.19-.64.36-1.14l.34-1 .17-.51c.08-.27.14-.51.2-.74a9.6 9.6 0 00.3-2 6 6 0 00-.75-2.94L5 6.09l-.1-.18v-.08l-.06-.17h7.77c0 .06 0 .12-.05.18l-.1.22v.09a6.38 6.38 0 00-.9 3.24 11.66 11.66 0 00.76 3.52l.44 1.29c.12.38.2.66.28 1a11.79 11.79 0 01-.04 6zM26 8h9.1a1 1 0 011 1v21.9a1 1 0 01-1 1H26a1 1 0 01-.95-1V9A1 1 0 0126 8zm9.1-2H26a3 3 0 00-3 3v21.9a3 3 0 002.95 3h9.1a3 3 0 003-3V9a3 3 0 00-3-3zm-5.8 3h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 010-1.5z",
  fill: "currentColor"
}));

var Svg = React.forwardRef(function (props, ref) {
  var next = { ref: ref };
  for (var key in props) {
    if (props.hasOwnProperty(key)) {
      next[key] = props[key];
    }
  }
  return React.cloneElement(element, next);
});
Svg.displayName = "GetAssistance";
Svg.element = element;
export default Svg;
