
import React, { useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { createFragmentContainer, graphql } from 'react-relay';
import { createStructuredSelector } from 'reselect';
import { Observable } from 'rxjs';
import { AudioTrack, VideoTrack } from 'twilio-video';
import { ShowMarker } from '@bfly/telemed-interchange';

import MainScene, { ArState } from '../scene/MainScene';
import store, { RootState } from '../store';
import AugmentedRealityOverlay from './AugementRealityOverlay';
import AutoplayAudio from './AutoplayAudio';
import Contain from './Contain';
import MarkerOverlay from './MarkerOverlay';
import Media from './Media';
import Video from './Video';
import VideoStatusOverlay from './VideoStatusOverlay';
import { InstructorHud_session as Session } from './__generated__/InstructorHud_session.graphql';

const styles = require('./InstructorHud-styles.module.scss');

type Props = {
  session: Session;
  remoteAudio$: Observable<AudioTrack | null>;
  camera$: Observable<VideoTrack | null>;
  myVideo$: Observable<VideoTrack | null>;
  enableMagicMarker: boolean;
  onArRender: (state: ArState) => void;
};

type State = {
  indicatorAngle: number;
};

function InstructorHud({
  remoteAudio$,
  camera$,
  onArRender,
  enableMagicMarker,
  session,
}: Props) {
  const arRef = useRef();
  const augmentedRealityOverlayVersion = useSelector(
    (s: RootState) =>
      s.session.heliosCapabilities.augmentedRealityOverlayVersion,
  );

  const cameraDimensions = useSelector(
    (s: RootState) => s.media.cameraDimensions,
  );

  useEffect(() => {
    if (augmentedRealityOverlayVersion == null) return undefined;

    const scene = new MainScene({
      store,
      version: augmentedRealityOverlayVersion,
      container: arRef.current!,
      onUpdate: onArRender,
      sceneOptions: {
        debug: {
          cameraControls: false,
          showProbe: false,
          lightHelper: false,
          gridHelper: false,
          axesHelper: false,
        },
      },
    });

    return () => {
      scene.destroy();
    };
    // This really can't be re-initialized more than once per component lifecycle
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [augmentedRealityOverlayVersion]);

  return (
    <>
      <Media track$={remoteAudio$}>
        {props => <AutoplayAudio {...props} />}
      </Media>

      <Contain dimensions={cameraDimensions}>
        {containedDimensions => (
          <>
            <Video
              track$={camera$}
              style={containedDimensions}
              className={styles.cameraVideo}
            />
            <AugmentedRealityOverlay ref={arRef as any} />
            {enableMagicMarker && (
              <MarkerOverlay
                surface={ShowMarker.Surface.CAMERA}
                dimensions={containedDimensions}
              />
            )}
            <VideoStatusOverlay session={session} />
          </>
        )}
      </Contain>
    </>
  );
}

export default createFragmentContainer(
  connect(
    createStructuredSelector({
      enableMagicMarker: (s: RootState) =>
        s.session.heliosCapabilities.magicMarkerCamera,
    }),
  )(InstructorHud),
  {
    session: graphql`
      fragment InstructorHud_session on TelemedSession {
        ...VideoStatusOverlay_session
      }
    `,
  },
);
