/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TelemedSessionStatus = "%future added value" | "%future added value" | "%future added value" | "ACTIVE" | "COMPLETED" | "EXPERT_REJECTED" | "NOVICE_CANCELED" | "WAITING_FOR_EXPERT" | "%future added value";
export type CallHistoryItem_session = {
    readonly createdAt: string | null;
    readonly organization: {
        readonly name: string | null;
    } | null;
    readonly novice: {
        readonly name: string | null;
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"Avatar_profile">;
    } | null;
    readonly status: TelemedSessionStatus | null;
    readonly " $refType": "CallHistoryItem_session";
};
export type CallHistoryItem_session$data = CallHistoryItem_session;
export type CallHistoryItem_session$key = {
    readonly " $data"?: CallHistoryItem_session$data;
    readonly " $fragmentRefs": FragmentRefs<"CallHistoryItem_session">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CallHistoryItem_session",
  "type": "TelemedSession",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "organization",
      "storageKey": null,
      "args": null,
      "concreteType": "Organization",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "novice",
      "storageKey": null,
      "args": null,
      "concreteType": "UserProfile",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "Avatar_profile",
          "args": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    }
  ]
};
})();
(node as any).hash = '45fc6c13880df724ca85b98fe5e61d55';
export default node;
