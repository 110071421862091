/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createPaginationContainer, graphql } from 'react-relay';
import Layout from '@4c/layout';
import InfiniteList from '@bfly/ui/InfiniteList';
import LoadingIndicator from '@bfly/ui/LoadingIndicator';
import Text from '@bfly/ui/Text';
import useSubscription from '@bfly/ui/useSubscription';
import getNodes from '@bfly/utils/getNodes';
import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';

import Contacts from '../icons/Contacts';
import BlueLinkButton from './BlueLinkButton';
import CallHistoryItem from './CallHistoryItem';
import CrossOrgModal from './CrossOrgModal';
import TelemedHeader from './TelemedHeader';
import { CallHistoryList_Subscription as CallHistoryListSubscription } from './__generated__/CallHistoryList_Subscription.graphql';
import { CallHistoryList_viewer as Viewer } from './__generated__/CallHistoryList_viewer.graphql';

const PAGE_SIZE = 12;

interface Props {
  viewer: Viewer;
  relay: any;
}

function CallHistoryList({ viewer, relay }: Props) {
  const [showCrossOrgModal, setShowCrossOrgModal] = useState(false);
  const sessions = getNodes(viewer.sessionHistory!);
  const { hasMore } = relay;
  const loadMore = (cb: any) => relay.loadMore(PAGE_SIZE, cb);

  useSubscription<CallHistoryListSubscription>(
    graphql`
      subscription CallHistoryList_Subscription(
        $input: ExpertTelemedSessionUpdatedSubscriptionInput!
      ) {
        expertTelemedSessionUpdated(input: $input) {
          telemedSessionEdge {
            node {
              id
              ...CallHistoryItem_session
            }
          }
        }
      }
    `,
    {
      input: { expertUserId: viewer.id },
      updater: store =>
        rangeAddUpdater(store, {
          parentId: viewer.id,
          connectionFilters: {
            status: ['NOVICE_CANCELED', 'COMPLETED', 'EXPERT_REJECTED'],
          },
          connectionKey: 'CallHistoryList_sessionHistory',
          rootFieldName: 'expertTelemedSessionUpdated',
          edgeName: 'telemedSessionEdge',
          rangeBehavior: 'prepend',
        }),
    },
  );

  return (
    <div>
      <TelemedHeader>
        <FormattedMessage
          id="callHistoryList.header"
          defaultMessage="Call History"
        />
      </TelemedHeader>
      {!sessions.length ? (
        <Layout
          align="center"
          justify="center"
          direction="column"
          css={[require("./CallHistoryList-CssProp1_Layout.module.scss"), []]}
        >
          <Contacts width="64px" height="64px" />
          <Text className="py-3" color="subtitle">
            <FormattedMessage
              id="callHistoryList.emptyTitle"
              defaultMessage="Start taking calls by adding contacts."
            />
          </Text>
          <BlueLinkButton onClick={() => setShowCrossOrgModal(true)}>
            <FormattedMessage
              id="callHistoryList.empty.contacts"
              defaultMessage="Add a contact"
            />
          </BlueLinkButton>
          <CrossOrgModal
            setShowCrossOrgModal={setShowCrossOrgModal}
            show={showCrossOrgModal}
            viewer={viewer}
          />
        </Layout>
      ) : (
        <div
          css={[require("./CallHistoryList-CssProp2_div.module.scss"), []]}
        >
          <InfiniteList
            loadMore={loadMore}
            hasMore={hasMore}
            loadingIndicator={<LoadingIndicator css={[require("./CallHistoryList-CssProp3_LoadingIndicator.module.scss")]} />}
          >
            {sessions.map(session => (
              <CallHistoryItem key={session.id} session={session} />
            ))}
          </InfiniteList>
        </div>
      )}
    </div>
  );
}

export default createPaginationContainer(
  CallHistoryList,
  {
    viewer: graphql`
      fragment CallHistoryList_viewer on Viewer
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 12 }
          cursor: { type: "String" }
        ) {
        id
        sessionHistory: expertTelemedSessionConnection(
          first: $count
          after: $cursor
          status: [NOVICE_CANCELED, COMPLETED, EXPERT_REJECTED]
        ) @connection(key: "CallHistoryList_sessionHistory") {
          edges {
            node {
              id
              ...CallHistoryItem_session
            }
          }
        }
        ...CrossOrgModal_viewer
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps: ({ viewer }) => viewer.sessionHistory,
    getVariables: ({ viewer }, { count, cursor }) => ({
      viewerId: viewer.id,
      count,
      cursor,
    }),
    query: graphql`
      query CallHistoryListPaginationQuery(
        $viewerId: ID!
        $count: Int!
        $cursor: String
      ) {
        sessionHistory: node(id: $viewerId) {
          ...CallHistoryList_viewer @arguments(count: $count, cursor: $cursor)
        }
      }
    `,
  },
);
