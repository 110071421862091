/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TelemedUserToUserPairingStatus = "%future added value" | "%future added value" | "ACTIVE" | "PENDING" | "%future added value";
export type CrossOrgConnectionList_crossOrgConnections = ReadonlyArray<{
    readonly id: string;
    readonly status: TelemedUserToUserPairingStatus | null;
    readonly recipientProfile: {
        readonly id: string;
        readonly name: string | null;
    } | null;
    readonly senderProfile: {
        readonly name: string | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"CrossOrgConnectionItem_crossOrgConnection">;
    readonly " $refType": "CrossOrgConnectionList_crossOrgConnections";
}>;
export type CrossOrgConnectionList_crossOrgConnections$data = CrossOrgConnectionList_crossOrgConnections;
export type CrossOrgConnectionList_crossOrgConnections$key = ReadonlyArray<{
    readonly " $data"?: CrossOrgConnectionList_crossOrgConnections$data;
    readonly " $fragmentRefs": FragmentRefs<"CrossOrgConnectionList_crossOrgConnections">;
}>;



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CrossOrgConnectionList_crossOrgConnections",
  "type": "TelemedUserToUserPairing",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "recipientProfile",
      "storageKey": null,
      "args": null,
      "concreteType": "UserProfile",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "senderProfile",
      "storageKey": null,
      "args": null,
      "concreteType": "UserProfile",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "CrossOrgConnectionItem_crossOrgConnection",
      "args": null
    }
  ]
};
})();
(node as any).hash = 'fe0fda70f57463a7ff8f51e714d0df44';
export default node;
