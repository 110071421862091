/* eslint-disable */
/* prettier-ignore-start */
// [GENERATED FILE; DO NOT EDIT BY HAND]

import React from "react"

var element = React.createElement("svg", {
  viewBox: "0 0 16 16",
  height: "1em",
  fill: "currentColor",
  "aria-hidden": "true"
}, React.createElement("path", {
  fill: "none",
  d: "M-829.81-413.86h1677v833h-1677z"
}), React.createElement("path", {
  fill: "none",
  d: "M-829.81-413.86h1677v833h-1677z"
}), React.createElement("path", {
  fill: "none",
  d: "M-853.57-413.86h1677v833h-1677z"
}), React.createElement("path", {
  fill: "none",
  d: "M-853.57-413.86h1677v833h-1677z"
}), React.createElement("path", {
  fill: "none",
  d: "M-853.57-455.47h1677v833h-1677z"
}), React.createElement("path", {
  fill: "none",
  d: "M-853.57-455.47h1677v833h-1677z"
}), React.createElement("path", {
  fill: "#fff",
  d: "M15.26 7.28H9.57a1.78 1.78 0 00-3.14 0H.74a.72.72 0 100 1.44h5.53a1.8 1.8 0 003.46 0h5.53a.72.72 0 100-1.44zm0 4.72h-1.21a1.8 1.8 0 00-3.38 0H.74a.72.72 0 100 1.44h10a1.83 1.83 0 001.65 1.08A1.81 1.81 0 0014 13.39h1.26a.72.72 0 100-1.44zM.74 4.05H2a1.8 1.8 0 003.38 0h9.93a.72.72 0 100-1.44h-10a1.83 1.83 0 00-1.67-1.08A1.81 1.81 0 002 2.61H.74a.72.72 0 100 1.44z",
  fillRule: "evenodd"
}));

var Svg = React.forwardRef(function (props, ref) {
  var next = { ref: ref };
  for (var key in props) {
    if (props.hasOwnProperty(key)) {
      next[key] = props[key];
    }
  }
  return React.cloneElement(element, next);
});
Svg.displayName = "Tgc";
Svg.element = element;
export default Svg;
