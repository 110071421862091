/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import React from 'react';

import ExpertVideo from './GettingStartedExpertVideo';

interface Props {
  src: string;
  poster: string;
  name: string;
}

function GettingStartedStory({ src, poster, name }: Props) {
  return (
    <ExpertVideo
      src={src}
      poster={poster}
      name={name}
      controls
      className="mt-16 mx-auto"
      css={[require("./GettingStartedStory-CssProp1_ExpertVideo.module.scss"), []]}
    />
  );
}

export default GettingStartedStory;
