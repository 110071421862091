import {
  AnimationClip,
  InterpolateLinear,
  Mesh,
  NumberKeyframeTrack,
  Object3D,
  Quaternion,
  QuaternionKeyframeTrack,
  Vector3,
  VectorKeyframeTrack,
} from 'three';
import { DEG2RAD } from '@bfly/utils/math';

import { tentativeCommandOpacity } from './sceneObjects';

const xAxis = new Vector3(1, 0, 0);
const yAxis = new Vector3(0, 1, 0);
const zAxis = new Vector3(0, 0, 1);

export function twist(
  node: THREE.Object3D,
  mixer: THREE.AnimationMixer,
  isTentative: boolean,
): THREE.AnimationAction {
  const maxOpacity = isTentative ? tentativeCommandOpacity : 1;

  const start = new Quaternion()
    .copy(node.quaternion)
    .multiply(new Quaternion().setFromAxisAngle(yAxis, DEG2RAD * -45));

  const end = new Quaternion()
    .copy(node.quaternion)
    .multiply(new Quaternion().setFromAxisAngle(yAxis, DEG2RAD * 45));

  const fadeInOut = new NumberKeyframeTrack(
    '.material.opacity',
    [0, 0.2, 1, 1.5],
    [0, maxOpacity, maxOpacity, 0],
  );

  // prettier-ignore
  const kf = new QuaternionKeyframeTrack(
    '.quaternion',
    [0, 1],
    [
      start.x,  start.y,  start.z,  start.w,
      end.x,    end.y,    end.z,    end.w,
    ],
    InterpolateLinear,
  );

  const clipAction = mixer.clipAction(
    new AnimationClip(node.name, 2, [fadeInOut, kf]),
    node,
  );

  return clipAction;
}

export function tilt(
  node: Object3D,
  mixer: THREE.AnimationMixer,
  isTentative: boolean,
): THREE.AnimationAction {
  const maxOpacity = isTentative ? tentativeCommandOpacity : 1;

  const fadeInOut = new NumberKeyframeTrack(
    '.material.opacity',
    [0, 0.2, 1, 1.5],
    [0, maxOpacity, maxOpacity, 0],
  );

  const start = new Quaternion()
    .copy(node.quaternion)
    .multiply(new Quaternion().setFromAxisAngle(zAxis, DEG2RAD * -20));

  const end = new Quaternion()
    .copy(node.quaternion)
    .multiply(new Quaternion().setFromAxisAngle(zAxis, DEG2RAD * 20));

  // prettier-ignore
  const rotate = new QuaternionKeyframeTrack(
    '.quaternion',
    [0, 1],
    [
      start.x,  start.y,  start.z,  start.w,
      end.x,    end.y,    end.z,    end.w,
    ],
    InterpolateLinear,
  );

  const tiltAction = mixer.clipAction(
    new AnimationClip(node.name, 2, [fadeInOut, rotate]),
    node,
  );

  return tiltAction;
}

export function move(
  node: Mesh,
  timeDelta = 1,
  mixer: THREE.AnimationMixer,
): THREE.AnimationAction {
  // cloneMaterial(node);

  const fadeInOut = new NumberKeyframeTrack(
    '.material.opacity',
    [0, 0.2, 0.7, 1, 1.3],
    [0, 1, 1, 0.3, 0],
  );

  const start = new Vector3().copy(node.position).setX(-2);
  const end = new Vector3().copy(node.position).setX(3);

  // prettier-ignore
  const slide = new VectorKeyframeTrack(
    '.position',
    [0, 1],
    [
      start.x, start.y, start.z,
      end.x,   end.y,   end.z,
    ],
    InterpolateLinear,
  );

  const moveAction = mixer.clipAction(
    new AnimationClip(node.name, 1.5, [fadeInOut, slide]),
    node,
  );

  // moveAction.clampWhenFinished = true;
  moveAction.timeScale = 1 * timeDelta;

  return moveAction;
}

// ---- Old Arrow Animations
// -----------------------------------

export function twistOld(
  node: THREE.Object3D,
  mixer: THREE.AnimationMixer,
): THREE.AnimationAction {
  const start = node.quaternion;

  const mid = new Quaternion()
    .copy(node.quaternion)
    .multiply(new Quaternion().setFromAxisAngle(yAxis, Math.PI));

  const end = new Quaternion()
    .copy(node.quaternion)
    .multiply(new Quaternion().setFromAxisAngle(yAxis, Math.PI * 2));

  // prettier-ignore
  const kf = new QuaternionKeyframeTrack(
    '.quaternion',
    [0, 1, 2],
    [
      start.x,  start.y,  start.z,  start.w,
      mid.x,    mid.y,    mid.z,    mid.w,
      end.x,    end.y,    end.z,    end.w,
    ],
    InterpolateLinear,
  );

  const clipAction = mixer.clipAction(
    new AnimationClip(node.name, 2, [kf]),
    node,
  );
  clipAction.timeScale = 0.5;

  return clipAction;
}

export function tiltOld(
  node: THREE.Object3D,
  timeDelta = 1,
  mixer: THREE.AnimationMixer,
): THREE.AnimationAction {
  const start = new Quaternion().copy(node.quaternion).multiply(
    // 90deg + some offset to acount for the length of the arrow
    new Quaternion().setFromAxisAngle(xAxis, -(Math.PI / 2 + Math.PI / 4)),
  );

  const end = new Quaternion()
    .copy(node.quaternion)
    .multiply(new Quaternion().setFromAxisAngle(xAxis, (-3 * Math.PI) / 2));

  // prettier-ignore
  const kf = new QuaternionKeyframeTrack(
    '.quaternion',
    [0, 1],
    [
      start.x,  start.y,  start.z,  start.w,
      end.x,    end.y,    end.z,    end.w,
    ],
    InterpolateLinear,
  );

  const clipAction = mixer.clipAction(
    new AnimationClip(node.name, 1, [kf]),
    node,
  );
  clipAction.timeScale = 0.5 * timeDelta;

  return clipAction;
}
