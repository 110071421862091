/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import classnames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { polarToCartesian } from '@bfly/utils/math';

import {
  instructionClicked,
  tentativeInstructionCanceled,
  tentativeInstructionShown,
} from '../../actions/instruction';
import {
  TiltCommand,
  VisualCommand,
  VisualCommandType,
} from '../../Instruction';
import { RootState } from '../../store';
import ArrowHeadMarker from './ArrowHeadMarker';
import HelpText from './HelpText';
import InstructionPlane, {
  CENTER_X,
  CENTER_Y,
  CIRCLE_R,
} from './InstructionPlane';
import Slice from './Slice';
import SvgButton from './SvgButton';
import * as Svg from './SvgUtils';

const TILT_UP: TiltCommand = {
  type: VisualCommandType.TILT,
  angle: Math.PI / 2,
};

const TILT_DOWN: TiltCommand = {
  type: VisualCommandType.TILT,
  angle: (3 * Math.PI) / 2,
};

const TILT_RIGHT: TiltCommand = {
  type: VisualCommandType.TILT,
  angle: 0,
};

const TILT_LEFT: TiltCommand = {
  type: VisualCommandType.TILT,
  angle: Math.PI,
};

const styles = require('./Tilt-styles.module.scss');

const ARROW_HEAD_R = 280;
const ARROW_TAIL_R = 70;

interface ArrowProps {
  id: string;
  degrees: number;
  command: VisualCommand;
}
const Arrow = ({ id, degrees, command }: ArrowProps) => {
  const offset = (CIRCLE_R - ARROW_HEAD_R) / 2 + ARROW_HEAD_R;

  const dispatch = useDispatch();
  const stale = useSelector((s: RootState) => s.pose.stale);

  const activeCommand = useSelector(
    (s: RootState) => s.commands.activeCommand,
  );

  const active = activeCommand === command;
  const allowClicks = !stale && !active;
  const [xTail, yTail] = polarToCartesian(
    [CENTER_X, CENTER_Y],
    ARROW_TAIL_R,
    -degrees,
  );
  const [xHead, yHead] = polarToCartesian(
    [CENTER_X, CENTER_Y],
    ARROW_HEAD_R,
    -degrees,
  );

  return (
    <g className={classnames(styles.container, active && styles.active)}>
      <SvgButton
        onClick={() => {
          if (allowClicks) dispatch(instructionClicked(command));
        }}
        onMouseEnter={() => {
          if (allowClicks) dispatch(tentativeInstructionShown(command));
        }}
        onMouseLeave={() => {
          dispatch(tentativeInstructionCanceled());
        }}
      >
        <defs>
          <ArrowHeadMarker
            id={`${id}__arrow-end`}
            className={styles.arrowEnd}
          />
        </defs>
        <Slice
          r={offset}
          cx={CENTER_X}
          cy={CENTER_Y}
          startAngle={-degrees - 45}
          endAngle={-degrees + 45}
          fill="transparent"
        />
        <line
          strokeWidth={14}
          x1={xTail}
          y1={yTail}
          x2={xHead}
          y2={yHead}
          className={styles.arrow}
          strokeLinecap="round"
          markerEnd={`url(#${id}__arrow-end)`}
        />
      </SvgButton>
    </g>
  );
};

interface Props {
  indicatorDegrees: number | null;
}

function Tilt({ indicatorDegrees }: Props) {
  const [isHovering, setIsHovering] = useState(false);

  const rotate = Svg.toRotateTransform(indicatorDegrees);
  const showAllTilts = useSelector(
    (s: RootState) => s.session.heliosCapabilities.multiAxisTilts,
  );

  return (
    <div css={[require("./Tilt-CssProp1_div.module.scss")]}>
      <HelpText previewing={isHovering} />

      <InstructionPlane
        indicatorDegrees={indicatorDegrees}
        onPointerEnter={() => setIsHovering(true)}
        onPointerLeave={() => setIsHovering(false)}
      >
        <g transform={`rotate(${rotate}, ${CENTER_X}, ${CENTER_Y})`}>
          <Arrow id="up" degrees={90} command={TILT_UP} />
          {showAllTilts && (
            <Arrow id="left" degrees={180} command={TILT_LEFT} />
          )}
          <Arrow id="down" degrees={270} command={TILT_DOWN} />
          {showAllTilts && (
            <Arrow id="right" degrees={0} command={TILT_RIGHT} />
          )}
        </g>
      </InstructionPlane>
    </div>
  );
}

export default Tilt;
