/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import * as yup from 'yup';
import Form from '@bfly/ui/Form';
import FormControlWithAddon from '@bfly/ui/FormControlWithAddon';
import { useToast } from '@bfly/ui/ToastContext';
import useRelayMutation from '@bfly/ui/useRelayMutation';
import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';

import { CrossOrgInviteForm_RequestTelemedUserToUserPairingMutation as RequestTelemedUserToUserPairingMutation } from './__generated__/CrossOrgInviteForm_RequestTelemedUserToUserPairingMutation.graphql';
import { CrossOrgInviteForm_viewer as Viewer } from './__generated__/CrossOrgInviteForm_viewer.graphql';

interface Props {
  viewer: Viewer;
  className?: string;
}

const messages = defineMessages({
  addMemberPlaceholder: {
    id: 'addMember.placeholder',
    defaultMessage: 'Enter email address',
  },
});

const schema = yup.object({
  // TODO: add email check - Right now this only works with user ids but this will eventually be for emails.
  email: yup
    .string()
    .email()
    .required()
    .default(''),
});

const defaultValue = schema.default();

const addConnectionMutation = graphql`
  mutation CrossOrgInviteForm_RequestTelemedUserToUserPairingMutation(
    $input: RequestTelemedUserToUserPairingInput!
  ) {
    requestTelemedUserToUserPairingOrError(input: $input) {
      ...mutationError_error @relay(mask: false)
      ... on RequestTelemedUserToUserPairingPayload {
        userToUserPairingEdge {
          node {
            id
            recipientProfile {
              id
              name
            }
            ...CrossOrgConnectionItem_crossOrgConnection
          }
        }
      }
    }
  }
`;

function CrossOrgInviteForm({ viewer, className }: Props) {
  const [mutate] = useRelayMutation<RequestTelemedUserToUserPairingMutation>(
    addConnectionMutation,
  );
  const toast = useToast();

  const submit = async ({ email }: { email: string }) => {
    await mutate({
      input: {
        recipientEmail: email,
      },
      updater: store => {
        rangeAddUpdater(store, {
          parentId: viewer.id,
          connectionKey: 'CrossOrgConnectionPanel_crossOrgConnections',
          rootFieldName: 'requestTelemedUserToUserPairingOrError',
          rangeBehavior: 'append',
          edgeName: 'userToUserPairingEdge',
        });
      },
      onCompleted: ({ requestTelemedUserToUserPairingOrError }) => {
        if (!requestTelemedUserToUserPairingOrError) return;

        toast!.success(
          <FormattedMessage
            id="inviteSent"
            defaultMessage="Invite sent to {name}"
            values={{
              name: requestTelemedUserToUserPairingOrError.userToUserPairingEdge!
                .node!.recipientProfile!.name,
            }}
          />,
        );
      },
      onError: error => {
        toast!.error(
          error.message || (
            <FormattedMessage
              id="inviteFailed"
              defaultMessage="Invite failed to send"
            />
          ),
        );
      },
    });
  };

  return (
    <Form
      schema={schema}
      submitForm={submit}
      defaultValue={defaultValue}
      className={className}
    >
      <Form.FieldGroup name="email" events="onBlur" className="mb-0">
        {(props: any) => (
          <FormControlWithAddon
            {...props}
            type="email"
            theme="light"
            autoComplete="off"
            css={[require("./CrossOrgInviteForm-CssProp1_FormControlWithAddon.module.scss"), []]}
            placeholder={messages.addMemberPlaceholder}
            onChange={(e: any) => props.onChange(e.target.value)}
            addon={
              <Form.Submit>
                <FormattedMessage
                  id="members.sendInvite"
                  defaultMessage="Invite"
                />
              </Form.Submit>
            }
          />
        )}
      </Form.FieldGroup>
    </Form>
  );
}

export default createFragmentContainer(CrossOrgInviteForm, {
  viewer: graphql`
    fragment CrossOrgInviteForm_viewer on Viewer {
      id
    }
  `,
});
