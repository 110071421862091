import { Point } from '@bfly/utils/math';

export function getLocalPointFromEvent(event: PointerEvent): Point {
  const element = event.target as SVGElement;
  const svg = element.closest('svg');
  let pt = svg!.createSVGPoint();
  pt.x = event.clientX;
  pt.y = event.clientY;
  pt = pt.matrixTransform(svg!.getScreenCTM()!.inverse());
  return [pt.x, pt.y];
}

/**
 * SVG rotate transforms are clockwise, whilst most of our math returns counter-clockwise
 * rotation. This negates the rotation, but mostly just self documents what's happening
 *
 * @param degrees angle of rotation in degrees
 */
export function toRotateTransform(degrees?: number | null) {
  return -(degrees || 0);
}
