import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import Layout from '@4c/layout';
import DialogContext from '@bfly/ui/DialogContext';
import Navbar from '@bfly/ui/Navbar';
import Page from '@bfly/ui/Page';

import NavbarDropdown from './NavbarDropdown';
import { AppPage_viewer as Viewer } from './__generated__/AppPage_viewer.graphql';

interface Props {
  viewer?: Viewer | null;
  children?: React.ReactNode;
  navbar?: boolean;
}

const cloudLink = window.location.host.startsWith('telemed')
  ? 'https://cloud.butterflynetwork.com/'
  : '/';

function AppPage({ children, viewer, navbar = true }: Props) {
  const dialog = useContext(DialogContext) as any;

  const handleClick = async (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.metaKey || e.altKey || e.ctrlKey || e.shiftKey || e.button !== 0)
      return;
    e.preventDefault();
    const confirmed = await dialog.open(
      <FormattedMessage
        id="navbar.dialog.goOffline"
        defaultMessage="Do you want to leave TeleGuidance? You'll be marked as offline."
      />,
      {
        title: (
          <FormattedMessage
            id="navbar.dialog.leaveTeleGuidance"
            defaultMessage="Leave TeleGuidance"
          />
        ),
      },
    );

    if (confirmed) {
      window.location.assign(cloudLink);
    }
  };

  return (
    <Page>
      {navbar && (
        <Navbar>
          <Navbar.Brand />
          {viewer && (
            <>
              <Layout.Spacer />
              <Navbar.NavItem href={cloudLink} onClick={handleClick}>
                <FormattedMessage
                  id="appProductNav.cloud"
                  defaultMessage="Cloud"
                />
              </Navbar.NavItem>
              <Navbar.NavItem href="/-/telemed" active>
                <FormattedMessage
                  id="appProductNav.teleguidance"
                  defaultMessage="TeleGuidance"
                />
              </Navbar.NavItem>
              <Navbar.Divider />
              <NavbarDropdown viewer={viewer} />
            </>
          )}
        </Navbar>
      )}
      {children}
    </Page>
  );
}

export { AppPage };

export default createFragmentContainer(AppPage, {
  viewer: graphql`
    fragment AppPage_viewer on Viewer {
      ...NavbarDropdown_viewer
    }
  `,
});
