/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '@4c/layout';
import PhoneIco from '@bfly/icons/Phone';
import Heading from '@bfly/ui/Heading';
import Section from '@bfly/ui/Section';
import Text from '@bfly/ui/Text';

import vid0 from '../assets/getting-started/novice/0-Story.mp4';
import vid1 from '../assets/getting-started/novice/1-Turn on TeleGuidance.mp4';
import vid2 from '../assets/getting-started/novice/2-Set up Contacts.mp4';
import vid3 from '../assets/getting-started/novice/3-Call contact.mp4';
import vid4 from '../assets/getting-started/novice/4-Capture Cine.mp4';
import vid5 from '../assets/getting-started/novice/5-Point Camera at iQ.mp4';
import vid6a from '../assets/getting-started/novice/6a-Draw-Cropped.mp4';
import vid6b from '../assets/getting-started/novice/6b-Rotating-Cropped.mp4';
import vid6c from '../assets/getting-started/novice/6c-Tilting-Cropped.mp4';
import vid6d from '../assets/getting-started/novice/6d-Sliding-Cropped.mp4';
import poster0 from '../assets/getting-started/novice/generated-posters/0-Story.jpg';
import poster1 from '../assets/getting-started/novice/generated-posters/1-Turn on TeleGuidance.jpg';
import poster2 from '../assets/getting-started/novice/generated-posters/2-Set up Contacts.jpg';
import poster3 from '../assets/getting-started/novice/generated-posters/3-Call contact.jpg';
import poster4 from '../assets/getting-started/novice/generated-posters/4-Capture Cine.jpg';
import poster5 from '../assets/getting-started/novice/generated-posters/5-Point Camera at iQ.jpg';
import poster6a from '../assets/getting-started/novice/generated-posters/6a-Draw-Cropped.jpg';
import poster6b from '../assets/getting-started/novice/generated-posters/6b-Rotating-Cropped.jpg';
import poster6c from '../assets/getting-started/novice/generated-posters/6c-Tilting-Cropped.jpg';
import poster6d from '../assets/getting-started/novice/generated-posters/6d-Sliding-Cropped.jpg';
import AddContactIco from '../icons/AddContact';
import GettingStartedImageScroller from './GettingStartedImageScroller';
import {
  CompatibilityNote,
  Content,
  HRule,
  Step,
  StepHeader,
  StepSection,
} from './GettingStartedLayout';
import NoviceVideo from './GettingStartedNoviceVideo';
import GettingStartedStory from './GettingStartedStory';

// eslint-disable-next-line import/no-named-as-default

function GettingStartedNovicePage() {
  return (
    <>
      <main className="pt-16 pb-20">
        <Section>
          <Content>
            <Heading variant="display-2">
              <FormattedMessage
                id="gettingStarted.novice.title"
                defaultMessage="Call for real-time assistance."
              />
            </Heading>

            <FormattedMessage
              tagName="p"
              id="gettingStarted.novice.copy1"
              defaultMessage="Start video calls from the iQ app. Your contact can accept the call from a computer."
            />
            <CompatibilityNote className="mt-6">
              <FormattedMessage
                id="gettingStarted.novice.compat"
                defaultMessage="Supported on iPhone XR, XS, XS Max, 11, and iPad Pro 3rd generation"
              />
            </CompatibilityNote>
          </Content>

          <GettingStartedStory
            src={vid0}
            poster={poster0}
            name="novice.story"
          />
        </Section>
        <HRule className="mt-16 mb-4" />
        <StepSection>
          <StepHeader>
            <Heading variant="display-2">
              <FormattedMessage
                id="gettingStarted.novice.settingUp.title"
                defaultMessage="Setting up"
              />
            </Heading>
          </StepHeader>
          <Step span="2/3">
            <Heading variant="xl">
              <FormattedMessage
                id="gettingStarted.novice.settingUp.step1"
                defaultMessage="1. Turn on TeleGuidance in your iQ app settings."
              />
            </Heading>
            <p>
              <FormattedMessage
                id="gettingStarted.novice.settingUp.step1.body"
                defaultMessage="TeleGuidance will appear on the bottom left of your scan screen."
              />
            </p>
          </Step>
          <NoviceVideo
            src={vid1}
            poster={poster1}
            name="novice.enableTeleguidance"
          />

          <Step span="2/3">
            <Heading variant="xl">
              <FormattedMessage
                id="gettingStarted.novice.settingUp.step2"
                defaultMessage="2. Set up your contacts by inviting any Butterfly user."
              />
            </Heading>
            <p>
              <FormattedMessage
                id="gettingStarted.novice.settingUp.step2.body"
                defaultMessage="Tap on {phoneIcon} on your scanning screen, then tap {contactsIcon}."
                values={{
                  phoneIcon: <PhoneIco />,
                  contactsIcon: (
                    <AddContactIco
                      css={[require("./GettingStartedNovicePage-CssProp1_AddContactIco.module.scss"), []]}
                    />
                  ),
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="gettingStarted.novice.settingUp.step2.body2"
                defaultMessage="Invite any Butterfly user with their email address. Once your contact accepts the email invitation, you’ll be able to call them."
              />
            </p>
          </Step>
          <NoviceVideo
            src={vid2}
            poster={poster2}
            name="novice.setUpContacts"
          />
        </StepSection>

        <HRule className="mt-16 mb-4" />
        <StepSection>
          <StepHeader>
            <Heading variant="display-2">
              <FormattedMessage
                id="gettingStarted.novice.makingCalls.title"
                defaultMessage="Making calls"
              />
            </Heading>
          </StepHeader>
          <Step span="2/3">
            <Heading variant="xl">
              <FormattedMessage
                id="gettingStarted.novice.makingCalls.step1"
                defaultMessage="1. Tap on {phoneIcon} to call an available contact."
                values={{ phoneIcon: <PhoneIco /> }}
              />
            </Heading>
            <p>
              <FormattedMessage
                id="gettingStarted.novice.makingCalls.step1.body"
                defaultMessage="Ask your contact to go online so you can call them."
              />
            </p>
          </Step>
          <NoviceVideo src={vid3} poster={poster3} name="novice.makeCall" />

          <Step span="2/3">
            <Heading variant="xl">
              <FormattedMessage
                id="gettingStarted.novice.makingCalls.step2"
                defaultMessage="2. Get remote adjustments while scanning."
              />
            </Heading>
            <p>
              <FormattedMessage
                id="gettingStarted.novice.makingCalls.step2.body"
                defaultMessage="Your ultrasound image and camera are streamed to your remote collaborator."
              />
            </p>
            <p>
              <FormattedMessage
                id="gettingStarted.novice.makingCalls.step2.body2"
                defaultMessage="They can adjust the preset, mode, gain, depth, and capture images or cines."
              />
            </p>
          </Step>
          <NoviceVideo
            src={vid4}
            poster={poster4}
            name="novice.getAdjustments"
          />

          <Step span="2/3">
            <Heading variant="xl">
              <FormattedMessage
                id="gettingStarted.novice.makingCalls.step3"
                defaultMessage="3. Point your camera at the iQ to show how it’s positioned."
              />
            </Heading>
            <p>
              <FormattedMessage
                id="gettingStarted.novice.makingCalls.step3.body"
                defaultMessage="Keep the tail in view to receive graphical instructions. The ring tracks the tail of the probe."
              />
            </p>
          </Step>
          <NoviceVideo
            src={vid5}
            poster={poster5}
            name="novice.showProbeRing"
          />

          <Step span="full">
            <Heading variant="xl">
              <FormattedMessage
                id="gettingStarted.novice.makingCalls.step4"
                defaultMessage="4. Follow the visual illustrations to adjust the iQ positioning."
              />
            </Heading>
            <p>
              <FormattedMessage
                id="gettingStarted.novice.makingCalls.step4.body"
                defaultMessage="During your conversation, your collaborator may indicate how to move the probe with graphical instructions."
              />
            </p>
            <div>
              <CompatibilityNote>
                <FormattedMessage
                  id="gettingStarted.novice.makingCalls.step4.note"
                  defaultMessage="Keep the iQ tail in view to receive graphical instructions."
                />
              </CompatibilityNote>
            </div>
          </Step>
        </StepSection>
        <GettingStartedImageScroller className="mt-6">
          <Layout direction="column" align="center">
            <NoviceVideo src={vid6a} poster={poster6a} name="novice.draw" />
            <Text variant="body-bold" color="headline" className="pt-4">
              <FormattedMessage
                id="gettingStarted.novice.makingCalls.vidA"
                defaultMessage="Drawing"
              />
            </Text>
          </Layout>
          <Layout direction="column" align="center">
            <NoviceVideo src={vid6b} poster={poster6b} name="novice.rotate" />
            <Text variant="body-bold" color="headline" className="pt-4">
              <FormattedMessage
                id="gettingStarted.novice.makingCalls.vidb"
                defaultMessage="Rotating"
              />
            </Text>
          </Layout>
          <Layout direction="column" align="center">
            <NoviceVideo src={vid6c} poster={poster6c} name="novice.tilt" />
            <Text variant="body-bold" color="headline" className="pt-4">
              <FormattedMessage
                id="gettingStarted.novice.makingCalls.vidv"
                defaultMessage="Tilting"
              />
            </Text>
          </Layout>
          <Layout direction="column" align="center">
            <NoviceVideo src={vid6d} poster={poster6d} name="novice.slide" />
            <Text variant="body-bold" color="headline" className="pt-4">
              <FormattedMessage
                id="gettingStarted.novice.makingCalls.vidd"
                defaultMessage="Sliding"
              />
            </Text>
          </Layout>
        </GettingStartedImageScroller>
      </main>
    </>
  );
}

export default GettingStartedNovicePage;
