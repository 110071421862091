import { useEffect } from 'react';

import notificationSound from '../../assets/new-request.mp3';

let audio: HTMLAudioElement;
if (typeof document !== 'undefined') {
  audio = new Audio(notificationSound);
  audio.loop = true;
  audio.preload = 'auto';
}

let handle: NodeJS.Timeout | null = null;
let ref = 0;

const ringtone = {
  play() {
    if (!audio || handle != null) return;

    audio.play();
    if (handle) {
      clearTimeout(handle);
      handle = null;
    }

    handle = setTimeout(ringtone.stop, 6500);
  },

  stop() {
    handle = null;
    if (!audio) return;
    audio.pause();
    audio.currentTime = 0;
  },
};

/**
 * Returns a global ref counted Ringtone object that will only ring once and
 * automatically stop when all consumers have unmounted (or triggered manually).
 */
export default function useRingtone() {
  useEffect(() => {
    ref++;
    return () => {
      ref--;
      if (ref <= 0) {
        ringtone.stop();
      }
    };
  }, []);

  return ringtone;
}
