/* eslint-disable no-param-reassign */
import {
  AnimationMixer,
  AxesHelper,
  Box3,
  Color,
  DirectionalLightHelper,
  GridHelper,
  Material,
  Matrix4,
  Mesh,
  Object3D,
  Vector2,
  Vector3,
  Math as _Math,
} from 'three';

import { VisualCommandType } from '../Instruction';
import * as Animations from './animations';
import { ArState } from './MainScene';
import loadModel, { OldSceneObjectName } from './oldLoader';
import Renderer from './Renderer';
import {
  activeIndicatorColor,
  makeLights,
  setColor,
  tentativeIndicatorColor,
} from './sceneObjects';

export type ScreenCoordinate = [number, number];

export interface RendererOptions {
  container: HTMLDivElement;
  probeOcclusion?: boolean;
  debug?:
    | true
    | {
        showProbe?: boolean;
        gridHelper?: boolean;
        axesHelper?: boolean;
        lightHelper?: boolean;
        cameraControls?: boolean;
      };
}

const xAxis = new Vector3(1, 0, 0);
const yAxis = new Vector3(0, 1, 0);
const zAxis = new Vector3(0, 0, 1);

const getBoundingBox = (object: Object3D) => new Box3().setFromObject(object);

type HelperMap = {
  [key: string]: AxesHelper | GridHelper | DirectionalLightHelper;
};

export default class OldArrowsRenderer extends Renderer<ArState> {
  private probeBoundingBox: Box3 | null = null;

  private readonly probeContainer: Object3D;

  private readonly compassContainer: Object3D;

  private readonly helpers: HelperMap = {};

  private readonly commandToObjectMap = new Map<VisualCommandType, Object3D>();

  private readonly modelNode = new Object3D();

  private readonly mixer = new AnimationMixer(this.scene);

  constructor(readonly container: HTMLElement) {
    super(container);

    this.scene.add(this.modelNode);
    this.scene.add(...makeLights());

    this.probeContainer = new Object3D();
    this.compassContainer = new Object3D();

    loadModel().then(objectMap => {
      const { probeContainer, compassContainer } = this;
      const probe = objectMap[OldSceneObjectName.Probe].clone() as Mesh;
      probe.renderOrder = 1;

      const boundingBox = getBoundingBox(probe);

      this.probeBoundingBox = boundingBox;

      probeContainer.position.set(0, -2 * boundingBox.min.y, 0);
      probe.position.set(0, boundingBox.min.y, 0);

      // TODO: why does this need to be seperate from the Probe model?
      const compassNode = objectMap.Compass.clone() as Mesh;
      compassNode.renderOrder = 2;

      compassContainer.position.set(0, -20, 0);
      compassContainer.add(compassNode);

      probeContainer.add(probe, compassContainer);
    });

    this.modelNode.add(
      this.probeContainer,
      this.buildRotateArrows(),
      this.buildTiltArrows(),
      this.buildLateralArrows(),
    );

    // Checkmark is unrotated and should always face the camera, so keep it
    // separate.
    this.scene.add(this.buildCheckmark());
  }

  buildLateralArrows() {
    const arrowContainer = new Object3D();

    loadModel().then(objectMap => {
      const len = this.probeBoundingBox!.max.y - this.probeBoundingBox!.min.y;
      const arrow = objectMap[OldSceneObjectName.LateralArrow].clone();

      // place at probe head?
      arrowContainer.position.set(0, len / 2, 0);
      arrow.position.x += 10;
      arrow.renderOrder = 2;
      arrow.castShadow = true;
      arrow.receiveShadow = true;

      arrow.visible = false;
      this.commandToObjectMap.set(VisualCommandType.TRANSLATE, arrow);
      arrowContainer.add(arrow);
    });

    return arrowContainer;
  }

  buildCheckmark() {
    const checkmarkContainer = new Object3D();

    loadModel().then(objectMap => {
      const checkmark = objectMap[OldSceneObjectName.CheckMark].clone();

      checkmark.renderOrder = 2;
      checkmark.visible = false;
      this.commandToObjectMap.set(VisualCommandType.HOLD_POSITION, checkmark);
      checkmarkContainer.add(checkmark);
    });

    return checkmarkContainer;
  }

  buildTiltArrows() {
    const tiltContainer = new Object3D();

    loadModel().then(objectMap => {
      const tiltArrow = objectMap.TiltArrow.clone();
      tiltArrow.name = 'TiltArrow';
      tiltArrow.visible = false;
      tiltArrow.renderOrder = 2;
      tiltArrow.castShadow = true;
      tiltArrow.receiveShadow = true;

      // It's easier to rotate a container into the right position than it is
      // to adjust the arrow animation
      tiltContainer.rotateOnAxis(zAxis, Math.PI);

      Animations.tiltOld(tiltArrow, 1, this.mixer).play();

      this.commandToObjectMap.set(VisualCommandType.TILT, tiltArrow);
      tiltContainer.add(tiltArrow);
    });
    return tiltContainer;
  }

  buildRotateArrows() {
    const rotateContainer = new Object3D();

    loadModel().then(objectMap => {
      const rotateArrow = objectMap.TwistArrow.clone();
      rotateArrow.name = 'RotateArrow';
      rotateArrow.position.set(0, 0, 0);
      rotateArrow.visible = false;
      rotateArrow.renderOrder = 2;
      rotateArrow.castShadow = true;
      rotateArrow.receiveShadow = true;

      Animations.twistOld(rotateArrow, this.mixer).play();

      this.commandToObjectMap.set(
        VisualCommandType.ROTATE_CLOCKWISE,
        rotateArrow,
      );
      this.commandToObjectMap.set(
        VisualCommandType.ROTATE_COUNTERCLOCKWISE,
        rotateArrow,
      );
      rotateContainer.add(rotateArrow);
    });
    return rotateContainer;
  }

  updateProbe({ debug, probeOcclusion, activeCommand }: ArState) {
    const probe = this.probeContainer.children[0] as Mesh;
    if (!probe) return;

    // First reset the position, then update it if there's a command.
    this.probeContainer.setRotationFromMatrix(new Matrix4());
    this.probeContainer.position.set(0, this.probeContainer.position.y, 0);

    const visible = debug.showProbe || probeOcclusion;
    probe.visible = visible;

    if (!debug.showProbe) {
      (probe.material as Material).colorWrite = !probeOcclusion;
    }
    setColor(
      probe.material,
      activeCommand ? activeIndicatorColor : tentativeIndicatorColor,
    );
  }

  updateHelpers({ debug }: ArState) {
    if (debug.axesHelper && !this.helpers.axesHelper) {
      this.helpers.axesHelper = new AxesHelper(10);
      this.helpers.axesHelper.position.x = -10;
      this.helpers.axesHelper.position.y = 10;
      this.scene.add(this.helpers.axesHelper);
    }
    if (debug.gridHelper && !this.helpers.gridHelper) {
      this.scene.add(
        (this.helpers.gridHelper = new GridHelper(
          100,
          10,
          new Color(0xff0000),
          new Color(0xffffff),
        )),
      );
    }
    if (this.helpers.axesHelper)
      this.helpers.axesHelper.visible = debug.axesHelper;
    if (this.helpers.gridHelper)
      this.helpers.gridHelper.visible = debug.gridHelper;
  }

  render(state: ArState, timeDeltaSeconds: DOMHighResTimeStamp) {
    this.updateHelpers(state);

    if (!this.probeBoundingBox) return;

    this.updateProbe(state);
    this.modelNode.setRotationFromMatrix(state.probeRotation);

    for (const obj of this.commandToObjectMap.values()) {
      obj.visible = false;
    }

    this.modelNode.visible = !state.poseStale && !state.imagingFrozen;

    if (state.activeCommand || state.tentativeCommand) {
      const command = state.activeCommand
        ? state.activeCommand!
        : state.tentativeCommand!;
      const obj = this.commandToObjectMap.get(command.type)! as Mesh;

      // All arrows have just a single node that gets rotated based on the
      // direction of the active command.
      switch (command.type) {
        case VisualCommandType.TRANSLATE:
          obj.parent!.setRotationFromAxisAngle(yAxis, -command.angle);
          break;
        case VisualCommandType.TILT:
          obj.parent!.setRotationFromAxisAngle(
            yAxis,
            Math.PI / 2 - command.angle,
          );
          break;
        case VisualCommandType.ROTATE_COUNTERCLOCKWISE:
          obj.parent!.setRotationFromAxisAngle(xAxis, Math.PI);
          break;
        case VisualCommandType.ROTATE_CLOCKWISE:
          obj.parent!.setRotationFromAxisAngle(xAxis, 0);
          break;
        default:
        // HOLD_POSITION doesn't get modified here
      }

      // TODO: Add a material for the checkmark, so it can have a gray preview.
      // Right now it always shows as green.
      if (obj.material) {
        setColor(
          obj.material,
          state.commandIsTentative
            ? tentativeIndicatorColor
            : activeIndicatorColor,
        );
      }
      obj.visible = true;
    }

    const { width, height } = state.dimensions;

    const size = this.renderer.getSize(new Vector2());
    if (size.width !== width || size.height !== height) {
      this.renderer.setSize(width, height);
    }

    this.mixer.update(timeDeltaSeconds);

    this.renderer.render(this.scene, state.camera);
  }
}
