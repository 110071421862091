/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import React, { useRef } from 'react';
import { polarToCartesian } from '@bfly/utils/math';

import colors from '../../utils/colors';

type Props = {
  degrees?: number | null;
  cx: number;
  cy: number;
  r: number;
};

const IndicatorDot = ({ cx, cy, r, degrees }: Props) => {
  const lastRef = useRef(degrees || 0);
  const stale = degrees == null;

  const [x, y] = polarToCartesian(
    [cx, cy],
    r,
    (!stale ? degrees! : lastRef.current) * -1,
  );
  if (!stale) lastRef.current = degrees!;

  return (
    <g>
      <circle
        cx={x}
        cy={y}
        r={40}
        css={[require("./IndicatorDot-CssProp1_circle.module.scss"), [["a18va22c", stale ? 0.2 : 1], ["a15t58z1", stale ? '#5e5e5e' : colors.blue]]]}
      />
    </g>
  );
};

export default IndicatorDot;
