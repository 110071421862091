import { get, keys, set } from 'idb-keyval';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import { Mesh, ObjectLoader } from 'three';
import { Collada } from 'three/examples/jsm/loaders/ColladaLoader';

import probeModel from '../assets/probe-scene-v0.dae';
import { cloneMaterial } from './sceneObjects';

export enum OldSceneObjectName {
  Probe = 'Probe',
  TwistArrow = 'TwistArrow',
  TiltArrow = 'TiltArrow',
  LateralArrow = 'LateralArrow',
  CheckMark = 'CheckMark',
  Compass = 'Compass',
  TiltHandle = 'TiltHandle',
  TiltHitBox = 'TiltHitBox',
  CompassHitBox = 'CompassHitBox',
}

type OldSceneObjectMap = Record<OldSceneObjectName, Mesh>;

const KEY = '@telemed/probe-model';
const VERSION_KEY = `${KEY}:version`;

let modelRequest: Promise<OldSceneObjectMap>;

async function loadCollada(): Promise<OldSceneObjectMap> {
  const { ColladaLoader } = await import(
    'three/examples/jsm/loaders/ColladaLoader'
  );

  return new Promise(resolve => {
    new ColladaLoader().load(probeModel, (model: Collada) => {
      // after import, all objects share one Material
      // this means we are unable to change one object's color while leaving others in place
      // fix by walking the entire scene graph
      model.scene.traverse((child: Mesh) => cloneMaterial(child));

      const objectMap = keyBy(
        model.scene.children.filter(c => c.name in OldSceneObjectName),
        value => value.name as OldSceneObjectName,
      ) as OldSceneObjectMap;

      try {
        localStorage.setItem(VERSION_KEY, process.env.PROBE_MODEL_VERSION_V0!);
      } catch (err) {
        /* ignore */
      }
      set(
        KEY,
        mapValues(objectMap, v => v.toJSON()),
      ).then(() => {
        resolve(objectMap);
      });
    });
  });
}

const cacheIsStillValid = () => {
  try {
    return (
      localStorage.getItem(VERSION_KEY) === process.env.PROBE_MODEL_VERSION_V0
    );
  } catch (err) {
    return null;
  }
};

async function loadLocally(): Promise<OldSceneObjectMap> {
  const loader = new ObjectLoader();
  console.time('IndexDBLoader: Parse');
  const json = await get<Record<string, any>>(KEY);

  const result = mapValues(json, value => loader.parse(value)) as any;

  console.timeEnd('IndexDBLoader: Parse');
  return result;
}

async function loadModelImpl(): Promise<OldSceneObjectMap> {
  const hasCached = cacheIsStillValid() && (await keys()).includes(KEY);

  return hasCached ? loadLocally() : loadCollada();
}

export default function loadModel() {
  if (!modelRequest) modelRequest = loadModelImpl();
  return modelRequest;
}
