/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '@4c/layout';
import Text from '@bfly/ui/Text';

import CloseButton from './CloseButton';
import DepthRange from './DepthRange';
import GainRange from './GainRange';
import Panel from './Panel';

interface Props {
  onClose: () => void;
  disabled: boolean;
}

function GainAndDepthControls({ onClose, disabled }: Props) {
  return (
    <Panel display="block">
      <Layout className="px-4 py-3" pad={6} justify="space-between">
        <Layout align="center" pad>
          <Text color="headline">
            <FormattedMessage
              id="gainAndDepthControls.gain"
              defaultMessage="Gain"
            />
          </Text>
          <GainRange disabled={disabled} />
        </Layout>
        <Layout align="center" pad>
          <Text color="headline">
            <FormattedMessage
              id="gainAndDepthControls.depth"
              defaultMessage="Depth"
            />
          </Text>
          <DepthRange disabled={disabled} />
        </Layout>
      </Layout>
      <Panel.Hr />

      <Layout
        pad={4}
        align="center"
        justify="space-between"
        className="px-4 py-3"
      >
        {/* z-index needed for button to be on top of overlay when controls are disabled */}
        <CloseButton css={[require("./GainAndDepthControls-CssProp1_CloseButton.module.scss")]} onClick={onClose} />
      </Layout>
    </Panel>
  );
}

export default GainAndDepthControls;
