/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import classNames from 'classnames';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import Layout from '@4c/layout';
import PlusIcon from '@bfly/icons/Plus';
import Text from '@bfly/ui/Text';
import getNodes from '@bfly/utils/getNodes';

import BlueLinkButton from './BlueLinkButton';
import CrossOrgConnectionList from './CrossOrgConnectionList';
import CrossOrgModal from './CrossOrgModal';
import { CrossOrgConnectionPanel_viewer as Viewer } from './__generated__/CrossOrgConnectionPanel_viewer.graphql';

function AddConnectionsControl({ numConnections = 0, onClick }: any) {
  return (
    <Layout className="mt-3">
      <BlueLinkButton onClick={onClick}>
        <Layout
          align="center"
          justify="center"
          css={[require("./CrossOrgConnectionPanel-CssProp1_Layout.module.scss"), []]}
        >
          {numConnections > 4 ? (
            <span>{numConnections - 4}+</span>
          ) : (
            <PlusIcon />
          )}
        </Layout>
        {numConnections > 4 ? (
          <FormattedMessage
            id="externalContact.view"
            defaultMessage="Edit or view all external contacts"
          />
        ) : (
          <FormattedMessage
            id="externalContact.add"
            defaultMessage="Add a contact"
          />
        )}
      </BlueLinkButton>
    </Layout>
  );
}

interface Props {
  viewer: Viewer;
  hideAddContacts?: boolean;
}

function CrossOrgConnectionPanel({ viewer, hideAddContacts }: Props) {
  const crossOrgConnections = getNodes(viewer.crossOrgConnections);
  const [showCrossOrgModal, setShowCrossOrgModal] = useState(false);

  return (
    <Layout direction="column" className="mt-3 pb-3">
      <Text
        as="div"
        color="headline"
        variant="body-bold"
        className={classNames(
          !crossOrgConnections.length && hideAddContacts && 'mb-4',
        )}
      >
        <FormattedMessage
          id="crossOrg.externalContacts"
          defaultMessage="External contacts"
        />
      </Text>
      <CrossOrgConnectionList
        crossOrgConnections={crossOrgConnections}
        viewer={viewer}
      />
      {!hideAddContacts && (
        <>
          <AddConnectionsControl
            numConnections={crossOrgConnections.length}
            onClick={() => setShowCrossOrgModal(true)}
          />
          <CrossOrgModal
            setShowCrossOrgModal={setShowCrossOrgModal}
            show={showCrossOrgModal}
            viewer={viewer}
          />
        </>
      )}
    </Layout>
  );
}

export default createFragmentContainer(CrossOrgConnectionPanel, {
  viewer: graphql`
    fragment CrossOrgConnectionPanel_viewer on Viewer {
      crossOrgConnections: telemedUserToUserPairingConnection(
        first: 2147483647
      ) @connection(key: "CrossOrgConnectionPanel_crossOrgConnections") {
        edges {
          node {
            ...CrossOrgConnectionList_crossOrgConnections
          }
        }
      }
      ...CrossOrgConnectionList_viewer
      ...CrossOrgModal_viewer
    }
  `,
});
