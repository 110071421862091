import { Action, AnyAction } from 'redux';

type ReducersMap<S = {}, A extends Action<any> = AnyAction> = {
  [K in keyof S]: (state: S[K], action: A) => S[K];
};

export function createReducer<State, A extends Action<any>>(
  initialState: State,
  reducers: ReducersMap<Partial<State>, A>,
  rootHandler: (state: State, action: A) => State,
) {
  const keys = Object.keys(reducers) as Array<
    keyof ReducersMap<Partial<State>, A>
  >;

  return (state: State = initialState, action: A) => {
    let hasChanged = false;

    const nextState: any = {};
    for (const key of keys) {
      const reducer = reducers[key];
      const prevStateKey = state[key];
      const nextStateKey = reducer(prevStateKey, action);

      nextState[key] = nextStateKey;
      hasChanged = hasChanged || nextStateKey !== prevStateKey;
    }

    const rootHandlerState = rootHandler(nextState, action);
    hasChanged = hasChanged || rootHandlerState !== nextState;

    return hasChanged ? (nextState as State) : state;
  };
}

export function combineFieldReducers<
  State extends object,
  A extends Action<any>
>(reducers: ReducersMap<Partial<State>, A>) {
  const keys = Object.keys(reducers) as Array<
    keyof ReducersMap<Partial<State>, A>
  >;

  return (state: State, action: A): State => {
    let hasChanged = false;

    const nextState: Partial<State> = {};
    for (const key of keys) {
      const reducer = reducers[key];
      const prevStateKey = state[key];
      const nextStateKey = reducer(prevStateKey, action);

      nextState[key] = nextStateKey;
      hasChanged = hasChanged || nextStateKey !== prevStateKey;
    }

    if (hasChanged) return { ...state, ...nextState };

    return state;
  };
}
