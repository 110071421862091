import { Observable, fromEvent, merge } from 'rxjs';
import { flatMap, map, startWith } from 'rxjs/operators';
import { VideoTrack } from 'twilio-video';

import { Dimensions } from '../../types';

const emptyDimensions: VideoTrack.Dimensions = { width: null, height: null };

const mapTrackDimensions = () => <T extends VideoTrack>(src: Observable<T>) =>
  src.pipe(
    flatMap(t =>
      merge(
        fromEvent<VideoTrack>(t, 'started'),
        fromEvent<VideoTrack>(t, 'dimensionsChanged'),
      ),
    ),
    map(vt => vt.dimensions),
    startWith(emptyDimensions),
  );

export const hasDimensions = (
  t: Dimensions | VideoTrack.Dimensions,
): t is Dimensions => !!(t.width && t.height);

export const aspectRatio = ({ width, height }: Dimensions): number =>
  width / height;

export default mapTrackDimensions;
