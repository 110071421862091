/* tslint:disable */
/* eslint-disable */
/* @relayHash 6c40aae412a3f01a49d4f4225ba74537 */

import { ConcreteRequest } from "relay-runtime";
export type routeConfig_LDQueryVariables = {};
export type routeConfig_LDQueryResponse = {
    readonly viewer: {
        readonly memberships: ReadonlyArray<{
            readonly organization: {
                readonly viewerLaunchDarklyConfig: {
                    readonly user: unknown | null;
                    readonly hash: string | null;
                    readonly state: unknown | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
};
export type routeConfig_LDQuery = {
    readonly response: routeConfig_LDQueryResponse;
    readonly variables: routeConfig_LDQueryVariables;
};



/*
query routeConfig_LDQuery {
  viewer {
    memberships {
      organization {
        viewerLaunchDarklyConfig {
          user
          hash
          state
        }
        id
      }
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "viewerLaunchDarklyConfig",
  "storageKey": null,
  "args": null,
  "concreteType": "LaunchDarklyConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "user",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hash",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "state",
      "args": null,
      "storageKey": null
    }
  ]
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "routeConfig_LDQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "memberships",
            "storageKey": null,
            "args": null,
            "concreteType": "ViewerMembership",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "organization",
                "storageKey": null,
                "args": null,
                "concreteType": "Organization",
                "plural": false,
                "selections": [
                  (v0/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "routeConfig_LDQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "memberships",
            "storageKey": null,
            "args": null,
            "concreteType": "ViewerMembership",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "organization",
                "storageKey": null,
                "args": null,
                "concreteType": "Organization",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/)
                ]
              },
              (v1/*: any*/)
            ]
          },
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "routeConfig_LDQuery",
    "id": null,
    "text": "query routeConfig_LDQuery {\n  viewer {\n    memberships {\n      organization {\n        viewerLaunchDarklyConfig {\n          user\n          hash\n          state\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'c2f5008ea44d461bdec7718a88435b4c';
export default node;
