import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import NetworkLayer, {
  LocalTokenStorage,
  useAuthToken,
} from 'relay-network-layer';
import {
  Environment,
  RecordSource,
  Store,
  fetchQuery,
  graphql,
} from 'relay-runtime';
import DialogContext from '@bfly/ui/DialogContext';

import { identify } from '../utils/Analytics';
import { AuthProvider_SessionInfoQuery as SessionInfoQuery } from './__generated__/AuthProvider_SessionInfoQuery.graphql';

export interface AuthContextValue {
  environment: Environment | null;
  tokenState: TokenState | null;
  clearTokenResponse(): void;
  updateTokenResponse(resp: AuthResponse): Promise<void>;
}

export const AuthContext = React.createContext<AuthContextValue>({
  environment: null,
  tokenState: null,
  clearTokenResponse() {
    //
  },
  async updateTokenResponse() {
    //
  },
});

export interface AuthResponse {
  accessToken: string;
  expiresAt?: number;
}

type SessionInfo = {
  expiresAt: number;
  localId: string;
};

async function fetchSessionInfo(
  environment: Environment,
): Promise<SessionInfo> {
  const result = await fetchQuery<SessionInfoQuery>(
    environment,
    graphql`
      query AuthProvider_SessionInfoQuery {
        viewer {
          authorizationExpiration
          localId
        }
      }
    `,
    {},
  );
  const { viewer } = result;

  return {
    localId: viewer!.localId!,
    expiresAt: new Date(viewer!.authorizationExpiration!).getTime(),
  };
}

export const useAuthContext = () => useContext(AuthContext)!;

const origin = window.bflyConfig.BNI_API_UPSTREAM;

type Props = {
  children(api: AuthContextValue): React.ReactNode;
};

export type TokenState = AuthResponse & SessionInfo;

function createEnvironment(token?: string) {
  const store = new Store(new RecordSource());
  store.holdGC(); // Disable GC on the store.

  return new Environment({
    network: NetworkLayer.create({
      url: `${origin}/graphql`,
      subscriptionUrl: `${origin}/socket.io/graphql`,
      authorization: {
        token: token || '',
        scheme: 'JWT',
      },
    }),
    store,
  });
}
const dft = {};
function useRefWithDefaultValueFactory<T>(defaultValue: (() => T) | T) {
  const ref = useRef<T>(dft as any);
  if (ref.current === dft) {
    ref.current =
      defaultValue instanceof Function ? defaultValue() : defaultValue;
  }
  return ref;
}

function useAuthState(onTokenExpired: () => void | Promise<void>) {
  const [tokenState, setTokenState] = useAuthToken<TokenState>({
    async onTokenExpired() {
      if (window.location.pathname.startsWith('/session')) {
        return;
      }

      await onTokenExpired();

      setTokenState(null);
      window.location.reload();
    },
    tokenStorage: new LocalTokenStorage('bfly:token'),
  });

  const envRef = useRefWithDefaultValueFactory(() =>
    createEnvironment(tokenState?.accessToken),
  );

  const updateTokenResponse = useCallback(
    async (tokenResponse: AuthResponse | null): Promise<void> => {
      const prevNetwork: any = envRef.current?.getNetwork();

      if (prevNetwork && typeof prevNetwork.close === 'function') {
        prevNetwork.close();
      }

      envRef.current = createEnvironment(tokenResponse?.accessToken);

      if (!tokenResponse) {
        await setTokenState(null);
        return;
      }

      // Before setting the tokenState, lookup the localId from the server
      // and add it to the state that'll be kept in local stroage.
      const sessionInfo = await fetchSessionInfo(envRef.current);

      identify(sessionInfo.localId, {});

      await setTokenState({
        ...tokenResponse,
        ...sessionInfo,
      });
    },
    [envRef, setTokenState],
  );

  const authState = useMemo(
    () => ({
      tokenState,
      environment: envRef.current,
      clearTokenResponse() {
        updateTokenResponse(null);
      },
      updateTokenResponse,
    }),
    [tokenState, envRef, updateTokenResponse],
  );

  return authState;
}

function AuthProvider({ children }: Props) {
  const dialog = useContext(DialogContext) as any;
  const authState = useAuthState(() =>
    dialog.open(
      <FormattedMessage
        id="auth.sessionExpired.body"
        defaultMessage="Your session is about to expire. Please login in again to continue."
      />,
      {
        hideCancel: true,
        title: (
          <FormattedMessage
            id="auth.sessionExpired.title"
            defaultMessage="Session Expired"
          />
        ),
      },
    ),
  );

  return (
    <AuthContext.Provider value={authState}>
      {children(authState)}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
