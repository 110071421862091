/**
 * Mimics the behavior of css' `object-fit: contain`
 */
export default function contain(
  parentWidth: number,
  parentHeight: number,
  childRatio: number,
) {
  const parentRatio = parentWidth / parentHeight;
  let width = parentWidth;
  let height = parentHeight;

  if (childRatio > parentRatio) {
    height = width / childRatio;
  } else {
    width = height * childRatio;
  }

  return {
    width,
    height,
  };
}
