/* eslint-disable */
/* prettier-ignore-start */
// [GENERATED FILE; DO NOT EDIT BY HAND]

import React from "react"

var element = React.createElement("svg", {
  viewBox: "0 0 40 40",
  fill: "currentColor",
  "aria-hidden": "true"
}, React.createElement("path", {
  fill: "none",
  d: "M0 0h40v40H0z"
}), React.createElement("circle", {
  cx: "20",
  cy: "20",
  r: "19.99",
  fill: "#fff",
  fillRule: "evenodd",
  clipRule: "evenodd"
}), React.createElement("path", {
  d: "M16.501 13.794a.927.927 0 00-.645.883v9.901a2.721 2.721 0 00-2.377-.203 2.75 2.75 0 00-1.7 1.87 2.784 2.784 0 002.744 3.506c1.537-.03 2.723-1.388 2.723-2.926V16.506c0-.101.065-.19.161-.221l9.214-3.056a.232.232 0 01.302.221v7.037a2.72 2.72 0 00-2.384-.2 2.754 2.754 0 00-1.695 1.873 2.785 2.785 0 002.747 3.5c1.537-.031 2.721-1.388 2.721-2.925V11.178a.927.927 0 00-1.208-.883l-10.603 3.499z",
  fill: "#262626"
}));

var Svg = React.forwardRef(function (props, ref) {
  var next = { ref: ref };
  for (var key in props) {
    if (props.hasOwnProperty(key)) {
      next[key] = props[key];
    }
  }
  return React.cloneElement(element, next);
});
Svg.displayName = "RingtoneOn";
Svg.element = element;
export default Svg;
