/* import-sort-ignore */

import './styles/styles.scss';
import { create } from 'rxjs-spy';

import {
  createConnectedRouter,
  createRender,
  HttpError,
  Match,
  RouteConfig,
  RedirectException,
  Location,
} from 'found';
import { Resolver as RelayResolver } from 'found-relay';

import * as React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import DialogProvider from '@bfly/ui/DialogProvider';
import ToastContainer from '@bfly/ui/ToastContainer';
import ToastProvider from '@bfly/ui/ToastProvider';

import store from './store';
import AuthProvider, { AuthContextValue } from './components/AuthProvider';
import LoginPage from './components/LoginPage';
import polyfills from './polyfills';
import { LaunchDarklyManager } from './utils/LaunchDarkly';
import isInApp from './utils/isInApp';
import getClosestLocale, { DEFAULT } from '@bfly/utils/getClosestLocale';

// expose window.store.getState() for debugging
// https://stackoverflow.com/questions/12709074/how-do-you-explicitly-set-a-new-property-on-window-in-typescript
declare global {
  interface Window {
    spy: any;
    store: any;
  }
}

window.store = store;

window.spy = create();

// Keep this working on the old telemed domain for now by redirecting to the
// landing page with the same path as the one on cloud.butterflynetwork.com.
if (window.location.pathname === '/') {
  window.location.replace('/-/telemed');
}

function isPublicRoute(routes: RouteConfig[]) {
  return routes.reduce(
    (prevAllowPublic, { allowPublic }) =>
      allowPublic == null ? prevAllowPublic : allowPublic,
    false,
  );
}

const render = createRender({
  renderError: ({ error }) => {
    switch (error.status) {
      case 404:
        return (
          <div>
            <h3>Couldn&apos;t find page</h3>
          </div>
        );
      case 401:
        // @ts-ignore Broken upstream typedef
        return <LoginPage />;
      default:
        return <span />;
    }
  },
});

class Resolver extends RelayResolver {
  constructor(private auth: AuthContextValue) {
    super(auth.environment);
  }

  getAccessTokenFromHash(location: Location, routes: RouteConfig[]) {
    if (
      !location.hash ||
      !!this.auth.tokenState ||
      !routes.some(r => r.allowLoginFromHash)
    ) {
      return null;
    }

    const hash = new URLSearchParams(location.hash.substring(1));
    return hash.get('access_token');
  }

  async loginFromHashToken(location: Location, accessToken: string) {
    // Do not run login if we're not on a valid user agent.
    if (isInApp()) {
      try {
        await this.auth.updateTokenResponse({ accessToken });
      } catch (e) {
        // accessToken is invalid. Skip to redirect to remove it.
      }
    }

    throw new RedirectException({ ...location, hash: '' });
  }

  async *resolveElements(match: Match) {
    const { location, routes } = match;

    const token = this.getAccessTokenFromHash(location, routes);
    if (token) {
      await this.loginFromHashToken(location, token);
    }

    if (!this.auth.tokenState && !isPublicRoute(routes)) {
      throw new HttpError(401);
    }

    yield* RelayResolver.prototype.resolveElements.call(this, match);
  }
}

const ConnectedRouter = createConnectedRouter({
  render,
});

// eslint-disable-next-line @typescript-eslint/camelcase, no-underscore-dangle
declare let __webpack_public_path__: string;

async function loadTranslations() {
  const locale = getClosestLocale(window.bflyConfig.AVAILABLE_LOCALES);

  if (locale === DEFAULT) {
    return [DEFAULT, null];
  }
  // eslint-disable-next-line @typescript-eslint/camelcase
  const url = `${window.location.origin}${__webpack_public_path__}messages.${locale}.json`;

  const resp = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!resp.ok) return [locale, null];
  const json = await resp.json();

  return [locale, json];
}

interface Props {
  locale: string;
  messages: any;
}

function Application({ locale, messages }: Props) {
  const launchDarkly = React.useMemo(() => new LaunchDarklyManager(), []);

  return (
    <IntlProvider locale={locale} messages={messages}>
      <DialogProvider>
        <AuthProvider>
          {auth => (
            <Provider store={store}>
              <ToastProvider>
                <ConnectedRouter
                  resolver={new Resolver(auth)}
                  matchContext={{
                    environment: auth.environment,
                    launchDarkly,
                  }}
                />
                <ToastContainer dismissAfter={5000} />
              </ToastProvider>
            </Provider>
          )}
        </AuthProvider>
      </DialogProvider>
    </IntlProvider>
  );
}

Promise.all([loadTranslations(), polyfills]).then(([[locale, messages]]) => {
  ReactDOM.render(
    <Application locale={locale} messages={messages} />,
    document.getElementById('root'),
  );
});
