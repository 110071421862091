/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import classNames from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import Layout from '@4c/layout';
import CineIcon from '@bfly/icons/Cine';
import Text from '@bfly/ui/Text';

import { RootState } from '../store';
import { Capture } from '../utils/capture';
import Duration from './Duration';

import utilsStyles from '../styles/utils.module.scss';

const styles = require('./ExamReelThumbnail-styles.module.scss');

interface Props {
  item: Capture;
}

function ExamReelThumbnail({ item }: Props) {
  const isVideo = item.type === 'cine';
  const fixColor = useSelector(
    (s: RootState) => !s.session.heliosCapabilities.correctProbeFeedColor,
  );

  return (
    <div
      css={[require("./ExamReelThumbnail-CssProp1_div.module.scss"), []]}
    >
      {isVideo ? (
        <video
          src={item.contents}
          className={classNames(
            styles.image,
            fixColor && utilsStyles.fixColor,
          )}
        />
      ) : (
        <img
          src={item.contents}
          alt="An ultasound cine frame"
          className={classNames(
            styles.image,
            fixColor && utilsStyles.fixColor,
          )}
        />
      )}
      {item.type === 'cine' && (
        <Layout
          align="center"
          justify="space-between"
          css={[require("./ExamReelThumbnail-CssProp2_Layout.module.scss"), []]}
        >
          <Text color="headline" variant="xs">
            <Duration seconds={item.duration} />
          </Text>
          <CineIcon
            css={[require("./ExamReelThumbnail-CssProp3_CineIcon.module.scss"), []]}
          />
        </Layout>
      )}
    </div>
  );
}

export default ExamReelThumbnail;
