/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CrossOrgConnectionItem_viewer = {
    readonly id: string;
    readonly profile: {
        readonly id: string;
    } | null;
    readonly " $refType": "CrossOrgConnectionItem_viewer";
};
export type CrossOrgConnectionItem_viewer$data = CrossOrgConnectionItem_viewer;
export type CrossOrgConnectionItem_viewer$key = {
    readonly " $data"?: CrossOrgConnectionItem_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"CrossOrgConnectionItem_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CrossOrgConnectionItem_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "profile",
      "storageKey": null,
      "args": null,
      "concreteType": "UserProfile",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    }
  ]
};
})();
(node as any).hash = '08e35ee31982528f82e912959b07409b';
export default node;
