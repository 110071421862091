const userAgent =
  (typeof navigator !== 'undefined' && navigator && navigator.userAgent) || '';

// Whitelist valid user agents that can login by directly passing
// an access_token hash to the URL.
export const APP_USER_AGENT_REGEX = /ButterflyIQ/i;

/**
 * The mobile app opens the education portal within an WebView and
 * applies custom user agent. Design differs slightly between a regular
 * mobile user and a user within the app so we need to differentiate.
 */
export default function isInApp() {
  return userAgent && APP_USER_AGENT_REGEX.test(userAgent);
}
