/* eslint-disable */
/* prettier-ignore-start */
// [GENERATED FILE; DO NOT EDIT BY HAND]

import React from "react"

var element = React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "currentColor",
  "aria-hidden": "true"
}, React.createElement("path", {
  d: "M1.61 5.15L15 5.23v1.56a.49.49 0 00.1.3.51.51 0 00.7.1l3.3-2.44.1-.1a.51.51 0 00-.1-.7l-3.26-2.48a.45.45 0 00-.3-.1.49.49 0 00-.5.49v1.62L1.62 3.4a.88.88 0 000 1.75zm16.82 9.7L5 14.76v-1.55a.5.5 0 00-.5-.5.54.54 0 00-.3.1L.89 15.25a.51.51 0 00-.1.7l.1.1 3.27 2.48a.5.5 0 00.7-.09.54.54 0 00.1-.3v-1.63l13.45.09a.89.89 0 00.88-.87.87.87 0 00-.86-.88z",
  fillRule: "evenodd"
}), React.createElement("path", {
  fill: "none",
  d: "M-802.81-411.86h1677v833h-1677z"
}), React.createElement("path", {
  fill: "none",
  d: "M-802.81-411.86h1677v833h-1677z"
}), React.createElement("path", {
  fill: "none",
  d: "M-802.81-453.47h1677v833h-1677z"
}), React.createElement("path", {
  fill: "none",
  d: "M-802.81-453.47h1677v833h-1677z"
}));

var Svg = React.forwardRef(function (props, ref) {
  var next = { ref: ref };
  for (var key in props) {
    if (props.hasOwnProperty(key)) {
      next[key] = props[key];
    }
  }
  return React.cloneElement(element, next);
});
Svg.displayName = "Move";
Svg.element = element;
export default Svg;
