/* eslint-disable */
/* prettier-ignore-start */
// [GENERATED FILE; DO NOT EDIT BY HAND]

import React from "react"

var element = React.createElement("svg", {
  viewBox: "0 0 47 48",
  fill: "currentColor",
  "aria-hidden": "true"
}, React.createElement("g", {
  fill: "none",
  fillRule: "evenodd"
}, React.createElement("path", {
  d: "M-1 0h48v48H-1z"
}), React.createElement("path", {
  stroke: "var(--icon-color, currentColor)",
  d: "M6.279.714c-1.883 0-2.647.148-3.436.57a3.736 3.736 0 00-1.559 1.559c-.422.789-.57 1.553-.57 3.436v31.156c0 1.883.148 2.647.57 3.437.36.673.885 1.198 1.559 1.558.789.422 1.553.57 3.436.57H29.72c1.883 0 2.647-.148 3.436-.57a3.736 3.736 0 001.559-1.558c.422-.79.57-1.554.57-3.437V6.28c0-1.883-.148-2.647-.57-3.436a3.736 3.736 0 00-1.559-1.559c-.789-.422-1.553-.57-3.436-.57H6.28z",
  strokeWidth: "1.429"
}), React.createElement("path", {
  fill: "var(--icon-color, currentColor)",
  d: "M9.055 8.571h5.89c.764 0 1.042.08 1.32.23.28.149.5.368.649.647.15.28.229.557.229 1.321v3.32c0 .763-.08 1.04-.23 1.32-.149.28-.368.498-.647.648-.28.15-.557.229-1.32.229H9.054c-.764 0-1.042-.08-1.32-.23a1.558 1.558 0 01-.649-.647c-.15-.28-.229-.557-.229-1.32v-3.32c0-.764.08-1.041.23-1.32.149-.28.368-.5.647-.649.28-.149.557-.229 1.32-.229z"
}), React.createElement("rect", {
  width: "22.286",
  height: "1.714",
  x: "6.857",
  y: "19.714",
  fill: "#BFBFBF",
  rx: ".857"
}), React.createElement("rect", {
  width: "7.714",
  height: "1.714",
  x: "21.429",
  y: "9",
  fill: "#BFBFBF",
  rx: ".857"
}), React.createElement("rect", {
  width: "7.714",
  height: "1.714",
  x: "21.429",
  y: "14.143",
  fill: "#BFBFBF",
  rx: ".857"
}), React.createElement("rect", {
  width: "22.286",
  height: "1.714",
  x: "6.857",
  y: "25.714",
  fill: "#BFBFBF",
  rx: ".857"
}), React.createElement("rect", {
  width: "22.286",
  height: "1.714",
  x: "6.857",
  y: "31.714",
  fill: "#BFBFBF",
  rx: ".857"
}), React.createElement("g", {
  transform: "translate(25.714 26.571)"
}, React.createElement("circle", {
  cx: "10.286",
  cy: "10.286",
  r: "10.286",
  fill: "#BFBFBF"
}), React.createElement("path", {
  fill: "#27272A",
  d: "M7.763 12.43l.148.145c.05.05.095.098.146.148 2.706 2.605 5.143 3.414 6.903 1.76.27-.246.304-.669.372-1.127.034-.246 0-.387-.203-.527-.029-.03-1.468-.87-1.905-1.124a.46.46 0 00-.354-.065c-.375.108-.696.642-.922.907-.203.352-.474.422-.745.247a10.878 10.878 0 01-1.962-1.548 10.87 10.87 0 01-1.548-1.963c-.176-.271-.106-.542.246-.745.265-.226.8-.546.907-.922a.463.463 0 00-.064-.354c-.255-.437-1.095-1.876-1.125-1.905-.14-.203-.28-.237-.528-.203-.457.068-.88.102-1.126.373-1.654 1.76-.845 4.195 1.76 6.903"
}))));

var Svg = React.forwardRef(function (props, ref) {
  var next = { ref: ref };
  for (var key in props) {
    if (props.hasOwnProperty(key)) {
      next[key] = props[key];
    }
  }
  return React.cloneElement(element, next);
});
Svg.displayName = "Guide";
Svg.element = element;
export default Svg;
