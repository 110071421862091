/* tslint:disable */
/* eslint-disable */
/* @relayHash 35e2b4db328214c16c710b006ac7f4e4 */

import { ConcreteRequest } from "relay-runtime";
export type MarkExperiencedInput = {
    key: string;
    clientMutationId?: string | null;
};
export type DashboardPageMarkExperiencedMutationVariables = {
    input: MarkExperiencedInput;
    key: string;
};
export type DashboardPageMarkExperiencedMutationResponse = {
    readonly markExperienced: {
        readonly viewer: {
            readonly id: string;
            readonly didExperience: boolean | null;
        } | null;
    } | null;
};
export type DashboardPageMarkExperiencedMutation = {
    readonly response: DashboardPageMarkExperiencedMutationResponse;
    readonly variables: DashboardPageMarkExperiencedMutationVariables;
};



/*
mutation DashboardPageMarkExperiencedMutation(
  $input: MarkExperiencedInput!
  $key: String!
) {
  markExperienced(input: $input) {
    viewer {
      id
      didExperience(key: $key)
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "MarkExperiencedInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "key",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "markExperienced",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MarkExperiencedPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "didExperience",
            "args": [
              {
                "kind": "Variable",
                "name": "key",
                "variableName": "key"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DashboardPageMarkExperiencedMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DashboardPageMarkExperiencedMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DashboardPageMarkExperiencedMutation",
    "id": null,
    "text": "mutation DashboardPageMarkExperiencedMutation(\n  $input: MarkExperiencedInput!\n  $key: String!\n) {\n  markExperienced(input: $input) {\n    viewer {\n      id\n      didExperience(key: $key)\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '08eb0d41feb4d9376791b180fbe6d6b0';
export default node;
