// Radians that by convention are always between positive 0 and 2*Pi.
export type NormalizedRadians = number;

export function normalizeRadians(angle: number): NormalizedRadians {
  let mod = angle % (2 * Math.PI);
  if (mod < 0) mod += 2 * Math.PI;
  return mod;
}

// Generic type for rotate, tilt, and rock.
export interface TiltCommand {
  type: VisualCommandType.TILT;

  // Angle towards which the probe tail should tilt. 0 means rock towards
  // the indicator dot.
  angle: NormalizedRadians;
}

export interface RotateClockwiseCommand {
  type: VisualCommandType.ROTATE_CLOCKWISE;
}

export interface RotateCounterclockwiseCommand {
  type: VisualCommandType.ROTATE_COUNTERCLOCKWISE;
}

export interface TranslateCommand {
  type: VisualCommandType.TRANSLATE;
  angle: NormalizedRadians;
}

export interface HoldPositionCommand {
  type: VisualCommandType.HOLD_POSITION;
}

export enum VisualCommandType {
  TRANSLATE = 'translate',
  ROTATE_CLOCKWISE = 'rotateClockwise',
  ROTATE_COUNTERCLOCKWISE = 'rotateCounterClockwise',
  TILT = 'tilt',
  HOLD_POSITION = 'holdPosition',
}

export type VisualCommand =
  | TiltCommand
  | RotateClockwiseCommand
  | RotateCounterclockwiseCommand
  | TranslateCommand
  | HoldPositionCommand;
