/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NavbarDropdown_viewer = {
    readonly id: string;
    readonly profile: {
        readonly id: string;
        readonly name: string | null;
        readonly " $fragmentRefs": FragmentRefs<"Avatar_profile">;
    } | null;
    readonly " $refType": "NavbarDropdown_viewer";
};
export type NavbarDropdown_viewer$data = NavbarDropdown_viewer;
export type NavbarDropdown_viewer$key = {
    readonly " $data"?: NavbarDropdown_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"NavbarDropdown_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "NavbarDropdown_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "profile",
      "storageKey": null,
      "args": null,
      "concreteType": "UserProfile",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "Avatar_profile",
          "args": null
        }
      ]
    }
  ]
};
})();
(node as any).hash = '4e37618bd77037edc9bd85769d9ae21a';
export default node;
