/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import React from 'react';

import ExpertVideo from './GettingStartedExpertVideo';

function NoviceVideo({
  src,
  className,
  poster,
  name,
}: React.ComponentProps<typeof ExpertVideo>) {
  return (
    <ExpertVideo
      src={src}
      className={className}
      poster={poster}
      name={name}
      css={[require("./GettingStartedNoviceVideo-CssProp1_ExpertVideo.module.scss"), []]}
    />
  );
}

export default NoviceVideo;
