export default class RenderLoop {
  private rafHandle: number | null = null;

  constructor(
    private readonly onTick: (timeDelta: DOMHighResTimeStamp) => void,
  ) {}

  start() {
    let last = window.performance.now();

    const tick = (now: DOMHighResTimeStamp) => {
      const dt = (now - last) / 1000; // duration in seconds

      this.onTick(dt);

      last = now;
      this.rafHandle = requestAnimationFrame(tick);
    };

    this.rafHandle = requestAnimationFrame(tick);
  }

  stop() {
    if (this.rafHandle) cancelAnimationFrame(this.rafHandle);
  }
}
