/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import Layout from '@4c/layout';
import Button from '@bfly/ui/Button';
import Text from '@bfly/ui/Text';

import DashboardDialog from './DashboardDialog';
import { RecordingConsentDialog_session as Session } from './__generated__/RecordingConsentDialog_session.graphql';

const styles = require('./RecordingConsentDialog-styles.module.scss');

function RecordingConsentDialog({
  session,
  acceptSession,
}: {
  session: Session;
  acceptSession: (allowRecording: boolean) => void;
}) {
  return (
    <DashboardDialog align="center" justify="center" show>
      <Layout
        css={[require("./RecordingConsentDialog-CssProp1_Layout.module.scss")]}
        className="p-6"
        align="center"
        justify="center"
        direction="column"
      >
        <Text align="center" color="body" className="mb-4">
          <FormattedMessage
            id="record.consent"
            defaultMessage="Can we record your session with {name} in order to
              improve Butterfly TeleGuidance? They have already confirmed that no
              patient is present and given their consent. If you change your
              mind, you will be able to delete it when the session is over."
            values={{ name: session.novice!.name }}
          />
        </Text>
        <Text align="center" color="body">
          <FormattedMessage
            id="record.shareScreen"
            defaultMessage=" You will be asked to share your screen as part of the recording."
          />
        </Text>

        <Layout pad justify="space-evenly" className="mt-6">
          <Button
            className={styles.button}
            size="large"
            theme="secondary"
            onClick={() => acceptSession(false)}
          >
            <FormattedMessage
              id="record.decline"
              defaultMessage="Do Not Record"
            />
          </Button>
          <Button
            className={styles.button}
            size="large"
            theme="primary"
            onClick={() => acceptSession(true)}
          >
            <FormattedMessage id="record.allow" defaultMessage="Record" />
          </Button>
        </Layout>
      </Layout>
    </DashboardDialog>
  );
}

export default createFragmentContainer(RecordingConsentDialog, {
  session: graphql`
    fragment RecordingConsentDialog_session on TelemedSession {
      id
      novice {
        name
      }
    }
  `,
});
