/* tslint:disable */
/* eslint-disable */
/* @relayHash ff8f2053805c6af954aafa90a0b627e6 */

import { ConcreteRequest } from "relay-runtime";
export type TelemedAvailabilitySetting = "AVAILABLE" | "OFFLINE" | "%future added value";
export type SetTelemedAvailabilityInput = {
    organizationId: unknown;
    availability: TelemedAvailabilitySetting;
    clientMutationId?: string | null;
};
export type DashboardPage_SetTelemedAvailabilityMutationVariables = {
    input: SetTelemedAvailabilityInput;
};
export type DashboardPage_SetTelemedAvailabilityMutationResponse = {
    readonly setTelemedAvailability: {
        readonly __typename: string;
    } | null;
};
export type DashboardPage_SetTelemedAvailabilityMutation = {
    readonly response: DashboardPage_SetTelemedAvailabilityMutationResponse;
    readonly variables: DashboardPage_SetTelemedAvailabilityMutationVariables;
};



/*
mutation DashboardPage_SetTelemedAvailabilityMutation(
  $input: SetTelemedAvailabilityInput!
) {
  setTelemedAvailability(input: $input) {
    __typename
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SetTelemedAvailabilityInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "setTelemedAvailability",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetTelemedAvailabilityPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "__typename",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DashboardPage_SetTelemedAvailabilityMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DashboardPage_SetTelemedAvailabilityMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DashboardPage_SetTelemedAvailabilityMutation",
    "id": null,
    "text": "mutation DashboardPage_SetTelemedAvailabilityMutation(\n  $input: SetTelemedAvailabilityInput!\n) {\n  setTelemedAvailability(input: $input) {\n    __typename\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'd47027837807ad2179003edab214b988';
export default node;
