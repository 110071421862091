import { useMemo } from 'react';
import useResizeObserver from '@restart/hooks/useResizeObserver';

import { Dimensions } from '../../types';
import contain from '../../utils/objectFitContain';
import { aspectRatio } from './mapTrackDimensions';

/**
 * uses the actual dimensions of the ref div and the aspect ratio of of the
 * passed in dimensions, to determine width and height that are contained in the
 * ref div. Functions like `object-fit: contain` for for anything.
 */
export default function useContainedDimensions(
  ref: HTMLDivElement | null,
  { width, height }: Dimensions,
): Dimensions | null {
  const rect = useResizeObserver(ref);
  return useMemo(() => {
    if (!rect || !width) return null;

    const aspect = aspectRatio({ width, height });

    return contain(rect.width, rect.height, aspect);
  }, [width, height, rect]);
}
