/* tslint:disable */
/* eslint-disable */
/* @relayHash 2cf9bec0629033276a0e2cece23f49ab */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TelemedSessionRequestedSubscriptionInput = {
    expertId?: unknown | null;
    organizationId?: unknown | null;
    userId?: unknown | null;
    clientSubscriptionId?: string | null;
};
export type DashboardPage_SubscriptionVariables = {
    input: TelemedSessionRequestedSubscriptionInput;
};
export type DashboardPage_SubscriptionResponse = {
    readonly telemedSessionRequested: {
        readonly telemedSessionEdge: {
            readonly node: {
                readonly id: string;
                readonly noviceAllowsRecording: boolean | null;
                readonly " $fragmentRefs": FragmentRefs<"IncomingRequest_session">;
            } | null;
        } | null;
    } | null;
};
export type DashboardPage_Subscription = {
    readonly response: DashboardPage_SubscriptionResponse;
    readonly variables: DashboardPage_SubscriptionVariables;
};



/*
subscription DashboardPage_Subscription(
  $input: TelemedSessionRequestedSubscriptionInput!
) {
  telemedSessionRequested(input: $input) {
    telemedSessionEdge {
      node {
        id
        noviceAllowsRecording
        ...IncomingRequest_session
      }
    }
  }
}

fragment Avatar_profile on UserProfile {
  name
  avatar {
    thumbnails {
      width
      url
    }
  }
}

fragment IncomingRequest_session on TelemedSession {
  id
  createdAt
  noviceAllowsRecording
  organization {
    name
    id
  }
  novice {
    id
    name
    avatar {
      thumbnail(width: 128) {
        url
      }
    }
    ...Avatar_profile
  }
  ...RecordingConsentDialog_session
}

fragment RecordingConsentDialog_session on TelemedSession {
  id
  novice {
    name
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "TelemedSessionRequestedSubscriptionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "noviceAllowsRecording",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DashboardPage_Subscription",
    "type": "Subscription",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "telemedSessionRequested",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "TelemedSessionRequestedSubscriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "telemedSessionEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "TelemedSessionEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "TelemedSession",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "IncomingRequest_session",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DashboardPage_Subscription",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "telemedSessionRequested",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "TelemedSessionRequestedSubscriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "telemedSessionEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "TelemedSessionEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "TelemedSession",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "createdAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "organization",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Organization",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "novice",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserProfile",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "avatar",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Avatar",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "thumbnail",
                            "storageKey": "thumbnail(width:128)",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "width",
                                "value": 128
                              }
                            ],
                            "concreteType": "Thumbnail",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "thumbnails",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Thumbnail",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "width",
                                "args": null,
                                "storageKey": null
                              },
                              (v5/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "subscription",
    "name": "DashboardPage_Subscription",
    "id": null,
    "text": "subscription DashboardPage_Subscription(\n  $input: TelemedSessionRequestedSubscriptionInput!\n) {\n  telemedSessionRequested(input: $input) {\n    telemedSessionEdge {\n      node {\n        id\n        noviceAllowsRecording\n        ...IncomingRequest_session\n      }\n    }\n  }\n}\n\nfragment Avatar_profile on UserProfile {\n  name\n  avatar {\n    thumbnails {\n      width\n      url\n    }\n  }\n}\n\nfragment IncomingRequest_session on TelemedSession {\n  id\n  createdAt\n  noviceAllowsRecording\n  organization {\n    name\n    id\n  }\n  novice {\n    id\n    name\n    avatar {\n      thumbnail(width: 128) {\n        url\n      }\n    }\n    ...Avatar_profile\n  }\n  ...RecordingConsentDialog_session\n}\n\nfragment RecordingConsentDialog_session on TelemedSession {\n  id\n  novice {\n    name\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'b66c33ee89c1c2923baebd72c2ab9319';
export default node;
