import { VideoTrack } from 'twilio-video';

import { Capture } from '../utils/capture';

const FRAME_INTERVAL_MS = 1000 / 30;

export default class VideoRecorder {
  private status: 'recording' | 'postprocessing' | 'stopped' = 'stopped';

  private recorder: MediaRecorder | null = null;

  private dimensions: VideoTrack.Dimensions;

  private stream: MediaStream;

  private virtualVideo: HTMLVideoElement;

  private timestamp?: number;

  frameLoop(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
    if (this.status !== 'recording') {
      this.virtualVideo.pause();

      // Clear this out so that a lingering frame from this recording doesn't
      // show up in the next one.
      this.virtualVideo.srcObject = null;
      return;
    }

    const startTime = Date.now();
    context.drawImage(this.virtualVideo, 0, 0, canvas.width, canvas.height);
    window.setTimeout(
      () => this.frameLoop(canvas, context),
      Math.max(FRAME_INTERVAL_MS - (Date.now() - startTime), 0),
    );
  }

  constructor(track: VideoTrack) {
    this.dimensions = track.dimensions;
    track.on('dimensionsChanged', t => {
      // TODO: Update the canvas? What happens if this happens during recording?
      this.dimensions = t.dimensions;
      this.virtualVideo.width = t.dimensions.width;
      this.virtualVideo.height = t.dimensions.height;
    });
    this.stream = new MediaStream([track.mediaStreamTrack]);
    this.virtualVideo = document.createElement('video');
    this.virtualVideo.width = this.dimensions.width || 0;
    this.virtualVideo.height = this.dimensions.height || 0;
  }

  start() {
    if (
      this.status !== 'stopped' ||
      !this.dimensions.width ||
      !this.dimensions.height
    )
      return;

    this.status = 'recording';
    this.timestamp = Date.now();

    console.log('recording started');

    // You'd think that MediaRecorder could just accept the media stream
    // directly, but Chrome only can play the first and last frame in the videos
    // created this way. So instead we draw to a canvas and record that stream.
    const canvas = document.createElement('canvas');
    canvas.width = this.dimensions.width;
    canvas.height = this.dimensions.height;
    const context = canvas.getContext('2d')!;

    this.virtualVideo.srcObject = this.stream;
    this.virtualVideo.play();
    this.frameLoop(canvas, context);

    this.recorder = new MediaRecorder((canvas as any).captureStream(), {
      mimeType: 'video/webm',
    });
    this.recorder.onerror = (e: MediaRecorderErrorEvent) =>
      console.log(`recording error: ${e.error.message}`);
    this.recorder.start();
  }

  stop(): Promise<Capture | null> {
    console.log('recording stopped');
    if (this.status !== 'recording' || !this.recorder)
      return Promise.resolve(null);

    this.status = 'postprocessing';
    this.recorder.stop();
    const duration = (Date.now() - this.timestamp!) / 1000;

    return new Promise<Capture | null>(resolve => {
      // Since no timeslice was provided in recorder.start(), it fires just a
      // single dataavailable event after stop() is called.
      const handleDataAvailable = (e: BlobEvent) => {
        console.log('recording completed');

        resolve({
          type: 'cine',
          duration,
          contents: window.URL.createObjectURL(e.data),
        });

        this.status = 'stopped';

        this.recorder!.removeEventListener(
          'dataavailable',
          handleDataAvailable,
        );
      };
      this.recorder!.addEventListener('dataavailable', handleDataAvailable);
    });
  }

  cancel() {
    if (!this.recorder || this.status !== 'recording') return;

    console.log('recording canceled');
    this.recorder.stop();
    this.status = 'stopped';
  }
}
