/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import Layout from '@4c/layout';
import Spinner from '@bfly/ui/Spinner';
import Text from '@bfly/ui/Text';

import { RootState } from '../../store';

interface Props {
  previewing?: boolean;
}

function HelpText({ previewing }: Props) {
  const activeCommand = useSelector(
    (s: RootState) => s.commands.activeCommand,
  );
  const probeUnavailable = useSelector(
    (s: RootState) => s.media.probeUnavailable,
  );

  let message;
  if (probeUnavailable) {
    message = (
      <FormattedMessage
        id="arrows.helpText.probeNotVisible"
        defaultMessage="Paused"
      />
    );
  } else if (activeCommand) {
    message = (
      <Layout pad={2} align="center" inline>
        <FormattedMessage
          tagName="span"
          id="arrows.helpText.sent"
          defaultMessage="Instruction sent…"
        />
        <Spinner css={[require("./HelpText-CssProp1_Spinner.module.scss")]} />
      </Layout>
    );
  } else if (previewing) {
    message = (
      <FormattedMessage
        id="arrows.helpText.activate"
        defaultMessage="Click to send"
      />
    );
  } else {
    message = (
      <FormattedMessage
        id="arrows.helpText.Preview"
        defaultMessage="Hover to preview"
      />
    );
  }

  return (
    <Text align="center" variant="sm" className="d-block">
      {message}
    </Text>
  );
}

export default HelpText;
