import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '@4c/layout';
import Card from '@bfly/ui/Card';
import Text from '@bfly/ui/Text';

import { ringtoneToggled } from '../actions/media';
import Off from '../icons/RingtoneOff';
import On from '../icons/RingtoneOn';
import { RootState } from '../store';
import IconButton from './IconButton';

interface Props {
  className?: string;
  disabled: boolean;
}

function RingtonePreference({ className, disabled }: Props) {
  const enabled = useSelector((s: RootState) => s.media.ringtoneEnabled);
  const dispatch = useDispatch();

  return (
    <Card className={className}>
      <Card.Body className="py-4 px-6">
        <Layout justify="space-between" align="center">
          <Text variant="body-bold" color="headline">
            {enabled ? (
              <FormattedMessage
                id="ringtonePreference.on"
                defaultMessage="Ringtone on"
              />
            ) : (
              <FormattedMessage
                id="ringtonePreference.off"
                defaultMessage="Ringtone off"
              />
            )}
          </Text>
          <IconButton
            disabled={disabled}
            onClick={() => dispatch(ringtoneToggled(!enabled))}
          >
            {enabled ? <On /> : <Off />}
          </IconButton>
        </Layout>
      </Card.Body>
    </Card>
  );
}

export default RingtonePreference;
