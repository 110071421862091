import { get, keys, set } from 'idb-keyval';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import { Object3D, ObjectLoader } from 'three';
import { Collada } from 'three/examples/jsm/loaders/ColladaLoader';

import probeModel from '../assets/probe-scene-v1.dae';
import { SceneObjectMap, SceneObjectName } from './sceneObjects';

// import { GLTF } from 'three/examples/jsm/loaders/ColladaLoader';

const KEY = '@telemed/probe-model-v1';
const VERSION_KEY = `${KEY}:version`;

let modelRequest: Promise<SceneObjectMap>;

async function loadCollada(): Promise<SceneObjectMap> {
  const { ColladaLoader } = await import(
    'three/examples/jsm/loaders/ColladaLoader'
  );

  return new Promise(resolve => {
    new ColladaLoader().load(probeModel, (model: Collada) => {
      const objects: Object3D[] = [];
      model.scene.traverse(c => {
        if (c.name in SceneObjectName) {
          objects.push(c);
        }
      });

      const objectMap = keyBy(
        objects,
        value => value.name as SceneObjectName,
      ) as SceneObjectMap;

      try {
        localStorage.setItem(VERSION_KEY, process.env.PROBE_MODEL_VERSION_V1!);
      } catch (err) {
        /* ignore */
      }
      set(
        KEY,
        mapValues(objectMap, v => v.toJSON()),
      ).then(() => {
        resolve(objectMap);
      });
    });
  });
}

const cacheIsStillValid = () => {
  try {
    return (
      localStorage.getItem(VERSION_KEY) === process.env.PROBE_MODEL_VERSION_V1
    );
  } catch (err) {
    return null;
  }
};

async function loadLocally(): Promise<SceneObjectMap> {
  const loader = new ObjectLoader();
  console.time('IndexDBLoader: Parse');
  const json = await get<Record<string, any>>(KEY);

  const result = mapValues(json, value => loader.parse(value)) as any;

  console.timeEnd('IndexDBLoader: Parse');
  return result;
}

async function loadModelImpl(): Promise<SceneObjectMap> {
  const hasCached = cacheIsStillValid() && (await keys()).includes(KEY);

  return hasCached ? loadLocally() : loadCollada();
}

export default function loadModel() {
  if (!modelRequest) modelRequest = loadModelImpl();
  return modelRequest;
}
