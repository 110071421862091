/* eslint-disable @typescript-eslint/camelcase */
import { Observable, combineLatest, merge, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  flatMap,
  map,
  mapTo,
  skipWhile,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { tag } from 'rxjs-spy/operators';
import { LocalDataTrack, RemoteDataTrack } from 'twilio-video';
import {
  HeliosEvent,
  IEmpty,
  IInstruction,
  Instruction,
  ProtoValueWithDefaults,
} from '@bfly/telemed-interchange';
import { RAD2DEG } from '@bfly/utils/math';

import { getType as t } from 'typesafe-actions';
import {
  batteryStateChanged,
  captureSaved,
  cineCancelRequested,
  cineStartRequested,
  cineStopRequested,
  depthChangeRequested,
  depthChanged,
  freezeChangeRequested,
  freezeChanged,
  gainChangeRequested,
  gainChanged,
  modeChangeRequestedToHelios,
  presetChangeRequested,
  probeStateChanged,
  startedCine,
  stillCaptureRequested,
  stoppedCine,
  temperatureStateChanged,
} from '../actions/imaging';
import {
  instructionActivated,
  markerPointDrawn,
  poseUpdated,
} from '../actions/instruction';
import {
  cameraDimensionsUpdated,
  cameraModeChanged,
  currentSettingsUpdated,
  probeDimensionsUpdated,
} from '../actions/session';
import { VisualCommand, VisualCommandType } from '../Instruction';
import { RootAction, RootState } from '../store';
import StoreSubject from '../store/StoreSubject';
import { Capture, captureProbeStill } from '../utils/capture';
import select from '../utils/select';
import { callEnded } from '../utils/selectors';
import HeliosDataService, { ParsedHeliosEvent } from './rpc';
import VideoRecorder from './VideoRecorder';

type Store = StoreSubject<RootState, RootAction>;

const EMPTY_MESSAGE: IEmpty = {};

type HeliosEventTypes = NonNullable<HeliosEvent['event']>;

const pluckType = <K extends HeliosEventTypes>(type: K) => (
  src: Observable<ParsedHeliosEvent>,
) =>
  src.pipe(
    filter(value => type === value.event && value[type] != null),
    map(e => e[type]!),
  );

function makeInstruction(
  command: VisualCommand,
  multiAxisTilts: boolean,
): IInstruction | null {
  let instruction = null;
  switch (command.type) {
    case VisualCommandType.TRANSLATE:
      instruction = {
        translate: {
          // Degrees is legacy, pre-1.15
          degrees: RAD2DEG * command.angle,
          direction: command.angle,
        },
      };
      break;
    case VisualCommandType.ROTATE_COUNTERCLOCKWISE:
      instruction = {
        rotateCounterClockwise: EMPTY_MESSAGE,
      };
      break;
    case VisualCommandType.ROTATE_CLOCKWISE:
      instruction = {
        rotateClockwise: EMPTY_MESSAGE,
      };
      break;
    case VisualCommandType.TILT:
      if (multiAxisTilts) {
        instruction = {
          tilt: {
            direction: command.angle,
          },
        };
      } else if (command.angle < Math.PI) {
        instruction = {
          tiltHeadUp: EMPTY_MESSAGE,
        };
      } else {
        instruction = {
          tiltHeadDown: EMPTY_MESSAGE,
        };
      }
      break;
    case VisualCommandType.HOLD_POSITION:
    default:
      instruction = {
        holdPosition: EMPTY_MESSAGE,
      };
      break;
  }
  return instruction;
}

function dispatchToHelios(
  store$: Store,
  action: RootAction,
  service: HeliosDataService,
) {
  switch (action.type) {
    // cine/still capture only fires after confirmation from helios -- handled by service.receiveMessage
    case t(markerPointDrawn):
      service.send('showMarker', action.payload);
      break;
    case t(cineStartRequested):
      service.send('startCaptureCine');
      break;
    case t(cineStopRequested):
      service.send('stopCaptureCine');
      break;
    case t(cineCancelRequested):
      service.send('cancelCaptureCine');
      break;
    case t(stillCaptureRequested):
      service.send('captureStill');
      break;
    case t(freezeChangeRequested):
      service.send('setFreeze', { value: action.payload });
      break;
    case t(gainChangeRequested):
      service.send('changeGain', action.payload);
      break;
    case t(depthChangeRequested):
      service.send('changeDepth', action.payload);
      break;
    case t(modeChangeRequestedToHelios):
      service.send('changeMode', { value: action.payload });
      break;
    case t(presetChangeRequested):
      service.send('changePreset', { value: action.payload });
      break;
    case t(instructionActivated): {
      const instruction = makeInstruction(
        action.payload.command,
        store$.value.session.heliosCapabilities.multiAxisTilts,
      );
      service.send(
        'sendInstruction',
        new Instruction({ ...instruction, durationSeconds: 3 }),
      );
      break;
    }
    default:
      break;
  }
}

function mapIncomingMessagesToActions(
  messages$: HeliosDataService['messages$'],
  store$: Store,
  probeRecorder$: Observable<VideoRecorder>,
): Observable<RootAction> {
  // We can't properly handle probe dimension changes while imaging is frozen,
  // so don't respond to them until imaging is unfrozen.
  const probeDimensionsWhenUnfrozen = combineLatest(
    messages$.pipe(
      pluckType('probeDimension'),
      filter(d => d != null),
    ),
    store$.pipe(select(s => s.imaging.frozen)),
  ).pipe(
    distinctUntilChanged(),
    filter(
      ([_, frozen]: [
        ProtoValueWithDefaults<HeliosEvent.IVideoDimension>,
        boolean,
      ]) => !frozen,
    ),
    map(
      ([dimensions, _]: [
        ProtoValueWithDefaults<HeliosEvent.IVideoDimension>,
        boolean,
      ]) => probeDimensionsUpdated(dimensions),
    ),
  );

  return merge(
    messages$.pipe(pluckType('currentSettings'), map(currentSettingsUpdated)),
    messages$.pipe(
      pluckType('pose'),
      distinctUntilChanged(),
      map(poseUpdated),
      tag('pose'),
    ),
    probeDimensionsWhenUnfrozen,
    messages$.pipe(pluckType('cameraDimension'), map(cameraDimensionsUpdated)),
    messages$.pipe(pluckType('gainChanged'), map(gainChanged)),
    messages$.pipe(pluckType('depthChanged'), map(depthChanged)),
    messages$.pipe(
      pluckType('freezeChanged'),
      map(m => freezeChanged(m.frozen)),
    ),
    messages$.pipe(
      pluckType('probeStateChanged'),
      map(m => probeStateChanged(m.value)),
    ),
    messages$.pipe(
      pluckType('temperatureStateChanged'),
      map(m => temperatureStateChanged(m.value)),
    ),
    messages$.pipe(
      pluckType('batteryStateChanged'),
      map(m => batteryStateChanged(m.value)),
    ),
    messages$.pipe(
      pluckType('cameraModeChanged'),
      map(m => cameraModeChanged(m.mode)),
    ),

    /**
     * Media events
     */
    messages$.pipe(
      pluckType('cineCaptureStarted'),
      withLatestFrom(probeRecorder$),
      tap(([, r]) => r.start()),
      mapTo(startedCine()),
    ),
    messages$.pipe(
      pluckType('cineCaptureCancelled'),
      withLatestFrom(probeRecorder$),
      tap(([, r]) => r.cancel()),
      mapTo(stoppedCine()),
    ),
    messages$.pipe(
      pluckType('cineCaptureStopped'),
      withLatestFrom(probeRecorder$),
      flatMap(([, r]) => r.stop()),
      filter((c): c is Capture => c != null),
      flatMap(capture => of(captureSaved(capture), stoppedCine())),
    ),
    messages$.pipe(
      pluckType('stillCaptured'),
      map(() => captureProbeStill()),
      filter((c): c is Capture => c != null),
      map(c => captureSaved(c)),
    ),
  );
}

// Sets up the connection so that:
// 1) Redux actions cause messages to be sent to Helios if they need to
// 2) Incoming messages from Helios trigger actions to fire
//
// All messages are fully handled within this file.
export function init(
  store$: Store,
  actions$: Observable<RootAction>,
  localData$: Observable<LocalDataTrack | null>,
  remoteData$: Observable<RemoteDataTrack | null>,
  // FIXME: Recording is a weird job for this file to do. Could do it via thunks
  // if the thunks know how to access the media stream.
  probeRecorder$: Observable<VideoRecorder>,
) {
  const service = new HeliosDataService(localData$, remoteData$);

  actions$
    .pipe(
      withLatestFrom(store$),
      skipWhile(
        ([, state]) => !state.session.connection.remoteDataTrackPublished,
      ),
      tag('actions'),
    )
    .subscribe(
      ([action]) => dispatchToHelios(store$, action, service),
      undefined,
      () => console.log('actions ended'),
    );

  mapIncomingMessagesToActions(service.messages$, store$, probeRecorder$)
    .pipe(takeUntil(callEnded(store$)))
    .subscribe(store$.dispatch, undefined, () =>
      console.log('dispatch ended'),
    );
}
