import sortBy from 'lodash/sortBy';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import CrossOrgConnectionItem from './CrossOrgConnectionItem';
import { CrossOrgConnectionList_crossOrgConnections as CrossOrgConnections } from './__generated__/CrossOrgConnectionList_crossOrgConnections.graphql';
import { CrossOrgConnectionList_viewer as Viewer } from './__generated__/CrossOrgConnectionList_viewer.graphql';

interface Props {
  viewer: Viewer;
  crossOrgConnections: CrossOrgConnections;
  showAll?: boolean;
}

function CrossOrgConnectionList({
  viewer,
  crossOrgConnections,
  showAll,
}: Props) {
  const sortedConnections = sortBy(crossOrgConnections, [
    crossOrgConnection => (crossOrgConnection.status === 'PENDING' ? 0 : 1),
    crossOrgConnection =>
      crossOrgConnection.recipientProfile!.id === viewer.profile!.id ? 0 : 1,
    crossOrgConnection =>
      crossOrgConnection.recipientProfile!.id === viewer.profile!.id
        ? crossOrgConnection!.senderProfile!.name!.toLowerCase()
        : crossOrgConnection!.recipientProfile!.name!.toLowerCase(),
  ]);

  const displayedConnections = showAll
    ? sortedConnections
    : sortedConnections.slice(0, 4);

  return (
    <>
      {displayedConnections.map(crossOrgConnection => (
        <CrossOrgConnectionItem
          key={crossOrgConnection.id}
          crossOrgConnection={crossOrgConnection}
          viewer={viewer}
        />
      ))}
    </>
  );
}

export default createFragmentContainer(CrossOrgConnectionList, {
  viewer: graphql`
    fragment CrossOrgConnectionList_viewer on Viewer {
      id
      profile {
        id
      }
      ...CrossOrgConnectionItem_viewer
    }
  `,
  crossOrgConnections: graphql`
    fragment CrossOrgConnectionList_crossOrgConnections on TelemedUserToUserPairing
      @relay(plural: true) {
      id
      status
      recipientProfile {
        id
        name
      }
      senderProfile {
        name
      }
      ...CrossOrgConnectionItem_crossOrgConnection
    }
  `,
});
