/* eslint-disable */
/* prettier-ignore-start */
// [GENERATED FILE; DO NOT EDIT BY HAND]

import React from "react"

var element = React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "currentColor",
  "aria-hidden": "true"
}, React.createElement("path", {
  d: "M19.82 13.82L18.91 11a.31.31 0 00-.06-.13.49.49 0 00-.69-.13l-2.44 1.65a.47.47 0 00-.19.25.5.5 0 00.31.64l1 .34a7.45 7.45 0 01-13.91-1.09.89.89 0 00-1.09-.6.88.88 0 00-.6 1.07 9.2 9.2 0 0017.24 1.2l.7.24a.44.44 0 00.32 0 .5.5 0 00.32-.62zM1.61 9.25l2.55-1.48a.5.5 0 000-.89l-.83-.35a7.45 7.45 0 0114 .94.88.88 0 00.84.63 1.07 1.07 0 00.25 0 .88.88 0 00.51-1.1A9.21 9.21 0 001.66 5.84l-.81-.35a.47.47 0 00-.31 0 .49.49 0 00-.37.6l.71 2.85a.69.69 0 00.05.13.5.5 0 00.68.18z",
  fillRule: "evenodd"
}), React.createElement("path", {
  fill: "none",
  d: "M-779.81-412.99h1677v833h-1677z"
}), React.createElement("path", {
  fill: "none",
  d: "M-779.81-412.99h1677v833h-1677z"
}), React.createElement("path", {
  fill: "none",
  d: "M-779.81-454.6h1677v833h-1677z"
}), React.createElement("path", {
  fill: "none",
  d: "M-779.81-454.6h1677v833h-1677z"
}));

var Svg = React.forwardRef(function (props, ref) {
  var next = { ref: ref };
  for (var key in props) {
    if (props.hasOwnProperty(key)) {
      next[key] = props[key];
    }
  }
  return React.cloneElement(element, next);
});
Svg.displayName = "Rotate";
Svg.element = element;
export default Svg;
