import { getType as t } from 'typesafe-actions';
import {
  currentSettingsUpdated,
  endCallClicked,
  noviceLeftTwilioRoom,
  receivedConnectionStats,
  remoteDataTrackConnected,
  serverStatusUpdated,
} from '../actions/session';
import { ConnectionStats } from '../Twilio/ConnectionStats';
import { TelemedSession } from '../types';
import { RootAction } from './index';

// Call status from the opinion of the server, mirroring TelemedSession.Status in GraphQL.
export enum SessionStatus {
  Unknown,

  Active,

  Completed,
}

export interface HeliosCapabilities {
  magicMarkerCamera: boolean;
  magicMarkerProbe: boolean;
  correctProbeFeedColor: boolean;
  multiAxisTilts: boolean;
  augmentedRealityOverlayVersion: number | null;
}

interface ConnectionState {
  // Whether we've seen the remote participant show up in Twilio and publish their data track.
  remoteDataTrackPublished: boolean;

  // The session status. This mirrors the authoritative status kept by the server, with one
  // exception: legacy Helios apps will fire a "disconnect" event on the data track without
  // telling the server, and this responds to that too.
  // TODO: Update comment once telemed-magic-marker builds are retired
  serverStatus: SessionStatus;

  // Whether the first currentSettings have been received by Helios
  receivedFirstHeliosSettings: boolean;

  // Whether the end of the call triggered by the local user
  endedByExpert: boolean;
}

export type CallState = {
  sessionInfo: TelemedSession | null;
  stats: ConnectionStats[];
  heliosCapabilities: HeliosCapabilities;
  connection: ConnectionState;
};

const initialState = {
  endedBy: null,
  sessionInfo: null,
  stats: [],
  heliosCapabilities: {
    magicMarkerProbe: false,
    magicMarkerCamera: false,
    correctProbeFeedColor: false,
    multiAxisTilts: false,
    augmentedRealityOverlayVersion: null,
  },
  connection: {
    serverStatus: SessionStatus.Unknown,
    remoteDataTrackPublished: false,
    receivedFirstHeliosSettings: false,
    endedByExpert: false,
  },
};

// Keep 10 minutes of frame rate history.
const MAX_STATS_DATA_POINTS = 60 * 10;

export default (state: CallState = initialState, action: RootAction) => {
  switch (action.type) {
    case t(receivedConnectionStats):
      return {
        ...state,
        stats: [
          ...state.stats.slice(-MAX_STATS_DATA_POINTS + 1),
          action.payload,
        ],
      };
    case t(currentSettingsUpdated):
      if (!action.payload || !action.payload.capabilities) return state;
      return {
        ...state,
        connection: { ...state.connection, receivedFirstHeliosSettings: true },
        heliosCapabilities: {
          magicMarkerCamera: action.payload.capabilities.magicMarkerCamera,
          magicMarkerProbe: action.payload.capabilities.magicMarkerProbe,
          correctProbeFeedColor:
            action.payload.capabilities.correctProbeFeedColor,

          multiAxisTilts: action.payload.capabilities.multiAxisTilts,
          augmentedRealityOverlayVersion:
            action.payload.capabilities.augmentedRealityOverlayVersion,
        },
      };
    case t(noviceLeftTwilioRoom):
      return {
        ...state,
        connection: {
          ...state.connection,
          remoteDataTrackPublished: false,
        },
      };
    case t(remoteDataTrackConnected):
      return {
        ...state,
        connection: {
          ...state.connection,
          remoteDataTrackPublished: true,
          remoteDataTrackWasEverConnected: true,
        },
      };
    case t(serverStatusUpdated):
      return {
        ...state,
        connection: {
          ...state.connection,
          serverStatus: action.payload,
        },
      };
    case t(endCallClicked):
      return {
        ...state,
        connection: {
          ...state.connection,
          endedByExpert: true,
        },
      };
    default:
      return state;
  }
};
