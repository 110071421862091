/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RecordingConsentDialog_session = {
    readonly id: string;
    readonly novice: {
        readonly name: string | null;
    } | null;
    readonly " $refType": "RecordingConsentDialog_session";
};
export type RecordingConsentDialog_session$data = RecordingConsentDialog_session;
export type RecordingConsentDialog_session$key = {
    readonly " $data"?: RecordingConsentDialog_session$data;
    readonly " $fragmentRefs": FragmentRefs<"RecordingConsentDialog_session">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "RecordingConsentDialog_session",
  "type": "TelemedSession",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "novice",
      "storageKey": null,
      "args": null,
      "concreteType": "UserProfile",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = 'e8ec3a069d54ce0c1c00bae9ac580c3c';
export default node;
