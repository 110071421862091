import { SyntheticEvent, useRef } from 'react';
import useMounted from '@restart/hooks/useMounted';

/**
 * Returns pointer handlers, that performantly track the position of the pointer
 * while `active`. Similar to the the annotation-tools version but withotu the local transform
 * adjustments of the event coordinates.
 */
export default function useThrottledPointerMove<TEvent extends SyntheticEvent>(
  isActive: (event: TEvent) => boolean,
  onMove: (event: TEvent['nativeEvent']) => void,
): [(e: TEvent) => void, () => void] {
  const isMounted = useMounted();

  const nextPointRef = useRef<{ event: Event | null; handle: null | number }>({
    event: null,
    handle: null,
  });

  const cancel = () => {
    cancelAnimationFrame(nextPointRef.current.handle as any);
    nextPointRef.current.handle = null;
  };

  const handlePointerMoveAnimation = () => {
    const { current: next } = nextPointRef;

    if (next.handle && next.event) {
      next.handle = null;
      onMove(next.event);
    }
    next.event = null;
  };

  const handlePointerMove = (event: TEvent) => {
    if (!isActive(event) || !isMounted()) return;

    nextPointRef.current.event = event.nativeEvent;
    if (!nextPointRef.current.handle) {
      nextPointRef.current.handle = requestAnimationFrame(
        handlePointerMoveAnimation,
      );
    }
  };

  return [handlePointerMove, cancel];
}
