import {
  CameraMode,
  HeliosEvent,
  ProtoValueWithDefaults,
} from '@bfly/telemed-interchange';
import { DEG2RAD, RAD2DEG } from '@bfly/utils/math';

import { getType as t } from 'typesafe-actions';
import { poseUpdated } from '../actions/instruction';
import { cameraModeChanged, currentSettingsUpdated } from '../actions/session';
import { RootAction } from './index';

export type PoseState = {
  stale: boolean;
  fieldOfView: number;
  cameraMode: CameraMode;
  estimation: ProtoValueWithDefaults<HeliosEvent.Pose.IEstimation> | null;
};

const initialState = {
  stale: true,
  fieldOfView: 58,
  estimation: null,
  cameraMode: CameraMode.FRONT,
};

export default (state: PoseState = initialState, action: RootAction) => {
  switch (action.type) {
    case t(poseUpdated): {
      const stale =
        action.payload.stale != null
          ? action.payload.stale
          : !action.payload.estimation;

      return { ...state, ...action.payload, stale };
    }
    case t(cameraModeChanged):
      return { ...state, cameraMode: action.payload };
    case t(currentSettingsUpdated): {
      let fieldOfView;
      if (!action.payload!.fieldOfView) {
        // Sometimes iOS sends no FOV, so use a best-effort default until that is fixed.
        // https://app.asana.com/0/795402996061094/1131958885926020
        console.warn(
          'fieldOfView missing from currentSettings. Using previous value.',
        );
        fieldOfView = state.fieldOfView;
      } else {
        // Prior to 1.17, Helios was not adjusting the field of view to account
        // for the fact that it crops the video on phones down to square. For those
        // app versions, we adjust it here instead.
        //
        // TODO: Remove this horrible hack after 1.17 is released.
        // Some 1.16 builds will still exist and their FOV will be slightly wrong
        // again, but it's not a big deal.

        // Helios always starts out with this aspect ratio
        const rawCameraVideoAspectRatio = 480 / 640;

        const camera = action.payload.cameraVideoDimension;
        fieldOfView = action.payload.fieldOfView;
        if (
          camera.width === camera.height &&
          !action.payload.capabilities.fieldOfViewCorrectedOnHelios
        ) {
          fieldOfView =
            RAD2DEG *
            2 *
            Math.atan(
              rawCameraVideoAspectRatio *
                Math.tan((fieldOfView * DEG2RAD) / 2),
            );
        }
      }

      return {
        ...state,
        fieldOfView,
        cameraMode: action.payload!.camera,
      };
    }
    default:
      return state;
  }
};
