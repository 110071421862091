/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '@4c/layout';
import Button from '@bfly/ui/Button';
import Card from '@bfly/ui/Card';
import Heading from '@bfly/ui/Heading';
import Section from '@bfly/ui/Section';
import Text from '@bfly/ui/Text';

import Guide from '../icons/Guide';
import { ROOT_PATH } from '../utils/constants';
import { Experience } from './DashboardPage';

interface Props {
  markCompleted: (key: Experience) => void;
}

function GettingStartedCard({ markCompleted }: Props) {
  return (
    <Section className="mb-6">
      <Heading>
        <FormattedMessage
          id="gettingStarted.header"
          defaultMessage="Getting started"
        />
      </Heading>
      <Card className="mt-5">
        <Card.Body
          css={[require("./GettingStartedCard-CssProp1_CardBody.module.scss")]}
          className="py-6 px-7"
        >
          <Guide width="48px" height="48px" />
          <Layout direction="column" className="ml-6">
            <Text color="headline" variant="lg" className="mb-1">
              <FormattedMessage
                id="gettingStartedCard.bodyTitle"
                defaultMessage="Help others acquire better images remotely."
              />
            </Text>
            <Text color="subtitle">
              <FormattedMessage
                id="gettingStartedCard.bodySubtitle"
                defaultMessage="Read our guide to learn how to give assistance with TeleGuidance."
              />
            </Text>
            <div className="mt-4">
              <Button
                className="mr-4"
                target="_blank"
                href={`${ROOT_PATH}/getting-started/give-assistance`}
                onClick={() => markCompleted(Experience.VIEW_GETTING_STARTED)}
              >
                <FormattedMessage
                  id="gettingStartedCard.LearnMore"
                  defaultMessage="Learn more"
                />
              </Button>
              <Button
                theme="link"
                onClick={() => markCompleted(Experience.VIEW_GETTING_STARTED)}
              >
                <FormattedMessage
                  id="gettingStartedCard.Dismiss"
                  defaultMessage="Dismiss"
                />
              </Button>
            </div>
          </Layout>
        </Card.Body>
      </Card>
    </Section>
  );
}

export default GettingStartedCard;
