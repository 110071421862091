/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TelemedUserToUserPairingStatus = "%future added value" | "ACTIVE" | "PENDING" | "%future added value";
export type CrossOrgConnectionItem_crossOrgConnection = {
    readonly id: string;
    readonly status: TelemedUserToUserPairingStatus | null;
    readonly recipientProfile: {
        readonly id: string;
        readonly name: string | null;
        readonly " $fragmentRefs": FragmentRefs<"Avatar_profile">;
    } | null;
    readonly senderProfile: {
        readonly name: string | null;
        readonly " $fragmentRefs": FragmentRefs<"Avatar_profile">;
    } | null;
    readonly " $refType": "CrossOrgConnectionItem_crossOrgConnection";
};
export type CrossOrgConnectionItem_crossOrgConnection$data = CrossOrgConnectionItem_crossOrgConnection;
export type CrossOrgConnectionItem_crossOrgConnection$key = {
    readonly " $data"?: CrossOrgConnectionItem_crossOrgConnection$data;
    readonly " $fragmentRefs": FragmentRefs<"CrossOrgConnectionItem_crossOrgConnection">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "FragmentSpread",
  "name": "Avatar_profile",
  "args": null
};
return {
  "kind": "Fragment",
  "name": "CrossOrgConnectionItem_crossOrgConnection",
  "type": "TelemedUserToUserPairing",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "recipientProfile",
      "storageKey": null,
      "args": null,
      "concreteType": "UserProfile",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "senderProfile",
      "storageKey": null,
      "args": null,
      "concreteType": "UserProfile",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/)
      ]
    }
  ]
};
})();
(node as any).hash = '1db117ac2c5f42ddc240c09963eec384';
export default node;
