/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrganizationMembershipList_viewer = {
    readonly memberships: ReadonlyArray<{
        readonly organization: {
            readonly id: string;
            readonly name: string | null;
            readonly numUsedSeats: number | null;
        } | null;
    } | null> | null;
    readonly " $fragmentRefs": FragmentRefs<"CrossOrgConnectionPanel_viewer">;
    readonly " $refType": "OrganizationMembershipList_viewer";
};
export type OrganizationMembershipList_viewer$data = OrganizationMembershipList_viewer;
export type OrganizationMembershipList_viewer$key = {
    readonly " $data"?: OrganizationMembershipList_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"OrganizationMembershipList_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "OrganizationMembershipList_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "memberships",
      "storageKey": null,
      "args": null,
      "concreteType": "ViewerMembership",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "organization",
          "storageKey": null,
          "args": null,
          "concreteType": "Organization",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "id",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "numUsedSeats",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "CrossOrgConnectionPanel_viewer",
      "args": null
    }
  ]
};
(node as any).hash = 'bd7d75a8976b0b382c2ff67855003da6';
export default node;
