import {
  LocalAudioTrackStats,
  LocalVideoTrackStats,
  RemoteAudioTrackStats,
  RemoteVideoTrackStats,
  StatsReport,
} from 'twilio-video';

// Pull out named tracks from the raw WebRTC StatsReport to make it easier to
// use.
export function makeConnectionStats(
  rawStats: StatsReport,
  cameraTrackSid: string,
): ConnectionStats {
  // TODO: Remote track stats does have track ID, but Twilio type definition
  // forgot to list it. Once they fix this, trackStats need not be "any".
  const cameraVideoTrackStats = rawStats.remoteVideoTrackStats.filter(
    (trackStats: any) => trackStats.trackSid === cameraTrackSid,
  )[0];
  const probeVideoTrackStats = rawStats.remoteVideoTrackStats.filter(
    (trackStats: any) => trackStats.trackSid !== cameraTrackSid,
  )[0];
  return {
    peerConnectionId: rawStats.peerConnectionId,
    localAudioTrackStats: rawStats.localAudioTrackStats[0],
    remoteAudioTrackStats: rawStats.remoteAudioTrackStats[0],
    cameraVideoTrackStats,
    probeVideoTrackStats,
    localVideoTrackStats: rawStats.localVideoTrackStats[0],
  };
}

export interface ConnectionStats {
  peerConnectionId?: string;
  localVideoTrackStats?: LocalVideoTrackStats;
  localAudioTrackStats?: LocalAudioTrackStats;
  remoteAudioTrackStats?: RemoteAudioTrackStats;
  probeVideoTrackStats?: RemoteVideoTrackStats;
  cameraVideoTrackStats?: RemoteVideoTrackStats;
}

export function convertConnectionStats(
  stats: ConnectionStats,
): LoggableConnectionStats {
  return {
    cameraFrameRate:
      stats.cameraVideoTrackStats && stats.cameraVideoTrackStats.frameRate
        ? stats.cameraVideoTrackStats.frameRate
        : null,
    cameraBytesReceived:
      stats.cameraVideoTrackStats && stats.cameraVideoTrackStats.bytesReceived
        ? stats.cameraVideoTrackStats.bytesReceived
        : null,
    probeFrameRate:
      stats.probeVideoTrackStats && stats.probeVideoTrackStats.frameRate
        ? stats.probeVideoTrackStats.frameRate
        : null,
    probeBytesReceived:
      stats.probeVideoTrackStats && stats.probeVideoTrackStats.bytesReceived
        ? stats.probeVideoTrackStats.bytesReceived
        : null,
  };
}

export interface LoggableConnectionStats {
  cameraFrameRate: number | null;
  cameraBytesReceived: number | null;
  probeFrameRate: number | null;
  probeBytesReceived: number | null;
}
