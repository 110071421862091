import {
  CameraMode,
  HeliosEvent,
  ProtoValueWithDefaults,
} from '@bfly/telemed-interchange';

import { createAction } from 'typesafe-actions';
import { SessionStatus } from '../reducers/session';
import { SessionConnectionInfo } from '../Twilio';
import { convertConnectionStats } from '../Twilio/ConnectionStats';
import { Dimensions } from '../types';
import {
  LoggableSessionDetails,
  createLoggedAction,
  withPayload,
} from '../utils/Analytics';

// Strips out the data needed for logging a telemed session.
function withLoggedTelemedSession<P extends LoggableSessionDetails>() {
  return (session: P) => ({
    sessionId: session.id,
    noviceId: session.noviceId,
    noviceName: session.noviceName,
    expertName: session.expertName,
    organizationName: session.organizationName,
  });
}

export const remoteDataTrackConnected = createLoggedAction(
  'novice/dataTrackConnected',
);
export const noviceLeftTwilioRoom = createLoggedAction(
  'novice/leftTwilioRoom',
);

// This, by itself, doesn't have a reducer! It exists purely to get intercepted
// by the Helios middleware, when sends the command to quit to iOS.
export const endCallClicked = createLoggedAction('expert/endCallClicked');

export const receivedConnectionStats = createLoggedAction(
  'session/receivedConnectionStats',
  convertConnectionStats,
);

export const serverStatusUpdated = createLoggedAction(
  'session/serverStatusUpdated',
  withPayload<SessionStatus>(),
);

export interface UserFeedback {
  videoRating: number;
  guidanceFeaturesRating: number;
  commentText: string;
}
export const expertClosedFeedbackForm = createLoggedAction(
  'expert/feedbackFormClosed',
  withPayload<UserFeedback>(),
);

export const sessionJoined = createLoggedAction(
  'expert/sessionJoined',
  withLoggedTelemedSession<SessionConnectionInfo & LoggableSessionDetails>(),
);

export type CurrentSettings = ProtoValueWithDefaults<
  HeliosEvent.ICurrentSettings
>;

export const currentSettingsUpdated = createAction(
  'novice/currentSettingsUpdated',
)<CurrentSettings>();

export const cameraModeChanged = createLoggedAction(
  'novice/cameraModeChanged',
  withPayload<CameraMode>(),
);

export const probeDimensionsUpdated = createLoggedAction(
  'novice/probeDimensionsUpdated',
  withPayload<Dimensions>(),
);
export const cameraDimensionsUpdated = createLoggedAction(
  'novice/cameraDimensionsUpdated',
  withPayload<Dimensions>(),
);

export const setShowToast = createAction('expert/setShowToast')<boolean>();

export const setProbeUnavailable = createLoggedAction(
  'expert/setProbeUnavailable',
  withPayload<boolean>(),
);
