/* tslint:disable */
/* eslint-disable */
/* @relayHash 8309cdd62318a0a21a3da2f0787d807e */

import { ConcreteRequest } from "relay-runtime";
export type TelemedUserToUserPairingStatus = "ACTIVE" | "PENDING" | "%future added value";
export type AcceptTelemedUserToUserPairingInput = {
    userToUserPairingId: unknown;
    clientMutationId?: string | null;
};
export type CrossOrgConnectionItem_AcceptTelemedUserToUserPairingMutationVariables = {
    input: AcceptTelemedUserToUserPairingInput;
};
export type CrossOrgConnectionItem_AcceptTelemedUserToUserPairingMutationResponse = {
    readonly acceptTelemedUserToUserPairingOrError: {
        readonly __typename?: string;
        readonly message?: string | null;
        readonly userToUserPairing?: {
            readonly status: TelemedUserToUserPairingStatus | null;
        } | null;
    } | null;
};
export type CrossOrgConnectionItem_AcceptTelemedUserToUserPairingMutation = {
    readonly response: CrossOrgConnectionItem_AcceptTelemedUserToUserPairingMutationResponse;
    readonly variables: CrossOrgConnectionItem_AcceptTelemedUserToUserPairingMutationVariables;
};



/*
mutation CrossOrgConnectionItem_AcceptTelemedUserToUserPairingMutation(
  $input: AcceptTelemedUserToUserPairingInput!
) {
  acceptTelemedUserToUserPairingOrError(input: $input) {
    __typename
    ... on ErrorInterface {
      __typename
      message
    }
    ... on AcceptTelemedUserToUserPairingPayload {
      userToUserPairing {
        status
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AcceptTelemedUserToUserPairingInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "message",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CrossOrgConnectionItem_AcceptTelemedUserToUserPairingMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "acceptTelemedUserToUserPairingOrError",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "AcceptTelemedUserToUserPairingPayload",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "userToUserPairing",
                "storageKey": null,
                "args": null,
                "concreteType": "TelemedUserToUserPairing",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CrossOrgConnectionItem_AcceptTelemedUserToUserPairingMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "acceptTelemedUserToUserPairingOrError",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "AcceptTelemedUserToUserPairingPayload",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "userToUserPairing",
                "storageKey": null,
                "args": null,
                "concreteType": "TelemedUserToUserPairing",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CrossOrgConnectionItem_AcceptTelemedUserToUserPairingMutation",
    "id": null,
    "text": "mutation CrossOrgConnectionItem_AcceptTelemedUserToUserPairingMutation(\n  $input: AcceptTelemedUserToUserPairingInput!\n) {\n  acceptTelemedUserToUserPairingOrError(input: $input) {\n    __typename\n    ... on ErrorInterface {\n      __typename\n      message\n    }\n    ... on AcceptTelemedUserToUserPairingPayload {\n      userToUserPairing {\n        status\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'af528fc8b33a40fc0979261410fed970';
export default node;
