/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFragmentContainer, graphql } from 'react-relay';
import { useMutation } from 'react-relay-mutation';

import { endCallClicked } from '../actions/session';
import IcoEnd from '../icons/End';
import { SessionStatus } from '../reducers/session';
import { RootState } from '../store';
import IconButton from './IconButton';
import { EndCallButton_session as Session } from './__generated__/EndCallButton_session.graphql';

interface Props {
  session: Session;
}

function EndCallButton({ session }: Props) {
  const dispatch = useDispatch();
  const status = useSelector(
    (s: RootState) => s.session.connection.serverStatus,
  );

  const [completeSession] = useMutation(
    graphql`
      mutation EndCallButtonCompleteSessionMutation(
        $input: CompleteTelemedSessionInput!
      ) {
        completeTelemedSession(input: $input) {
          session {
            id
          }
        }
      }
    `,
    {
      variables: {
        input: { sessionId: session.id },
      },
    },
  );

  return (
    <IconButton
      css={[require("./EndCallButton-CssProp1_IconButton.module.scss"), []]}
      disabled={status !== SessionStatus.Active}
      onClick={() => {
        completeSession();
        dispatch(endCallClicked());
      }}
    >
      <IcoEnd strokeWidth="4" />
    </IconButton>
  );
}

export default createFragmentContainer(EndCallButton, {
  session: graphql`
    fragment EndCallButton_session on TelemedSession {
      id
    }
  `,
});
