/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import React from 'react';

import IndicatorDot from './IndicatorDot';

export const WIDTH = 1000;
export const HEIGHT = WIDTH;

export const CENTER_X = WIDTH / 2;
export const CENTER_Y = HEIGHT / 2;

export const CIRCLE_R = 400;

type Props = React.SVGProps<SVGSVGElement> & {
  indicatorDegrees: number | null;
};

function InstructionPlane({ children, indicatorDegrees, ...props }: Props) {
  return (
    <svg
      {...props}
      preserveAspectRatio="xMidYMid meet"
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      css={[require("./InstructionPlane-CssProp1_svg.module.scss"), []]}
    >
      <circle cx={CENTER_X} cy={CENTER_Y} r={CIRCLE_R} fill="#171718" />
      <IndicatorDot
        cx={CENTER_X}
        cy={CENTER_Y}
        r={CIRCLE_R}
        degrees={indicatorDegrees}
      />

      {children}
    </svg>
  );
}

export default InstructionPlane;
