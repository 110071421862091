import { Action, AnyAction, Dispatch, Store } from 'redux';
import { BehaviorSubject } from 'rxjs';

import select from '../utils/select';

const UNSUBSCRIBE = Symbol('unsubscribe');

class StoreSubject<
  TState,
  TAction extends Action<any> = AnyAction
> extends BehaviorSubject<TState> {
  dispatch: Dispatch<TAction>;

  private [UNSUBSCRIBE]: () => void;

  constructor(store: Store<TState, TAction>) {
    super(store.getState());

    this[UNSUBSCRIBE] = store.subscribe(() => this.next(store.getState()));

    this.dispatch = store.dispatch;
  }

  select<K>(mapFn: (s: TState) => K) {
    return this.pipe(select(mapFn));
  }

  complete() {
    this[UNSUBSCRIBE]();
    super.complete();
  }
}

export default StoreSubject;
