/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CautionBanner_session = {
    readonly organization: {
        readonly id: string;
    } | null;
    readonly " $refType": "CautionBanner_session";
};
export type CautionBanner_session$data = CautionBanner_session;
export type CautionBanner_session$key = {
    readonly " $data"?: CautionBanner_session$data;
    readonly " $fragmentRefs": FragmentRefs<"CautionBanner_session">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "CautionBanner_session",
  "type": "TelemedSession",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "organization",
      "storageKey": null,
      "args": null,
      "concreteType": "Organization",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = '660e02024629c5380d030ab3dea9d390';
export default node;
