/* eslint-disable */
/* prettier-ignore-start */
// [GENERATED FILE; DO NOT EDIT BY HAND]

import React from "react"

var element = React.createElement("svg", {
  viewBox: "0 0 40 40",
  fill: "currentColor",
  "aria-hidden": "true"
}, React.createElement("path", {
  d: "M38.5 28.58H36v-17.5a2.66 2.66 0 00-2.67-2.66H6.67A2.66 2.66 0 004 11.08v17.5H1.5a1.5 1.5 0 000 3h37a1.5 1.5 0 000-3zM6 11.08a.67.67 0 01.67-.66h26.66a.67.67 0 01.67.66v16.67a.67.67 0 01-.67.67H6.67a.67.67 0 01-.67-.67z",
  fill: "currentColor",
  fillRule: "evenodd"
}));

var Svg = React.forwardRef(function (props, ref) {
  var next = { ref: ref };
  for (var key in props) {
    if (props.hasOwnProperty(key)) {
      next[key] = props[key];
    }
  }
  return React.cloneElement(element, next);
});
Svg.displayName = "GiveAssistance";
Svg.element = element;
export default Svg;
