/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type InstructorHud_session = {
    readonly " $fragmentRefs": FragmentRefs<"VideoStatusOverlay_session">;
    readonly " $refType": "InstructorHud_session";
};
export type InstructorHud_session$data = InstructorHud_session;
export type InstructorHud_session$key = {
    readonly " $data"?: InstructorHud_session$data;
    readonly " $fragmentRefs": FragmentRefs<"InstructorHud_session">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "InstructorHud_session",
  "type": "TelemedSession",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "VideoStatusOverlay_session",
      "args": null
    }
  ]
};
(node as any).hash = 'fa80ee0c464fa5855e034d6f195ea863';
export default node;
