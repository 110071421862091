import React, { useRef } from 'react';
import useMergedRefs from '@restart/hooks/useMergedRefs';
import useMountEffect from '@restart/hooks/useMountEffect';

// Audio element that autoplays its media and is resilient to Chrome's autoplay policy.
const AutoplayAudio = React.forwardRef<
  HTMLAudioElement,
  React.HTMLProps<HTMLAudioElement>
>((props, attachRef) => {
  const localRef = useRef<HTMLAudioElement | null>();
  const mergedRef = useMergedRefs(localRef, attachRef);

  useMountEffect(() => {
    if (new window.AudioContext().state === 'running') return undefined;

    const clickHandler = () => {
      // Autoplay failed, and now we're allowed to play. Any future elements
      // will have autoplay succeed after this.
      localRef.current?.play();
      window.removeEventListener('click', clickHandler);
    };
    window.addEventListener('click', clickHandler);

    return () => {
      window.removeEventListener('click', clickHandler);
    };
  });

  return <audio autoPlay {...props} ref={mergedRef} />;
});

export default AutoplayAudio;
