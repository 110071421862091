/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CrossOrgConnectionList_viewer = {
    readonly id: string;
    readonly profile: {
        readonly id: string;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"CrossOrgConnectionItem_viewer">;
    readonly " $refType": "CrossOrgConnectionList_viewer";
};
export type CrossOrgConnectionList_viewer$data = CrossOrgConnectionList_viewer;
export type CrossOrgConnectionList_viewer$key = {
    readonly " $data"?: CrossOrgConnectionList_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"CrossOrgConnectionList_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CrossOrgConnectionList_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "profile",
      "storageKey": null,
      "args": null,
      "concreteType": "UserProfile",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "CrossOrgConnectionItem_viewer",
      "args": null
    }
  ]
};
})();
(node as any).hash = '914718494b0d181d537bc038c9ef214c';
export default node;
