import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { localVideoToggled } from '../actions/media';
import Video from '../icons/Video';
import { RootState } from '../store';
import IconToggleButton from './IconToggleButton';

function LocalVideoButton() {
  const dispatch = useDispatch();
  const enabled = useSelector((s: RootState) => s.media.localVideoEnabled);

  return (
    <IconToggleButton
      onClick={() => dispatch(localVideoToggled(!enabled))}
      enabled={enabled}
      icon={<Video />}
    />
  );
}

export default LocalVideoButton;
