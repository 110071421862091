const linear = (p: number) => p;

type Prop = { to: number; from: number };

interface TweenOptions {
  duration: number;
  delay?: number;
  easing?: (amount: number) => number;
}

/**
 * Calculates a change in value(s) over a set duration, with an optional
 * easing function applied. `Tween` doesn't do any animation, instead provides
 * a callback at the correct time with the interpolated value for each "frame"
 */
export default class Tween {
  private rafHandle?: number;

  private readonly properties: Prop[] = [];

  isStopped = false;

  /**
   * Duration is the _total_ time spent, **including** the delay.
   */
  readonly duration: number;

  readonly delay: number;

  readonly easing: (p: number) => number;

  constructor(
    properties: Prop[],
    { duration, delay = 0, easing = linear }: TweenOptions,
  ) {
    this.duration = duration;
    this.delay = delay;
    this.easing = easing;
    this.properties = properties;
  }

  start(renderFn: (...args: any[]) => void, onComplete?: () => void) {
    this.isStopped = false;

    const { properties, duration, delay, easing } = this;

    let startTime: number | null = null;
    let elapsedTime = 0;

    const tick = (currentTime: number) => {
      if (this.isStopped) {
        return;
      }

      if (startTime === null) {
        startTime = currentTime;
        elapsedTime = 0;
      } else {
        elapsedTime = currentTime - startTime;
      }

      if (elapsedTime > duration) {
        this.stop();
        if (onComplete) onComplete();
        return;
      }

      if (elapsedTime > delay) {
        const adjustedDuration = duration - delay;
        const adjustedElapsedTime = elapsedTime - delay;

        // elapsedTime %
        let proportion = Math.min(adjustedElapsedTime / adjustedDuration, 1);
        proportion = easing(proportion);

        const amounts = properties.map(
          ({ from, to }) => from + (to - from) * proportion,
        );
        renderFn(...amounts);
      }

      this.rafHandle = window.requestAnimationFrame(tick);
    };

    this.rafHandle = window.requestAnimationFrame(tick);
  }

  stop() {
    this.isStopped = true;
    window.cancelAnimationFrame(this.rafHandle!);
  }
}
