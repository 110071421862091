/* eslint-disable */
/* prettier-ignore-start */
// [GENERATED FILE; DO NOT EDIT BY HAND]

import React from "react"

var element = React.createElement("svg", {
  viewBox: "0 0 40 40",
  fill: "currentColor",
  "aria-hidden": "true"
}, React.createElement("g", {
  fill: "none",
  fillRule: "evenodd"
}, React.createElement("circle", {
  cx: "20",
  cy: "20",
  r: "20",
  fill: "var(--icon-bg-color)"
}), React.createElement("path", {
  d: "M8.444 17.784A3.557 3.557 0 0112 14.222h8.299a3.55 3.55 0 013.554 3.562v4.432a3.557 3.557 0 01-3.554 3.562h-8.3a3.55 3.55 0 01-3.554-3.562v-4.432zm7.704 4.142a1.926 1.926 0 100-3.852 1.926 1.926 0 000 3.852zm15.408-7.345v10.838c0 .383-.277.473-.616.204l-4.072-3.23c-1.666-1.322-1.666-3.464 0-4.786l4.072-3.23c.34-.27.616-.178.616.204z",
  fill: "var(--icon-color)"
})));

var Svg = React.forwardRef(function (props, ref) {
  var next = { ref: ref };
  for (var key in props) {
    if (props.hasOwnProperty(key)) {
      next[key] = props[key];
    }
  }
  return React.cloneElement(element, next);
});
Svg.displayName = "RecordVideo";
Svg.element = element;
export default Svg;
