import * as React from 'react';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { last, takeUntil } from 'rxjs/operators';
import { AudioTrack, LocalAudioTrack, Track, VideoTrack } from 'twilio-video';

type ElementType<T extends Track> = T extends VideoTrack
  ? HTMLVideoElement
  : T extends AudioTrack | LocalAudioTrack
  ? HTMLAudioElement
  : never;

interface Props<TTrack extends Track, TElement> {
  track$: Observable<TTrack | null>;
  children: (props: { ref: React.Ref<TElement> }) => React.ReactElement<any>;
}

interface State {
  videoHeight?: number;
}

export default class Media<
  TTrack extends VideoTrack | AudioTrack,
  TElement extends ElementType<TTrack>
> extends React.Component<Props<TTrack, TElement>, State> {
  private subscriptions: Subscription[];

  private ref$: Subject<TElement | null>;

  fullBleed = false;

  readonly state: Readonly<State> = {}; // MediaState

  constructor(props: Props<TTrack, TElement>) {
    super(props);

    const { track$ } = this.props;
    this.ref$ = new Subject();

    this.subscriptions = [
      combineLatest(
        track$,
        this.ref$.pipe(takeUntil(track$.pipe(last()))),
      ).subscribe({
        next([t, ref]) {
          if (t) {
            if (ref) t.attach(ref);
            else t.detach();
          } else {
            // Track was unsubscribed in Twilio
          }
        },
        complete() {
          console.log('complete!');
        },
      }),
    ];
  }

  componentWillUnmount() {
    this.ref$.complete();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  attachRef = (ref: TElement) => {
    this.ref$.next(ref);
  };

  render() {
    return this.props.children({
      ref: this.attachRef,
    });
  }
}
