import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IcoDropdown from '@bfly/icons/Dropdown';
import { ImagingMode } from '@bfly/telemed-interchange';
import Dropdown from '@bfly/ui/Dropdown';

import { modeChangedRequested } from '../actions/imaging';
import { RootState } from '../store';

import textStyles from '@bfly/ui/styles/text.module.scss';

const Modes = {
  [ImagingMode.UNKNOWN_MODE]: 'unknown mode',
  [ImagingMode.B_MODE]: 'B Mode',
  [ImagingMode.COLOR_DOPPLER]: 'Color Doppler',
  [ImagingMode.POWER_DOPPLER]: 'Power Doppler',
};

function ModeDropdown({ disabled }: { disabled?: boolean }) {
  const dispatch = useDispatch();
  const currentMode = useSelector((s: RootState) => s.imaging.mode);
  const pendingChange = useSelector(
    (s: RootState) => s.instruction.pendingModeChange,
  );
  const availableModes = useSelector(
    (s: RootState) => s.imaging.settings.availableModes,
  );
  const [show, setShow] = useState(false);
  if (disabled && show) {
    setShow(false);
  }

  return (
    <Dropdown
      drop="up"
      onSelect={(key: string) => {
        dispatch(modeChangedRequested(+key as ImagingMode));
      }}
      show={show}
      onToggle={setShow}
    >
      <Dropdown.Toggle
        id="preset-dropdown"
        theme="link"
        disabled={disabled}
        busy={pendingChange}
        className={textStyles.textBodyBold}
      >
        {Modes[currentMode] || '—'}
        <IcoDropdown className="ml-1" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {availableModes.map(mode => (
          <Dropdown.Item key={mode} eventKey={String(mode)}>
            {Modes[mode]}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ModeDropdown;
