/* eslint-disable */
/* prettier-ignore-start */
// [GENERATED FILE; DO NOT EDIT BY HAND]

import React from "react"

var element = React.createElement("svg", {
  viewBox: "0 0 64 21",
  fill: "currentColor",
  "aria-hidden": "true"
}, React.createElement("g", {
  fill: "none",
  fillRule: "evenodd"
}, React.createElement("path", {
  d: "M0-11h64v64H0z"
}), React.createElement("path", {
  fill: "gray",
  d: "M52.551 0a7.508 7.508 0 11-.002 15.014A7.508 7.508 0 0152.551 0m-41.29 0a7.508 7.508 0 11-.002 15.014A7.508 7.508 0 0111.261 0m20.645 7.507a7.507 7.507 0 110 15.015 7.507 7.507 0 010-15.015M22.288 25.8c-.67-2.819-2.793-5.419-5.386-6.397l-.082-.03-.285-.07c-3.092-.713-7.454-.713-10.547 0l-.288.07-.08.03C2.478 20.588 0 24.153 0 27.605v6.177h17.74c.396-3.063 2.116-6.029 4.547-7.983m20.876 9.061a7.885 7.885 0 00-.329-1.904c-.027-.1-.051-.2-.083-.3a9.39 9.39 0 00-.422-1.068 9.592 9.592 0 00-.39-.754c-.043-.076-.09-.148-.135-.221-1.024-1.673-2.53-3.051-4.256-3.703l-.084-.03-.284-.07c-3.093-.712-7.453-.713-10.547 0l-.288.07-.08.03c-2.082.786-3.867 2.618-4.838 4.768l-.03.065a9.237 9.237 0 00-.36.949c-.012.037-.022.075-.033.113a8.395 8.395 0 00-.239.978c-.078.442-.12.887-.12 1.33v6.178h22.522v-6.178l-.004-.253zm20.649-7.255l-.004-.253c-.117-3.373-2.549-6.792-5.615-7.95l-.084-.03-.284-.07c-3.093-.712-7.453-.712-10.547 0l-.288.072-.08.029c-2.585.975-4.718 3.562-5.393 6.372 2.441 1.943 4.168 4.906 4.561 8.008h17.734v-6.178z"
})));

var Svg = React.forwardRef(function (props, ref) {
  var next = { ref: ref };
  for (var key in props) {
    if (props.hasOwnProperty(key)) {
      next[key] = props[key];
    }
  }
  return React.cloneElement(element, next);
});
Svg.displayName = "Contacts";
Svg.element = element;
export default Svg;
