/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AppPage_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"NavbarDropdown_viewer">;
    readonly " $refType": "AppPage_viewer";
};
export type AppPage_viewer$data = AppPage_viewer;
export type AppPage_viewer$key = {
    readonly " $data"?: AppPage_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"AppPage_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "AppPage_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "NavbarDropdown_viewer",
      "args": null
    }
  ]
};
(node as any).hash = 'c278f4cf82837f6d0f5084f281b2a640';
export default node;
