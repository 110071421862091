/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DashboardPage_viewer = {
    readonly id: string;
    readonly didViewGettingStartedGuide: boolean | null;
    readonly profile: {
        readonly id: string;
        readonly name: string | null;
        readonly " $fragmentRefs": FragmentRefs<"Avatar_profile">;
    } | null;
    readonly telemedDashboardSettings: {
        readonly presenceHeartbeatIntervalSeconds: number | null;
    } | null;
    readonly memberships: ReadonlyArray<{
        readonly organization: {
            readonly id: string;
        } | null;
    } | null> | null;
    readonly incomingRequests: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly noviceAllowsRecording: boolean | null;
                readonly " $fragmentRefs": FragmentRefs<"IncomingRequest_session">;
            } | null;
        } | null> | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"CallHistoryList_viewer" | "OrganizationMembershipList_viewer" | "AppPage_viewer">;
    readonly " $refType": "DashboardPage_viewer";
};
export type DashboardPage_viewer$data = DashboardPage_viewer;
export type DashboardPage_viewer$key = {
    readonly " $data"?: DashboardPage_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"DashboardPage_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "DashboardPage_viewer",
  "type": "Viewer",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "incomingRequests"
        ]
      }
    ]
  },
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": "didViewGettingStartedGuide",
      "name": "didExperience",
      "args": [
        {
          "kind": "Literal",
          "name": "key",
          "value": "web.view_telemed_getting_started"
        }
      ],
      "storageKey": "didExperience(key:\"web.view_telemed_getting_started\")"
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "profile",
      "storageKey": null,
      "args": null,
      "concreteType": "UserProfile",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "Avatar_profile",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "telemedDashboardSettings",
      "storageKey": null,
      "args": null,
      "concreteType": "TelemedDashboardSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "presenceHeartbeatIntervalSeconds",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "memberships",
      "storageKey": null,
      "args": null,
      "concreteType": "ViewerMembership",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "organization",
          "storageKey": null,
          "args": null,
          "concreteType": "Organization",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "incomingRequests",
      "name": "__DashboardPage_incomingRequests_connection",
      "storageKey": "__DashboardPage_incomingRequests_connection(status:\"WAITING_FOR_EXPERT\")",
      "args": [
        {
          "kind": "Literal",
          "name": "status",
          "value": "WAITING_FOR_EXPERT"
        }
      ],
      "concreteType": "TelemedSessionConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "TelemedSessionEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "TelemedSession",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "noviceAllowsRecording",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "IncomingRequest_session",
                  "args": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "CallHistoryList_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "OrganizationMembershipList_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "AppPage_viewer",
      "args": null
    }
  ]
};
})();
(node as any).hash = '183bbcf2dd1b9b1216163a5391bb719d';
export default node;
