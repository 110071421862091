/* eslint-disable */
/* prettier-ignore-start */
// [GENERATED FILE; DO NOT EDIT BY HAND]

import React from "react"

var element = React.createElement("svg", {
  width: "24",
  height: "15",
  fill: "currentColor",
  "aria-hidden": "true"
}, React.createElement("path", {
  fill: "currentColor",
  d: "M6.409 3.059H4.272V.923a.71.71 0 10-1.424 0v2.136H.712a.712.712 0 000 1.424h2.136V6.62a.711.711 0 101.424 0V4.483h2.137a.711.711 0 100-1.424",
  mask: "url(#b)"
}), React.createElement("path", {
  fill: "currentColor",
  d: "M19.83 0a2.763 2.763 0 110 5.525 2.763 2.763 0 010-5.525m4.14 10.64c0-1.025-.146-1.601-.422-2.116a2.879 2.879 0 00-1.197-1.197c-.516-.276-1.09-.422-2.117-.422h-.81c-1.027 0-1.603.146-2.119.422-.498.267-.89.656-1.164 1.146 1.396.788 2.402 2.345 2.5 3.954h5.329v-1.786zM14.974 9.66l.036.014c.05.013.105.023.153.036l-.19-.05z"
}), React.createElement("path", {
  fill: "currentColor",
  d: "M12.925 2.531a2.763 2.763 0 11-.001 5.525 2.763 2.763 0 01.001-5.525m-.407 6.906h.81c1.026 0 1.602.146 2.118.422.516.276.92.681 1.197 1.196.275.516.422 1.092.422 2.118v1.785H8.782v-1.785c0-1.026.147-1.602.422-2.118.276-.515.68-.92 1.197-1.196.515-.276 1.091-.422 2.117-.422"
}));

var Svg = React.forwardRef(function (props, ref) {
  var next = { ref: ref };
  for (var key in props) {
    if (props.hasOwnProperty(key)) {
      next[key] = props[key];
    }
  }
  return React.cloneElement(element, next);
});
Svg.displayName = "AddContact";
Svg.element = element;
export default Svg;
