/* eslint-disable */
/* prettier-ignore-start */
// [GENERATED FILE; DO NOT EDIT BY HAND]

import React from "react"

var element = React.createElement("svg", {
  viewBox: "0 0 74 74",
  fill: "currentColor",
  "aria-hidden": "true"
}, React.createElement("g", {
  fill: "gray",
  fillRule: "evenodd"
}, React.createElement("path", {
  d: "M36.981 0c20.425 0 36.982 16.558 36.982 36.981 0 20.425-16.557 36.982-36.981 36.982C16.557 73.963 0 57.406 0 36.982 0 16.557 16.558 0 36.981 0",
  opacity: ".1"
}), React.createElement("path", {
  d: "M30.508 25.5a1.713 1.713 0 00-1.193 1.634v18.313c-1.23-.714-2.753-.984-4.396-.373-1.53.566-2.745 1.88-3.146 3.457-.875 3.458 1.75 6.553 5.074 6.486 2.847-.055 5.04-2.567 5.04-5.41V30.515c0-.187.122-.352.297-.409l17.048-5.652a.427.427 0 01.559.407V37.88c-1.234-.718-2.762-.986-4.41-.37-1.527.571-2.739 1.887-3.136 3.465a5.152 5.152 0 005.082 6.475c2.843-.058 5.035-2.568 5.035-5.411v-21.38c0-1.16-1.13-1.985-2.236-1.633L30.508 25.5z"
})));

var Svg = React.forwardRef(function (props, ref) {
  var next = { ref: ref };
  for (var key in props) {
    if (props.hasOwnProperty(key)) {
      next[key] = props[key];
    }
  }
  return React.cloneElement(element, next);
});
Svg.displayName = "Ringtone";
Svg.element = element;
export default Svg;
