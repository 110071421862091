import Konva from 'konva';
import React, { useRef, useState } from 'react';
import { Line } from 'react-konva';
import { connect } from 'react-redux';
import {
  DrawingCanvas,
  Point,
  useLocalTransform,
} from '@bfly/annotation-tools';
import useEventTarget from '@bfly/annotation-tools/lib/useEventTarget';
import isPrimaryPointer from '@bfly/annotation-tools/lib/utils/isPrimaryPointer';
import { useThrottledPointerMove } from '@bfly/annotation-tools/lib/utils/useThrottledPointerMove';
import {
  ShowMarker,
  IShowMarker as _IShowMarker,
} from '@bfly/telemed-interchange';

import { markerPointDrawn } from '../actions/instruction';
import pencilCursor from '../assets/pencil-cursor-sm.svg';
import { Dimensions } from '../types';
import Tween from '../utils/Tween';

const defaultStrokeWidth = 5;

export type IShowMarker = _IShowMarker;

const easeOutQuad = (t: number) => t * (2 - t);

interface MarkProps {
  mark?: Point[];
  strokeWidth?: number;
  onStart?(): void;
  onMove?(points: Point[]): void;
  onFinish?(points: Point[]): void;
}
// TODO: move this into @bfly/annotation-tools
function FreehandTrace({
  mark,
  onStart,
  onMove,
  onFinish,
  strokeWidth = defaultStrokeWidth,
}: MarkProps) {
  const isReadonly = !!mark;
  const localTransform = useLocalTransform();

  const [points, setMark] = useState([] as Point[]);

  const [handlePointerMove, cancelPendingMove] = useThrottledPointerMove(
    (e: Konva.KonvaPointerEvent) =>
      !isReadonly &&
      e.target.hasPointerCapture(e.pointerId) &&
      e.evt.isPrimary,
    (nextPoint: Point) => {
      // the side effect here is gross, but we do need to use the callback
      // version of setState, in case updates are lagging behind, we don't want to
      // lose a point
      setMark(prevPoints => {
        const nextMark = [...prevPoints, nextPoint];
        // FIXME: without the timeout this causes the prod build to throw a
        // bad hook usage error. This seems to be fixed in react#master (> 16.8.6)
        if (onMove) Promise.resolve().then(() => onMove(nextMark));
        return nextMark;
      });
    },
  );

  useEventTarget(
    isReadonly
      ? {}
      : {
          onPointerMove: handlePointerMove,
          onPointerDown: (e: Konva.KonvaPointerEvent) => {
            if (!isPrimaryPointer(e)) return;

            e.target.setPointerCapture(e.pointerId);
            if (onStart) onStart();
          },
          onPointerUp: (e: Konva.KonvaPointerEvent) => {
            cancelPendingMove();
            const donePoints = points;
            setMark([]);
            onFinish!([
              ...donePoints,
              localTransform.getPointFromEvent(e.evt),
            ]);
          },
        },
  );
  const currentMark = mark || points;

  return (
    <Line
      points={currentMark.flat()}
      strokeWidth={strokeWidth}
      stroke="#2779ff"
      lineCap="round"
      lineJoin="round"
    />
  );
}

interface Props {
  showMarker(mark: IShowMarker): void;
  dimensions: Dimensions;
  surface: ShowMarker.Surface;
}

function MarkerOverlay({ showMarker, surface, dimensions }: Props) {
  const mark = useRef({ id: 0, active: true });
  const [strokeWidth, setStrokeWidth] = useState(1);
  const [marks, setMarks] = useState([] as Point[][]);
  const { current: tween } = useRef(
    new Tween([{ from: defaultStrokeWidth, to: 0 }], {
      delay: 1000,
      duration: 2000,
      easing: easeOutQuad,
    }),
  );

  const toRelativePoint = ([x, y]: Point) => ({
    x: x / dimensions!.width,
    y: Math.abs(y) / dimensions!.height,
  });

  const handleStart = () => {
    mark.current.id++;
    mark.current.active = true;
    tween.stop();
    if (strokeWidth !== defaultStrokeWidth) setStrokeWidth(defaultStrokeWidth);
  };

  const handleShowMark = (points: Point[]) => {
    // prevents any race conditions where a move flushs while the nextMark is still inFlight
    if (!mark.current.active) return;

    showMarker({
      surface,
      markId: mark.current.id,
      points: points.map(toRelativePoint),
    });
  };

  const handleFinish = (points: Point[]) => {
    mark.current.active = false;

    setMarks([...marks, points]);

    showMarker({
      surface,
      finished: true,
      markId: mark.current.id,
      points: points.map(toRelativePoint),
    });

    handleStart();
    tween.start(
      (next: number) => {
        setStrokeWidth(next);
      },
      () => setMarks([]),
    );
  };

  return (
    <DrawingCanvas
      style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
      cursor={`url(${pencilCursor}) 8 8, auto`}
      width={dimensions.width}
      height={dimensions.height}
      image={null as any}
    >
      <>
        {marks.map((points, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <FreehandTrace key={idx} mark={points} strokeWidth={strokeWidth} />
        ))}

        <FreehandTrace
          key="next-trace"
          onFinish={handleFinish}
          onMove={handleShowMark}
          onStart={handleStart}
        />
      </>
    </DrawingCanvas>
  );
}

export default connect(null, {
  showMarker: markerPointDrawn,
})(MarkerOverlay);
