import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { RootState } from '../store';
import { createLoggedAction, withPayload } from '../utils/Analytics';
import {
  LOCAL_AUDIO_STORAGE_KEY,
  LOCAL_VIDEO_STORAGE_KEY,
  REMOTE_AUDIO_STORAGE_KEY,
  RINGTONE_STORAGE_KEY,
} from '../utils/constants';

export const setLocalAudioEnabled = createLoggedAction(
  'expert/setLocalAudioEnabled',
  withPayload<boolean>(),
);
export const setRemoteAudioEnabled = createLoggedAction(
  'expert/setRemoteAudioEnabled',
  withPayload<boolean>(),
);
export const setLocalVideoEnabled = createLoggedAction(
  'expert/setLocalVideoEnabled',
  withPayload<boolean>(),
);

export const setRingtoneEnabled = createLoggedAction(
  'expert/setRingtoneEnabled',
  withPayload<boolean>(),
);

function localStorageAction(key: string, action: (value: boolean) => any) {
  return (
    value: boolean,
  ): ThunkAction<void, RootState, undefined, AnyAction> => {
    return dispatch => {
      dispatch(action(value));
      localStorage.setItem(key, value.toString());
    };
  };
}

export const localAudioToggled = localStorageAction(
  LOCAL_AUDIO_STORAGE_KEY,
  setLocalAudioEnabled,
);
export const remoteAudioToggled = localStorageAction(
  REMOTE_AUDIO_STORAGE_KEY,
  setRemoteAudioEnabled,
);
export const localVideoToggled = localStorageAction(
  LOCAL_VIDEO_STORAGE_KEY,
  setLocalVideoEnabled,
);

export const ringtoneToggled = localStorageAction(
  RINGTONE_STORAGE_KEY,
  setRingtoneEnabled,
);
