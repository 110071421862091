import loadSegment from 'analytics.js-loader';

import { createAction } from 'typesafe-actions';

export interface LoggableSessionDetails {
  id: string;
  noviceId: string;
  noviceName: string;
  expertName: string;
  organizationName: string | null;
  status?: string;
}

const analytics = loadSegment({
  writeKey: window.bflyConfig.SEGMENT_WRITE_KEY,
  skipPageCall: true,
});

function addPrefix(object: any, prefix: string) {
  // Log the first level of nesting of objects. Log primitives as-is.
  if (Object(object) === object) {
    const result: any = {};
    for (const [key, value] of Object.entries(object)) {
      result[`${prefix}.${key}`] = value;
    }
    return result;
  }

  return { [prefix]: object };
}

// We wrap the analytics library to reliably avoid leaking
// query parameters
function redactUrl(url: string) {
  return url.split('?')[0].split('#')[0];
}

function getPageOverrides() {
  return {
    search: '[redacted]',
    url: redactUrl(window.location.href),
    referrer: redactUrl(document.referrer),
  };
}

function createOptions() {
  return {
    context: {
      page: {
        ...getPageOverrides(),
      },
    },
  };
}

analytics.page(getPageOverrides());

let currentSession: Record<string, object> | null;

let startTime: number | null = null;
export function setSession({
  id,
  noviceId,
  noviceName,
  expertName,
  organizationName,
  status,
}: LoggableSessionDetails) {
  // TODO: These IDs are the GraphQL global IDs and not a backend friendly one

  startTime = Date.now();
  currentSession = addPrefix(
    { id, noviceId, noviceName, expertName, organizationName, status },
    'session',
  );
}

export function identify(user: any, traits: any) {
  return analytics.identify(user, traits, createOptions());
}

export function track(eventName: string, payload?: any) {
  const elapsedMs = startTime ? Date.now() - startTime : null;
  return analytics.track(
    eventName,
    {
      sessionPageLoadTimestampMs: startTime,
      elapsedMs,
      ...addPrefix(payload || {}, 'payload'),
      ...currentSession,
    },
    createOptions(),
  );
}

export const segmentLogger = () => (next: any) => (action: any) => {
  if (action.meta && action.meta.logToSegment) {
    track(action.type, action.meta.logPayload);
  }
  return next(action);
};

type LogMeta<L> = {
  logToSegment: boolean;
  logPayload?: L;
};
// Return type of createLoggedAction, used to preserve type safety on actions.
// P: Raw payload tyoe
// L: Loggable payload type after conversion
export interface LoggablePayloadMeta<P, L> {
  meta: LogMeta<L>;
  payload: P;
}

/* Creates an action creator that will trigger a log to segment when used with
 * the segmentLogged middleware.
 *
 * No payload to log:
 * const muteAudio = createLogggedAction('MUTE_AUDIO');
 *
 * Action with payload, logging the full payload:
 * const submitFeedback = createLoggedAction('SUBMIT_FEEDBACK', withPayload<UserFeedback>());
 *
 * Action with payload where the payload needs to be transformed before logging:
 * const acceptSession = createLoggedAction('ACCEPT_SESSION', (payload: TelemedSession) =>
 *     ({ sessionId: session.id }));
 *
 * Why might the payload need to be converted before logging?
 * 1) To flatten nested objects (Segment ignores all nested fields)
 * 2) To avoid cluttering analytics with irrelevant info
 * 3) To redact sensitive details (e.g. Twilio tokens)
 *
 * Types:
 * P: Raw payload of the action
 * L: Loggable form of the payload after conversion
 * T: Action name, a string that's also a type
 */
export function createLoggedAction<L, T extends string, P = undefined>(
  typeName: T,
  convertPayload?: (p: P) => L, // only defined if action has a payload
) {
  const actionCreator = createAction<T, P, LogMeta<L>>(
    typeName,
    // FIXME: I can't figure out how to accept no arguments with this new API
    (p?: P) => p!,
    (p: P) => ({
      logToSegment: true,
      logPayload: convertPayload && convertPayload(p),
    }),
  );

  return actionCreator();
}

// Convenience function that specifies a payload type with a no-op converter
// for logging. It avoids some ugly generic syntax when declaring actions.
export function withPayload<T>() {
  return (v: T) => v;
}
