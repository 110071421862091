/* tslint:disable */
/* eslint-disable */
/* @relayHash eaf54b5b49ec3f2562c1f0c4f8610059 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TelemedSessionStatus = "ACTIVE" | "COMPLETED" | "EXPERT_REJECTED" | "NOVICE_CANCELED" | "WAITING_FOR_EXPERT" | "%future added value";
export type TelemedSessionStatusUpdatedSubscriptionInput = {
    sessionId?: unknown | null;
    clientSubscriptionId?: string | null;
};
export type IncomingRequest_SubscriptionVariables = {
    input: TelemedSessionStatusUpdatedSubscriptionInput;
};
export type IncomingRequest_SubscriptionResponse = {
    readonly telemedSessionStatusUpdated: {
        readonly session: {
            readonly id: string;
            readonly status: TelemedSessionStatus | null;
            readonly " $fragmentRefs": FragmentRefs<"IncomingRequest_session">;
        } | null;
    } | null;
};
export type IncomingRequest_Subscription = {
    readonly response: IncomingRequest_SubscriptionResponse;
    readonly variables: IncomingRequest_SubscriptionVariables;
};



/*
subscription IncomingRequest_Subscription(
  $input: TelemedSessionStatusUpdatedSubscriptionInput!
) {
  telemedSessionStatusUpdated(input: $input) {
    session {
      id
      status
      ...IncomingRequest_session
    }
  }
}

fragment Avatar_profile on UserProfile {
  name
  avatar {
    thumbnails {
      width
      url
    }
  }
}

fragment IncomingRequest_session on TelemedSession {
  id
  createdAt
  noviceAllowsRecording
  organization {
    name
    id
  }
  novice {
    id
    name
    avatar {
      thumbnail(width: 128) {
        url
      }
    }
    ...Avatar_profile
  }
  ...RecordingConsentDialog_session
}

fragment RecordingConsentDialog_session on TelemedSession {
  id
  novice {
    name
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "TelemedSessionStatusUpdatedSubscriptionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "IncomingRequest_Subscription",
    "type": "Subscription",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "telemedSessionStatusUpdated",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "TelemedSessionStatusUpdatedSubscriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "session",
            "storageKey": null,
            "args": null,
            "concreteType": "TelemedSession",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "IncomingRequest_session",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "IncomingRequest_Subscription",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "telemedSessionStatusUpdated",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "TelemedSessionStatusUpdatedSubscriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "session",
            "storageKey": null,
            "args": null,
            "concreteType": "TelemedSession",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "noviceAllowsRecording",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "organization",
                "storageKey": null,
                "args": null,
                "concreteType": "Organization",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "novice",
                "storageKey": null,
                "args": null,
                "concreteType": "UserProfile",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "avatar",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Avatar",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "thumbnail",
                        "storageKey": "thumbnail(width:128)",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "width",
                            "value": 128
                          }
                        ],
                        "concreteType": "Thumbnail",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "thumbnails",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Thumbnail",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "width",
                            "args": null,
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "subscription",
    "name": "IncomingRequest_Subscription",
    "id": null,
    "text": "subscription IncomingRequest_Subscription(\n  $input: TelemedSessionStatusUpdatedSubscriptionInput!\n) {\n  telemedSessionStatusUpdated(input: $input) {\n    session {\n      id\n      status\n      ...IncomingRequest_session\n    }\n  }\n}\n\nfragment Avatar_profile on UserProfile {\n  name\n  avatar {\n    thumbnails {\n      width\n      url\n    }\n  }\n}\n\nfragment IncomingRequest_session on TelemedSession {\n  id\n  createdAt\n  noviceAllowsRecording\n  organization {\n    name\n    id\n  }\n  novice {\n    id\n    name\n    avatar {\n      thumbnail(width: 128) {\n        url\n      }\n    }\n    ...Avatar_profile\n  }\n  ...RecordingConsentDialog_session\n}\n\nfragment RecordingConsentDialog_session on TelemedSession {\n  id\n  novice {\n    name\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '826b2a5229ad542e0dd1911b32b5497a';
export default node;
