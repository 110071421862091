import { PCFSoftShadowMap, Scene, WebGLRenderer } from 'three';

export default abstract class Renderer<TState> {
  protected readonly renderer: WebGLRenderer;

  protected readonly scene = new Scene();

  constructor(readonly container: HTMLElement) {
    const renderer = new WebGLRenderer({ alpha: true });

    renderer.setClearColor(0xffffff, 0); // transparent

    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(container.offsetWidth, container.offsetHeight);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = PCFSoftShadowMap;

    this.renderer = renderer;

    this.container.appendChild(renderer.domElement);
  }

  abstract render(state: TState, timeDeltaSeconds: DOMHighResTimeStamp): void;

  destroy() {
    this.renderer.forceContextLoss();
    this.renderer.domElement.parentElement!.innerHTML = '';
  }
}
