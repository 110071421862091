/* tslint:disable */
/* eslint-disable */
/* @relayHash 860e58f8726e7ef49a184a1fe684aea6 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RequestTelemedUserToUserPairingInput = {
    recipientUserProfileId?: unknown | null;
    recipientEmail?: string | null;
    clientMutationId?: string | null;
};
export type CrossOrgInviteForm_RequestTelemedUserToUserPairingMutationVariables = {
    input: RequestTelemedUserToUserPairingInput;
};
export type CrossOrgInviteForm_RequestTelemedUserToUserPairingMutationResponse = {
    readonly requestTelemedUserToUserPairingOrError: {
        readonly __typename?: string;
        readonly message?: string | null;
        readonly userToUserPairingEdge?: {
            readonly node: {
                readonly id: string;
                readonly recipientProfile: {
                    readonly id: string;
                    readonly name: string | null;
                } | null;
                readonly " $fragmentRefs": FragmentRefs<"CrossOrgConnectionItem_crossOrgConnection">;
            } | null;
        } | null;
    } | null;
};
export type CrossOrgInviteForm_RequestTelemedUserToUserPairingMutation = {
    readonly response: CrossOrgInviteForm_RequestTelemedUserToUserPairingMutationResponse;
    readonly variables: CrossOrgInviteForm_RequestTelemedUserToUserPairingMutationVariables;
};



/*
mutation CrossOrgInviteForm_RequestTelemedUserToUserPairingMutation(
  $input: RequestTelemedUserToUserPairingInput!
) {
  requestTelemedUserToUserPairingOrError(input: $input) {
    __typename
    ... on ErrorInterface {
      __typename
      message
    }
    ... on RequestTelemedUserToUserPairingPayload {
      userToUserPairingEdge {
        node {
          id
          recipientProfile {
            id
            name
          }
          ...CrossOrgConnectionItem_crossOrgConnection
        }
      }
    }
  }
}

fragment Avatar_profile on UserProfile {
  name
  avatar {
    thumbnails {
      width
      url
    }
  }
}

fragment CrossOrgConnectionItem_crossOrgConnection on TelemedUserToUserPairing {
  id
  status
  recipientProfile {
    id
    name
    ...Avatar_profile
  }
  senderProfile {
    name
    ...Avatar_profile
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RequestTelemedUserToUserPairingInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "message",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "avatar",
  "storageKey": null,
  "args": null,
  "concreteType": "Avatar",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "thumbnails",
      "storageKey": null,
      "args": null,
      "concreteType": "Thumbnail",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "width",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CrossOrgInviteForm_RequestTelemedUserToUserPairingMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "requestTelemedUserToUserPairingOrError",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "RequestTelemedUserToUserPairingPayload",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "userToUserPairingEdge",
                "storageKey": null,
                "args": null,
                "concreteType": "TelemedUserToUserPairingEdge",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TelemedUserToUserPairing",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "recipientProfile",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserProfile",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ]
                      },
                      {
                        "kind": "FragmentSpread",
                        "name": "CrossOrgConnectionItem_crossOrgConnection",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CrossOrgInviteForm_RequestTelemedUserToUserPairingMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "requestTelemedUserToUserPairingOrError",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "type": "RequestTelemedUserToUserPairingPayload",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "userToUserPairingEdge",
                "storageKey": null,
                "args": null,
                "concreteType": "TelemedUserToUserPairingEdge",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TelemedUserToUserPairing",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "recipientProfile",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserProfile",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "senderProfile",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserProfile",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v4/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CrossOrgInviteForm_RequestTelemedUserToUserPairingMutation",
    "id": null,
    "text": "mutation CrossOrgInviteForm_RequestTelemedUserToUserPairingMutation(\n  $input: RequestTelemedUserToUserPairingInput!\n) {\n  requestTelemedUserToUserPairingOrError(input: $input) {\n    __typename\n    ... on ErrorInterface {\n      __typename\n      message\n    }\n    ... on RequestTelemedUserToUserPairingPayload {\n      userToUserPairingEdge {\n        node {\n          id\n          recipientProfile {\n            id\n            name\n          }\n          ...CrossOrgConnectionItem_crossOrgConnection\n        }\n      }\n    }\n  }\n}\n\nfragment Avatar_profile on UserProfile {\n  name\n  avatar {\n    thumbnails {\n      width\n      url\n    }\n  }\n}\n\nfragment CrossOrgConnectionItem_crossOrgConnection on TelemedUserToUserPairing {\n  id\n  status\n  recipientProfile {\n    id\n    name\n    ...Avatar_profile\n  }\n  senderProfile {\n    name\n    ...Avatar_profile\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '5c50cf3cca8447f88adf81bd88e3d14a';
export default node;
