import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Text from '@bfly/ui/Text';

import { gainChangeRequested } from '../actions/imaging';
import { RootState } from '../store';
import Range from './Range';

const getDisplayValue = (
  val: number | null | undefined,
): number | undefined => {
  return val == null ? undefined : Math.round(val);
};

function GainRange({
  disabled,
}: React.ComponentPropsWithoutRef<typeof Range>) {
  const dispatch = useDispatch();
  const { lower, upper, value } = useSelector(
    (s: RootState) => s.imaging.gain,
  );
  const displayValue = getDisplayValue(value);

  return (
    <>
      <Range
        disabled={disabled}
        min={getDisplayValue(lower)}
        max={getDisplayValue(upper)}
        value={displayValue}
        title={String(displayValue)}
        onChange={e => {
          dispatch(
            gainChangeRequested({
              value: e.currentTarget.valueAsNumber,
              tentative: false,
            }),
          );
        }}
      />
      <Text color="headline" variant="body-bold">
        {displayValue}%
      </Text>
    </>
  );
}

export default GainRange;
