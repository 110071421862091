/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import classNames from 'classnames';
import React, { useRef, useState } from 'react';

import { track } from '../utils/Analytics';

interface Props {
  src: string;
  className?: string;
  loop?: boolean;
  controls?: boolean;
  poster?: string;
  name: string;
}

function ExpertVideo({ src, className, name, ...rest }: Props) {
  const ref = useRef<HTMLVideoElement>(null);
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(false);

  function handlePlayback() {
    const video = ref.current!;

    if (video.controls) return;

    if (video.paused || video.ended) {
      video.play();
      if (!played) {
        track('guide/videoPlayed', { name });
        setPlayed(true);
      }
    } else {
      video.pause();
    }
  }

  return (
    <div className={classNames(className, 'position-relative')} tabIndex={-1}>
      <video
        muted
        playsInline
        ref={ref}
        {...rest}
        onClick={handlePlayback}
        onKeyDown={e => {
          if (e.key === ' ') handlePlayback();
        }}
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        css={[require("./GettingStartedExpertVideo-CssProp1_video.module.scss"), []]}
      >
        <source src={src} type="video/mp4" />
      </video>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="96"
        height="96"
        viewBox="0 0 96 96"
        className="position-absolute"
        css={[require("./GettingStartedExpertVideo-CssProp2_svg.module.scss"), [["ar625d8", playing ? '0' : '1']]]}
      >
        <defs>
          <filter
            id="prefix__a"
            width="209.4%"
            height="209.4%"
            x="-54.7%"
            y="-54.7%"
            filterUnits="objectBoundingBox"
          >
            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
              stdDeviation="8"
            />
            <feColorMatrix
              in="shadowBlurOuter1"
              result="shadowMatrixOuter1"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.32 0"
            />
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <g
          fill="none"
          fillRule="evenodd"
          filter="url(#prefix__a)"
          transform="translate(16 14)"
        >
          <circle
            cx="32"
            cy="32"
            r="30.667"
            stroke="#FFF"
            strokeWidth="2.667"
          />
          <path
            fill="#FFF"
            d="M24 21.028v21.944a2.667 2.667 0 004.163 2.207l16.19-10.971a2.667 2.667 0 000-4.416l-16.19-10.971A2.667 2.667 0 0024 21.028z"
          />
        </g>
      </svg>
    </div>
  );
}

export default ExpertVideo;
