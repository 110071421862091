import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { tag } from 'rxjs-spy/operators';

import { RootState } from '../reducers/index';
import { SessionStatus } from '../reducers/session';
import StoreSubject from '../store/StoreSubject';
import select from './select';

export function callEnded(
  store$: StoreSubject<RootState>,
): Observable<SessionStatus> {
  return store$.pipe(
    select(s => s.session.connection.serverStatus),
    filter(s => s === SessionStatus.Completed),
    tag('callEnded'),
  );
}
