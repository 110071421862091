export interface DebugOptions {
  showProbe?: boolean;
  gridHelper?: boolean;
  axesHelper?: boolean;
  lightHelper?: boolean;
  cameraControls?: boolean;
}

export interface SceneOptions {
  probeOcclusion?: boolean;
  debug?: true | DebugOptions;
}

export interface DefaultedSceneOptions {
  probeOcclusion: boolean;
  debug: DebugOptions;
}

export const defaultOptions = (opts: SceneOptions): DefaultedSceneOptions => ({
  ...opts,
  probeOcclusion: opts.probeOcclusion || false,
  debug: {
    showProbe: !!opts.debug,
    axesHelper: !!opts.debug,
    gridHelper: !!opts.debug,
    cameraControls: !!opts.debug,
    lightHelper: !!opts.debug,
    ...(opts.debug as any),
  },
});
