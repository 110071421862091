/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import React from 'react';
import useCallbackRef from '@restart/hooks/useCallbackRef';

import { Dimensions } from '../types';
import useContainedDimensions from './utils/useContainedDimensions';

const defaultDimensions = { width: 0, height: 0 };

interface Props extends React.HTMLProps<HTMLDivElement> {
  dimensions: Dimensions;
  children: (d: Dimensions) => React.ReactNode;
}

/**
 * A component that constrains and centers a child using the `object-fit: contain`
 * calculation.
 */
function Contain({ dimensions, children, ...props }: Props) {
  const [container, attachRef] = useCallbackRef();

  const { width, height } =
    useContainedDimensions(container, dimensions) || defaultDimensions;

  return (
    <div
      ref={attachRef}
      {...props}
      css={[require("./Contain-CssProp1_div.module.scss"), []]}
    >
      <div
        css={[require("./Contain-CssProp2_div.module.scss"), [["a1ayuy5q", width, "px"], ["a1hftrco", height, "px"]]]}
      >
        {children({ width, height })}
      </div>
    </div>
  );
}

export default Contain;
