/** @jsx _j **/
/** @jsxFrag _f **/

const { jsx: _j, F: _f } = require('astroturf');

import capitalize from 'lodash/capitalize';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IcoDropdown from '@bfly/icons/Dropdown';
import Dropdown from '@bfly/ui/Dropdown';

import { presetChangeRequested } from '../actions/imaging';
import { RootState } from '../store';

import textStyles from '@bfly/ui/styles/text.module.scss';

function PresetDropdown({ disabled }: { disabled?: boolean }) {
  const dispatch = useDispatch();
  const currentPreset = useSelector((s: RootState) => s.imaging.preset);
  const pendingChange = useSelector(
    (s: RootState) => s.instruction.pendingPresetChange,
  );
  const availablePresets = useSelector(
    (s: RootState) => s.imaging.settings.availablePresets,
  );
  const [show, setShow] = useState(false);
  if (disabled && show) {
    setShow(false);
  }

  return (
    <Dropdown
      drop="up"
      onSelect={(key: string) => dispatch(presetChangeRequested(key))}
      show={show}
      onToggle={setShow}
    >
      <Dropdown.Toggle
        id="preset-dropdown"
        theme="link"
        disabled={disabled}
        busy={pendingChange}
        className={textStyles.textBodyBold}
      >
        {capitalize(currentPreset || '—')}
        <IcoDropdown className="ml-1" />
      </Dropdown.Toggle>
      <Dropdown.Menu
        css={[require("./PresetDropdown-CssProp1_DropdownMenu.module.scss"), []]}
      >
        {availablePresets.map(preset => (
          <Dropdown.Item key={preset} eventKey={preset} disabled={disabled}>
            {capitalize(preset)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default PresetDropdown;
